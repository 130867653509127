import React, { Component } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";

export default class BarRechartSingleValue extends Component {
  handleClick = (data) => {
    this.props.onClick(data);
  };
  render() {
    /*
      Bar Rechart Single Value styles
      If passed the value to it it will overide with use defined values, otherwise it will 
      take default vlaue.
      Use left side variable name to pass the value from other component, or use this.props.* name.
    */
    const colors =
      this.props.colors !== undefined &&
      this.props.colors !== null &&
      this.props.colors.length > 0
        ? this.props.colors
        : ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    const width =
      this.props.width !== undefined && this.props.width !== null
        ? this.props.width
        : "100%";
    const composedChartWidth =
      this.props.width !== undefined && this.props.width !== null
        ? this.props.width
        : 155;
    const height =
      this.props.height !== undefined && this.props.height !== null
        ? this.props.height
        : 295;
    const marginTop =
      this.props.marginTop !== undefined && this.props.marginTop !== null
        ? this.props.marginTop
        : 5;
    const marginRight =
      this.props.marginRight !== undefined && this.props.marginRight !== null
        ? this.props.marginRight
        : 5;
    const marginBottom =
      this.props.marginBottom !== undefined && this.props.marginBottom !== null
        ? this.props.marginBottom
        : 5;
    const marginLeft =
      this.props.marginLeft !== undefined && this.props.marginLeft !== null
        ? this.props.marginLeft
        : -20;
    const fontSize =
      this.props.fontSize !== undefined && this.props.fontSize !== null
        ? this.props.fontSize
        : "0.7rem";
    const barSize =
      this.props.barSize !== undefined && this.props.barSize !== null
        ? this.props.barSize
        : 40;
    const cartesianGridStroke =
      this.props.cartesianGridStroke !== undefined &&
      this.props.cartesianGridStroke !== null
        ? this.props.cartesianGridStroke
        : "";
    const barFill =
      this.props.barFill !== undefined && this.props.barFill !== null
        ? this.props.barFill
        : "#413ea0";

    const CustomBarLabel = ({ payload, x, y, value }) => {
      return (
        <text x={x + barSize / 2} y={y} fill="#777" dy={0} textAnchor="middle">
          {value}
        </text>
      );
    };
    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        let value = 0;
        if (payload !== undefined && payload !== null && payload.length > 0) {
          value = payload[0].value;
        }

        console.log(
          "this is selected value",
          label,
          "value",
          value,
          "payload",
          payload
        );
        return (
          <div
            style={{
              background: "var(--cardBackground)",
              color: "var(--textColor)",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 0.1,
              textTransform: "uppercase",
              boxShadow: "3px 3px 10px 0 rgba(0, 0, 0, 0.7)",
            }}
          >
            <p className="">{`${label} : ${value}`}</p>
          </div>
        );
      }

      return null;
    };
    return (
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          width={composedChartWidth}
          height={height}
          data={this.props.response}
          margin={{
            top: marginTop,
            right: marginRight,
            bottom: marginBottom,
            left: marginLeft,
          }}
        >
          {/* <CartesianGrid stroke={cartesianGridStroke} /> */}
          <XAxis
            dataKey={this.props.XAxis}
            tick={{ fill: "var(--barReChartSingleXAxis)" }}
            style={{ fontSize: fontSize, textTransform: "uppercase" }}
          />
          <YAxis
            dataKey={this.props.YAxis}
            tick={{ fill: "var(--barReChartSingleYAxis)" }}
            style={{ fontSize: fontSize }}
            tickCount={this.props.tick ? this.props.tick : 10}
            domain={[0, this.props.maxDomain]}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar
            dataKey={this.props.YAxis}
            barSize={barSize}
            onClick={(data, index) => {
              this.handleClick(data);
            }}
            fill={barFill}
            label={{ position: this.props.LabelPosition }}
          >
            {this.props.response.map((entry, index) => {
              const color = colors[index % colors.length];
              return <Cell key={index} fill={color} />;
            })}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
