import React from "react";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@mui/icons-material/Group";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import DescriptionIcon from "@mui/icons-material/Description";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 200,
    width: 214,
    height: 152,
    // backgroundColor: "white",
  },
  cardList: {
    display: "flex",
    justifyContent: "start",
    // alignItems: "center",
    flexDirection: "row",
    // flexWrap: "wrap",
  },
  card: {
    // margin: "20px",
    // borderRadius: "11px",
    // // color: "#fff",
    // // display: "flex",
    // // justifyContent: "space-around",
    // // gap: "20px",
    // padding: 10,
    width: "100%",
    height: 152,
    // overflow: "hidden",
  },
  cardTitle: {
    marginTop: "10px",
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 600,
    color: "#202224",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardCount: {
    fontSize: 28,
    fontWeight: 700,
    marginTop: 5,
    color: "#202224",
  },
}));

// const colors = [
//   "rgba(255, 99, 132, 1)",
//   "rgba(54, 162, 235, 1)",
//   "rgba(255, 159, 64, 1)",
//   "rgba(75, 192, 192, 1)",
//   "rgba(153, 102, 255, 1)",
// ];
const colors = ["#fff", "#fff", "#fff", "#fff", "#fff"];

const icons = [
  <GroupIcon fontSize="large" />,
  <FormatListBulletedIcon fontSize="large" />,
  <ThumbUpIcon fontSize="large" />,
  <DescriptionIcon fontSize="large" />,
  <RssFeedIcon fontSize="large" />,
];

const CardsList = (props) => {
  const classes = useStyles();

  // console.log("this is props?.values", props?.values);

  return (
    <div className={classes.root}>
      <div className={classes.cardList}>
        {props?.values?.map((key, index) => (
          <Grid
            // container
            style={{
              margin: 0,
              // background: key.background || colors[index],
              background: "#ffffff",
              // position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "16px",
              borderRadius: "11px",
              boxShadow: "6px 6px 14px 0px #0000000D",
              overflow: "hidden",
              // marginTop: "12px",
            }}
            className={classes.card}
          >
            {/* <svg
              width="151"
              height="127"
              viewBox="0 0 151 127"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                borderRadius: "10px",
              }}
            >
              <path
                d="M0 43.1226L1.19 42.9625C3.99 63.7025 13.32 82.5125 28.16 97.3525C51.15 120.343 83.87 129.933 115.68 122.993L115.94 124.173C83.73 131.203 50.6 121.493 27.32 98.2125C12.28 83.1725 2.84 64.1226 0 43.1226Z"
                fill={key.color}
              />
              <path
                d="M27.31 -37.8675C64.82 -75.3775 125.86 -75.3775 163.37 -37.8675C200.88 -0.357498 200.88 60.6825 163.37 98.1925L162.52 97.3425C199.56 60.3025 199.56 0.0225067 162.52 -37.0175C125.48 -74.0575 65.2 -74.0575 28.16 -37.0175C16.97 -25.8275 8.69 -11.9375 4.24 3.14248L3.09 2.80248C7.6 -12.4675 15.97 -26.5275 27.31 -37.8675Z"
                fill={key.color}
              />
              <path
                d="M33.88 -31.2975C40.62 -38.0375 48.32 -43.5775 56.79 -47.7675L57.86 -45.6075C49.63 -41.5375 42.14 -36.1475 35.59 -29.5975C4.8 1.19253 2.31 49.8125 29.79 83.5025L27.93 85.0225C-0.35 50.3825 2.21 0.372543 33.88 -31.2975Z"
                fill={key.color}
              />
              <path
                d="M45.89 98.7224C61.98 110.362 81.81 115.942 101.73 114.442C121.86 112.922 140.81 104.222 155.1 89.9325C166.63 78.4025 174.54 63.9124 177.95 48.0324C181.93 29.5324 179.57 10.0124 171.3 -6.92755L173.46 -7.98755C181.97 9.43245 184.4 29.5024 180.3 48.5324C176.79 64.8624 168.66 79.7625 156.79 91.6225C142.09 106.322 122.6 115.272 101.89 116.832C81.41 118.372 61.01 112.632 44.46 100.662L45.89 98.7224Z"
                fill={key.color}
              />
              <path
                d="M50.95 -14.2275C75.43 -38.7075 115.26 -38.7075 139.74 -14.2275C149.65 -4.31752 155.83 8.24249 157.61 22.1025L155.22 22.4125C153.5 9.08249 147.56 -2.9875 138.03 -12.5175C114.49 -36.0575 76.19 -36.0575 52.65 -12.5175C48.47 -8.3375 44.95 -3.6075 42.17 1.5425L40.05 0.402481C42.94 -4.96752 46.6 -9.87752 50.95 -14.2275Z"
                fill={key.color}
              />
              <path
                d="M32.62 27.4125L35.03 27.5125C34.29 44.3925 40.72 60.9125 52.65 72.8525C61.06 81.2625 71.63 86.9525 83.23 89.3125L82.75 91.6725C70.69 89.2125 59.69 83.2925 50.95 74.5525C38.53 62.1425 31.85 44.9625 32.62 27.4125Z"
                fill={key.color}
              />
              <path
                d="M102.2 90.1525C115.88 88.5925 128.27 82.6125 138.03 72.8625C146.58 64.3125 152.32 53.5325 154.61 41.7025L156.97 42.1625C154.59 54.4625 148.63 65.6726 139.73 74.5626C129.58 84.7126 116.7 90.9225 102.47 92.5425L102.2 90.1525Z"
                fill={key.color}
              />
              <path
                d="M58.57 -6.59747C64.55 -12.5775 71.95 -17.0475 79.97 -19.5175L80.32 -18.3675C72.48 -15.9475 65.25 -11.5875 59.41 -5.74747C43.29 10.3725 39.89 35.4526 51.14 55.2426L50.09 55.8325C38.59 35.5725 42.07 9.90253 58.57 -6.59747Z"
                fill={key.color}
              />
              <path
                d="M95.33 -21.8275C109.22 -21.8275 122.28 -16.4175 132.11 -6.59753C152.38 13.6725 152.38 46.6625 132.11 66.9325C111.84 87.2025 78.85 87.2025 58.58 66.9325L59.43 66.0825C79.23 85.8825 111.46 85.8825 131.26 66.0825C151.06 46.2825 151.06 14.0525 131.26 -5.74753C121.66 -15.3475 108.91 -20.6275 95.34 -20.6275V-21.8275H95.33Z"
                fill={key.color}
              />
              <path
                d="M72.47 7.30247C85.08 -5.30753 105.6 -5.30753 118.21 7.30247C130.82 19.9125 130.82 40.4325 118.21 53.0425L116.51 51.3425C128.18 39.6725 128.18 20.6825 116.51 9.00245C104.84 -2.66755 85.85 -2.66755 74.18 9.00245C62.51 20.6725 62.51 39.6625 74.18 51.3325L72.48 53.0325C59.86 40.4225 59.86 19.9125 72.47 7.30247Z"
                fill={key.color}
              />
              <path
                d="M83.97 18.8025C90.24 12.5325 100.44 12.5325 106.7 18.8025L105.85 19.6525C100.05 13.8525 90.62 13.8525 84.82 19.6525C79.02 25.4525 79.02 34.8825 84.82 40.6825C90.62 46.4825 100.05 46.4825 105.85 40.6825C109.07 37.4625 110.62 33.0225 110.11 28.4925L111.31 28.3525C111.86 33.2425 110.18 38.0525 106.7 41.5325C100.43 47.8025 90.23 47.8025 83.97 41.5325C77.71 35.2625 77.71 25.0725 83.97 18.8025Z"
                fill={key.color}
              />
            </svg> */}
            <Typography variant="root">
              <Box className={classes.cardTitle}>{key.title}</Box>
              <Box className={classes.cardCount}>{key.value}</Box>
            </Typography>

            <div
              style={{
                backgroundColor: key.iconBackground,
                width: 60,
                height: 60,
                borderRadius: 16,
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                objectFit: "cover",
              }}
            >
              <img
                src={key.icon}
                style={{ width: "32px", height: "30px", contain: "content" }}
              />
            </div>
            {/* <Avatar sx={{ width: 60, height: 60, backgroundColor: "white" }}>
              {key.icon}
            </Avatar> */}
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default CardsList;
