import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: "100vh",
  },

  // spaceBetween: {
  //   margin: `0 ${theme.spacing(2)}`,
  // },
  // label: {
  //   fontWeight: 600,
  //   fontSize: theme.fonts.heading,
  // },
  // distinct: {
  //   marginTop: theme.spacingArea.doubleLarge,
  // },
  // container: {
  //   paddingTop: theme.spacingArea.XLarge,
  // },
  // gapMedium: {
  //   gap: theme.spacingArea.XLarge,
  // },
  // gapSmall: {
  //   gap: theme.spacingArea.large,
  // },
  // gapXsmall: {
  //   gap: theme.spacingArea.small,
  // },

  // input: {
  //   borderRadius: "8px",
  //   border: "1px solid #E5E5E5",
  //   overflow: "hidden",
  //   fontSize: "14px",
  //   marginTop: theme.spacingArea.xSmall,
  // },
  // companyImage: {
  //   width: "90px",
  //   height: "90px",
  //   background: "#F9F9F9",
  //   borderRadius: "100%",
  //   marginBottom: theme.spacingArea.large,
  // },
  companyLogo: {
    width: "57px",
    height: "57px",
    textAlign: "center",
  },
  // spaceY: {
  //   padding: theme.spacingArea.large,
  // },
  flex: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: "12px",
    position: "relative",
  },
  round: {
    width: "16px",
    height: "16px",
    // background: theme.palette.primary.main,
    borderRadius: "100%",
  },
  // textMargin: {
  //   marginTop: theme.spacing.l1,
  //   marginBottom: theme.spacing.l1,
  // },
  lines: {
    // borderLeft: "1px solid #949499",#D1D5DB
    width: "0.125rem ",
    height: "50px",
    alignSelf: "start",
    marginLeft: "7px",
  },
  parent: {
    padding: "1rem 1rem 3rem 1rem",
    height: "80vh",

    overflowY: "auto",
    justifyContent: "space-between",
  },
  // spacing: {
  //   padding: theme.spacingArea.large,
  // },
  // spacingSmall: {
  //   padding: theme.spacingArea.small,
  // },
  // parentTitle: {
  //   padding: theme.spacingArea.large,
  //   backgroundColor: "#ECF8FF",
  // },
  logsRoot: {
    // marginTop: "60px",
    gap: "24px",
    // height: "100vh",
    // overflow: "auto",
  },
  header: {
    position: "relative",
    top: "-26px",
    zIndex: "1",
  },
  chatRoot: {
    width: "334px",
    // height: "105px",
    borderRadius: "0 6px 6px 6px",
    position: "relative",
    top: 4,
    gap: "4px",
    padding: "10px",
  },
  rootLog: {
    // paddingTop: "60px",
    marginBottom: "5rem",
    // height: "67vh",
    position: "relative",
    // overflow: "auto",
  },
  inputArea: {
    flexGrow: 2,
    // width: "480px",
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
  },
  input: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
    overflow: "hidden",
    fontSize: "14px",
    // marginTop: theme.spacingArea.xSmall,
  },
  inputCostSheet: {
    borderRadius: "8px",
    border: "1px solid #E5E5E5",
  },
  icon: {
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    backgroundColor: "#F8F9FD",
    justifyContent: "center",
    alignItems: "center",
  },
  dropdown: {
    border: "none  !important",
    overflow: "hidden !important",
    fontSize: "14px !important",
    marginTop: "4px !important",
    backgroundColor: "white !important",
    borderRadius: "0px !important",
    borderRight: "1px solid #E5E5E5 !important",
  },
  parentTextArea: {
    flexDirection: "row",
    alignItems: "center",
    // gap: theme.spacingArea.large,
    boxShadow:
      "0px 6.400000095367432px 14.399999618530273px 0px rgba(0, 0, 0, 0.13), 0px 1.2000000476837158px 3.5999999046325684px 0px rgba(0, 0, 0, 0.10)",
  },
}));
