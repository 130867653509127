class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader = [
    {
      id: "fname",
      accessor: "fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "user.fname",
      className: "",
      functionName: "",
    },
    {
      id: "lname",
      accessor: "lname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "user.lname",
      className: "",
      functionName: "",
    },
    {
      id: "email",
      accessor: "email",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: "user.email",
      className: "",
      functionName: "",
    },
    {
      id: "userType",
      accessor: "userType",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: "user.userType",
      className: "StringUtils",
      functionName: "modifyUserType",
    },

    {
      id: "mobileNo",
      accessor: "mobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: "user.mobileNo",
      className: "",
      functionName: "",
    },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
  ];
  static adminTableHeader = [
    {
      id: "fname",
      accessor: "fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 130,
      label: "user.fname",
      className: "",
      functionName: "",
    },
    {
      id: "lname",
      accessor: "lname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 130,
      label: "user.lname",
      className: "",
      functionName: "",
    },
    {
      id: "email",
      accessor: "email",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "user.email",
      className: "",
      functionName: "",
    },
    {
      id: "userType",
      accessor: "userType",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 60,
      label: "user.userType",
      className: "",
      functionName: "",
    },

    {
      id: "mobileNo",
      accessor: "mobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 110,
      label: "user.mobileNo",
      className: "",
      functionName: "",
    },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 130,
      label: "user.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 130,
      label: "user.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    // {
    //   id: "passwordAttempt",
    //   accessor: "passwordAttempt",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: "user.passwordAttempt",
    //   className: "",
    //   functionName: "",
    // },
  ];
}
export default TableHeader;
