import React from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import Role from "./role";
import User from "./user";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile";
import Setting from "./setting";
import Advertisement from "./advertisement";
import Category from "./category";
import Booking from "./booking";
import Discount from "./discounts";
import Coupons from "./coupons/index";
import organization from "./organization";

function AdminContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path="/admin"
        render={() => <Redirect to="/admin/users" />}
      />
      <Route exact path={["/admin/security/role"]} component={Role} />
      <Route exact path={["/admin/dashboard"]} component={Dashboard} />
      <Route exact path={["/admin/users"]} component={User} />
      <Route exact path={["/admin/booking"]} component={Booking} />
      <Route exact path={["/admin/profile"]} component={Profile} />
      <Route exact path={["/admin/setting"]} component={Setting} />
      <Route exact path={["/admin/advertisements"]} component={Advertisement} />
      <Route exact path={["/admin/organization"]} component={organization} />
      <Route exact path={["/admin/category"]} component={Category} />
      <Route exact path={["/admin/coupons"]} component={Coupons} />
      <Route exact path={["/admin/discount"]} component={Discount} />

      <Route render={() => <h3>Coming soon.</h3>} />
    </Switch>
  );
}
export default withTranslation("translations")(AdminContentRouting);
