import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import TranslateIcon from "@material-ui/icons/Translate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageConfig from "../../../../config/LanguageConfig";
import Tooltip from "@material-ui/core/Tooltip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Hidden } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: "#00275A",
      // background: "#2c72ff",
    },
    backgroundImage: theme.custom.topHeader.toolbarColor,
    background: "#00275A",
    // background: "#2c72ff",
  },
  appBarDrawerClose: {
    backgroundImage: theme.custom.topHeader.toolbarColor,
    background: "#00275A",
    // background: "#2c72ff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  floatRight: {
    textAlign: "right",
  },
  languageMenuColor: {
    color: theme.custom.topHeader.toolBarLanguage,
    background: "transparent",
  },
  logoutIcon: {
    color: theme.custom.topHeader.toolBarLogout,
  },
}));

function TopBar(props) {
  const classes = useStyles();
  const [languageList, setLanguageList] = React.useState([]);
  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
    props.i18n.changeLanguage(props.languageData.code);
  }, []);
  const notificationTest = () => {
    SnackbarUtils.sucess("HI", "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  const languageChange = (data) => {
    props.i18n.changeLanguage(data.code);
    props.languageChange(data);
    localStorage.setItem("lng", JSON.stringify(data));
    handleLanguageMenuClose();
  };
  const [languageDropdown, setLanguageDropdown] = React.useState(null);
  const handleLanguageMenuClick = (event) => {
    setLanguageDropdown(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageDropdown(null);
  };
  return (
    <div>
      <AppBar
        position="fixed"
        className={
          props.mobileOpen ? classes.appBarDrawerClose : classes.appBar
        }
      >
        <Toolbar>
          <Tooltip ml={2} title={props.t("topNavBar.menu")}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <Typography variant="h6" className={classes.title}>
            {props.t(props.pageName)}
          </Typography>
          <Hidden only={["sm", "xs"]}>
            <Tooltip ml={2} title={props.t("topNavBar.language")}>
              <Button
                className={classes.languageMenuColor}
                aria-controls="simple-menu"
                aria-haspopup="true"
                startIcon={<TranslateIcon />}
                endIcon={<ExpandMoreIcon />}
                onClick={handleLanguageMenuClick}
              >
                {props.languageData !== undefined && props.languageData !== null
                  ? props.languageData.displayName
                  : "English"}
              </Button>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={languageDropdown}
              keepMounted
              open={Boolean(languageDropdown)}
              onClose={handleLanguageMenuClose}
            >
              {languageList.map((lng, lngIndex) => (
                <MenuItem
                  key={lngIndex}
                  selected={
                    lng.displayName === props.languageData.displayName
                      ? true
                      : false
                  }
                  onClick={() => languageChange(lng)}
                >
                  {lng.displayName}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip ml={2} title={props.t("topNavBar.logout")}>
              <IconButton onClick={() => props.logout()}>
                <ExitToAppIcon className={classes.logoutIcon} />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Hidden only={["xl", "lg", "md"]}>
            <Tooltip ml={2} title={props.t("topNavBar.moreMenu")}>
              <IconButton
                onClick={() => {
                  props.handleBottomDrawerOpen();
                }}
              >
                <MoreVertIcon
                  onClick={() => {
                    props.handleBottomDrawerOpen();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

TopBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    languageData: state.languageData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    languageChange: (languageData) =>
      dispatch({ type: "LANGUAGE", value: languageData }),
    logout: () => dispatch({ type: "LOGOUT", value: "" }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(TopBar)
);
