import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import { MdDeleteSweep, MdCloudUpload } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
// import ChipInput from "../../../../templates/ChipInput";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalStorage from "../../../../config/LocalStorage";
import FileUploadTableCheckBox from "../../../../templates/FileUploadTableCheckBox";
import FileUpload from "../../../../templates/FileUpload";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import FormValidation from "../../../../utils/FormValidation";
import Toaster from "../../../../utils/Toaster";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { IoIosInformationCircle } from "react-icons/io";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));
const addFormDetails = {
  title: "",
  description: "",
  adPosition: "",
  siteLink: "",
  // category: "",
  typeOfLink: "",
  priority: "normal", // high, normal,low
  active: true,
  images: [],
  searchKey: [], //chip input
  users: [], //inventory ids
  bannerList: [
    // { image: '', siteLink: '', searchKey: [], category: '', subCategory: '' },
  ],
  startAt: new Date().setSeconds(0),
  endAt: new Date(new Date().setDate(new Date().getDate() + 7)).setSeconds(59),
};
function AEVForm(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(addFormDetails);
  const [bannerListVal, setBannerListVal] = useState({
    image: "",
    siteLink: "",
    searchKey: [],
    // category: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);

  const [galleryFileBase64, setGalleryFileBase64] = useState([]);

  const [bannerCheckBox, setBannerCheckBox] = useState([]);

  const [aws] = useState(LocalStorage.aws);
  const [attachmentsFileBase64, setattachmentsFileBase64] = useState([]);
  const [file, setFile] = useState("");
  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const bannerFileUpload = useRef();

  const galleryFileUpload = useRef();

  const [adPositionOptions, setAdPositionOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUserDropdown();
  }, []);

  const getUserDropdown = () => {
    APIRequest.request("GET", ConfigAPIURL.getUserDropdown, "").then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setUserList(response.data.result);
          }
        }
      }
    );
  };
  useEffect(() => {
    // getCategoryOptions();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAddForm(addFormDetails);
    setGalleryFileBase64([]);
    setBannerListVal({ image: "", siteLink: "", searchKey: [] });
  };

  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  const handleTooltipOpen = () => {
    setMoreInfoOpen(true);
  };

  const getCategoryOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: "category" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setCategoryOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const handleTooltipClose = () => {
    setMoreInfoOpen(false);
  };

  const handleGalleryImage = (event) => {
    if (
      aws !== "" &&
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null
    ) {
      if (
        event.target.files[0].type.endsWith("jpeg") ||
        event.target.files[0].type.endsWith("png")
      ) {
        [...event.target.files].map(async (data) => {
          let img = new Image();

          img.src = window.URL.createObjectURL(data);

          img.onload = () => {
            // if (img.width !== 436) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageWidthRequired') + img.width,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }
            // if (img.height !== 224) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageHeightRequired') + img.height,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }

            let reader = new FileReader();
            let url = "";
            if (data.size < 1000000) {
              url = reader.readAsDataURL(data);
            } else {
              SnackbarUtils.error(
                props.t("snackbar.fileLarge"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            SnackbarUtils.sucess(
              props.t("snackbar.uploadReady"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            reader.onloadend = function () {
              setGalleryFileBase64(reader.result.split("base64,")[1]);
              setFile(data);
              setFileUploadStatus(true);
              galleryFileUpload.current._s3Upload();
            }.bind(this);
          };

          return;
        });
      } else {
        setFileUploadStatus(false);
        setGalleryFileBase64("");
        setFile("");
        SnackbarUtils.error(
          props.t("snackbar.onlyImage"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      setFileUploadStatus(false);
      setGalleryFileBase64("");
      setFile("");
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const imageTableRowDetails = (selected) => {
    if (selected !== undefined && selected !== null) {
      setSelectedImages(selected);
    }
  };

  const _s3GalleryImageUrl = (url) => {
    let user = addForm;
    user.images.push(url);
    setAddForm(user);
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
    setFileUploadStatus(false);
    setGalleryFileBase64("");
    setFile("");
  };

  const _deleteS3Files = (event) => {
    let form = addForm;
    selectedImages.map((selectedimages, idx) => {
      let index = form.images.indexOf(selectedimages);
      if (index !== -1) {
        form.images.splice(index, 1);
      }
    });
    setAddForm({ ...addForm, form });
  };

  const handleCategoryOptions = (event) => {
    setAddForm({
      ...addForm,
      category: event.target.value,
    });
  };
  const handleBannerCategoryOptions = (event) => {
    setBannerListVal({
      ...bannerListVal,
      category: event.target.value,
    });
  };
  const convertDatetostring = (date, type) => {
    return date * 1000;
    // console.log(convertedDate);
    // let d = convertedDate.toString();
    // return d;
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getAdvertisementDetails,
        JSON.stringify({ AdvId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.adv;
            // userResult.startAt = userResult.startAt * 1000;
            // userResult.endAt = userResult.endAt * 1000;
            userResult.searchKey = userResult.searchKey;

            setAddForm({
              ...addForm,

              title: userResult.title,
              description: userResult.description,
              adPosition: userResult.adPosition,
              siteLink: userResult.siteLink,
              // category: userResult.category,
              typeOfLink: userResult.typeOfLink,
              priority: userResult.priority,
              active: userResult.active,
              images: userResult.images,
              searchKey: userResult.searchKey,
              users: userResult.users,
              bannerList: userResult.bannerList,
              startAt: userResult.startAt,
              endAt: userResult.endAt,
            });
          }
        }
      });
    }
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.advertisementCreate;
    let method = "POST";

    if (props.formDialog.divType === "edit") {
      url = ConfigAPIURL.updateAdvertisement;
      addForm["AdvId"] = props.rowDetails._id;
      method = "PUT";
    }

    let fieldValidation =
      addForm.typeOfLink === "external"
        ? ["title", "endAt", "startAt", "typeOfLink", "siteLink"]
        : addForm.typeOfLink === "internal"
        ? ["title", "searchKey", "endAt", "startAt", "typeOfLink"]
        : ["title", "typeOfLink"];
    FormValidation.validation(fieldValidation, addForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          if (
            (addForm.typeOfLink === "internal" ||
              addForm.typeOfLink === "external") &&
            addForm.images.length < 1
          ) {
            Toaster.error(props.t("toaster.uploadImages"), "topRight");
            return;
          }
          let productIds = [];
          if (addForm.typeOfLink === "product") {
            addForm.users.map((productsSelected) => {
              productIds.push(productsSelected._id);
            });
          }
          APIRequest.request(method, url, JSON.stringify(addForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.transComplete"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  props.tableQuery({
                    keyword: "",
                    page: 0,
                    pageSize: 10,
                    sortField: "",
                    userType: "all",
                    sortOrder: "false",
                    active: true,
                  });
                  setAddForm(addFormDetails);
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleBannerImage = (event) => {
    if (aws !== "") {
      [...event.target.files].map(async (data) => {
        let reader = new FileReader();
        let url = "";
        if (data.size < 3000000) {
          url = reader.readAsDataURL(data);
        } else {
          SnackbarUtils.error(
            props.t("snackbar.fileLarge"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          return;
        }
        SnackbarUtils.sucess(
          props.t("snackbar.uploadReady"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });

        reader.onloadend = function () {
          // SnackbarUtils.error('Error uploading file',   'bottomCenter', 3000).then(
          //   (notification) => {
          //     props.publishNotification(notification);
          //   }
          // );
          setattachmentsFileBase64(reader.result.split("base64,")[1]);
          setFile(data);
          setFileUploadStatus(true);
          bannerFileUpload.current._s3Upload();
        }.bind(this);
      });
    } else {
      setFileUploadStatus(false);
      setattachmentsFileBase64("");
      setFile("");
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const _s3BannerImageUrl = (url) => {
    setFileUploadStatus(false);
    setattachmentsFileBase64("");
    setFile("");
    setBannerListVal({
      ...bannerListVal,
      image: url,
      category: "all",
    });
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
  };

  const handleBannerPush = () => {
    let fieldValidation = ["image", "siteLink", "searchKey"];
    FormValidation.validation(fieldValidation, bannerListVal).then(
      (ValidationResult) => {
        if (ValidationResult) {
          let adform = addForm;
          adform.bannerList.push(bannerListVal);
          let bannercheckbox = bannerCheckBox;
          bannercheckbox.push(false);
          setBannerListVal({ ...bannerCheckBox }, bannercheckbox);
          setAddForm(adform);
          setBannerListVal({
            ...bannerListVal,
            image: "",
            siteLink: "",
            searchKey: [],
            // category: "",
          });
        }
      }
    );
  };

  const handleCheckBox = (event, index) => {
    let bannerCheck = bannerCheckBox;
    bannerCheck[index] = event.target.checked;
    setBannerCheckBox([...bannerCheckBox], bannerCheck);
  };

  const handleDeleteBanner = () => {
    let form = addForm;
    bannerCheckBox.map((banners, index) => {
      if (banners) {
        delete form.bannerList[index];
      }
    });
    let temp = [];
    form.bannerList.map((banner, idx) => {
      if (banner) {
        temp.push(banner);
      }
    });

    form.bannerList = temp;
    setBannerCheckBox([false]);

    setAddForm({ ...addForm, form });
  };

  const handleStartAtDateChange = (date) => {
    let current = new Date().setSeconds(0);
    let start = new Date(date).setSeconds(0);
    let end;

    if (start === current) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (new Date(start).getTime() >= end) {
      SnackbarUtils.error(
        props.t("toaster.startDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      startAt: start,
    });
  };
  const handleEndAtDateChange = (date) => {
    let current = new Date().setSeconds(0);
    let end = new Date(date).setSeconds(59);
    let start;
    if (start === current) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (new Date(end).getTime() <= start) {
      SnackbarUtils.error(
        props.t("toaster.endDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      endAt: end,
    });
  };
  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("advertisements.title")}
                  autoComplete="off"
                  required
                  value={addForm.title}
                  fullWidth
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      title: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  variant="outlined"
                  style={{margin:'5px'}}
                />
              </Grid>
              <Grid
                xl={8}
                lg={8}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "-2px" }}
                className={classes.formGrid}
              >
                <TextField
                  id="description"
                  label={props.t("advertisements.description")}
                  autoComplete="off"
                  multiline
                  // rows={1}
                  // required
                  fullWidth
                  value={addForm.description}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      description: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  variant="outlined"
                  style={{margin:'5px'}}
                />
              </Grid>

              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                className={classes.formGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDateTimePicker
                      animateYearScrolling={false}
                      variant="dialog"
                      required
                      fullWidth
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      disablePast={
                        props.formDialog.divType === "edit" ||
                        props.formDialog.divType === "view"
                          ? false
                          : true
                      }
                      format="dd/MM/yyyy hh:mm a"
                      margin="normal"
                      id="startAt"
                      label={props.t("advertisements.startAt")}
                      value={addForm.startAt}
                      onChange={(date) => {
                        handleStartAtDateChange(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      maxDate={
                        addForm.endAt !== null
                          ? new Date(addForm.endAt)
                          : new Date()
                      }
                      
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                className={classes.formGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDateTimePicker
                      animateYearScrolling={false}
                      // disableFuture={true}
                      // disablePast={true}
                      variant="dialog"
                      required
                      fullWidth
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      format="dd/MM/yyyy hh:mm a"
                      margin="normal"
                      id="endAt"
                      label={props.t("advertisements.endAt")}
                      value={addForm.endAt}
                      onChange={(date) => {
                        handleEndAtDateChange(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={
                        addForm.startAt !== null
                          ? new Date(addForm.startAt)
                          : new Date(0)
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: '13px' }}
                className={classes.formGrid}
              >
                <TextField
                  id='priority'
                  label={props.t('advertisements.priority')}
                  // className={classes.userTypeMenuItem}
                  select
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  required
                  fullWidth
                  defaultValue={addForm.priority}
                  value={addForm.priority}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      priority: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={'low'}>
                    {props.t('advertisements.low')}
                  </MenuItem>
                  <MenuItem key={1} value={'normal'}>
                    {props.t('advertisements.normal')}
                  </MenuItem>
                  <MenuItem key={2} value={'high'}>
                    {props.t('advertisements.high')}
                  </MenuItem>
                </TextField>
              </Grid>
            */}
              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: "15px" }}
                // className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("advertisements.active")}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={addForm.active}
                  value={addForm.active}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      active: event.target.value,
                    })
                  }
                  variant="outlined"
                  style={{margin:'5px'}}
                >
                  <MenuItem key={0} value={true}>
                    {props.t("advertisements.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("advertisements.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>
              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='adPosition'
                  label={props.t('advertisements.position')}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  fullWidth
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  defaultValue={addForm.adPosition}
                  value={addForm.adPosition}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      adPosition: event.target.value,
                    })
                  }
                >
                  {adPositionOptions.map((options, index) => (
                    <MenuItem key={index} value={options.value}>
                      {options.display}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: "15px" }}
                // className={classes.formGrid}
              >
                <TextField
                  label={props.t("advertisements.typeoflink")}
                  select
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  id="typeOfLink"
                  defaultValue={addForm.typeOfLink}
                  value={addForm.typeOfLink}
                  required
                  fullWidth
                  onChange={(event) => {
                    setAddForm({
                      ...addForm,
                      typeOfLink: event.target.value,
                      // category: "",
                      users: [],
                      searchKey: [],
                      siteLink: "",
                      images: [],
                    });
                    setBannerListVal({
                      ...bannerListVal,
                      image: "",
                      siteLink: "",
                      searchKey: [],
                      // category: "",
                    });
                  }}
                  variant="outlined"
                  style={{margin:'5px'}}
                >
                  {/* <MenuItem key={0} value={"external"}>
                    {props.t("advertisements.link.external")}
                  </MenuItem>
                  <MenuItem key={1} value={"internal"}>
                    {props.t("advertisements.link.internal")}
                  </MenuItem> */}
                  <MenuItem key={2} value={"user"}>
                    {props.t("advertisements.link.user")}
                  </MenuItem>
                  <MenuItem key={3} value={"banner"}>
                    {props.t("advertisements.link.banner")}
                  </MenuItem>
                  <MenuItem key={4} value={"promotion"}>
                    {props.t("advertisements.link.promotion")}
                  </MenuItem>
                </TextField>
              </Grid>

              {/* {addForm.typeOfLink === "external" ? (
                <>
                  <Grid
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                    style={{ marginTop: "15px" }}
                  >
                    <TextField
                      id="siteLink"
                      label={props.t("advertisements.siteLink")}
                      autoComplete="off"
                      required
                      fullWidth
                      value={addForm.siteLink}
                      onChange={(event) =>
                        setAddForm({
                          ...addForm,
                          siteLink: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {props.formDialog.divType === "new" ||
                  props.formDialog.divType === "edit" ? (
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <Grid container>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ display: "flex" }}
                        >
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="galleryImageFileUploader"
                            // multiple
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            onChange={handleGalleryImage}
                            type="file"
                          />
                          <label htmlFor="galleryImageFileUploader">
                            <Button
                              style={{ marginTop: "10px" }}
                              variant="contained"
                              color="primary"
                              component="span"
                              className={"mr-2"}
                            >
                              <MdCloudUpload
                                style={{
                                  fontSize: 20,
                                  marginRight: "20px",
                                }}
                              />
                              {props.t("advertisements.uploadImages")}
                            </Button>
                          </label>

                          <ClickAwayListener
                            onClickAway={handleTooltipClose}
                            style={{ marginTop: "10px" }}
                          >
                            <div>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={moreInfoOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={props.t(
                                  "advertisements.moreInfoAboutFileUpload"
                                )}
                              >
                                <IconButton
                                  aria-label="view"
                                  onClick={handleTooltipOpen}
                                >
                                  <IoIosInformationCircle />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </ClickAwayListener>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Button
                            style={{ marginTop: "10px", float: "right" }}
                            variant="contained"
                            color="primary"
                            component="span"
                            className={"mr-2"}
                            onClick={_deleteS3Files}
                          >
                            <MdDeleteSweep
                              style={{
                                fontSize: 20,
                                marginRight: "20px",
                              }}
                            />
                            {props.t("advertisements.deleteFile")}
                          </Button>
                        </Grid>
                      </Grid>

                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FileUploadTableCheckBox
                      imageTableCallBack={imageTableRowDetails}
                      data={addForm.images}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )} */}
              {/* {addForm.typeOfLink === "internal" ? (
                <>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <ChipInput
                      id="searchKey"
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      fullWidth
                      required
                      defaultValue={addForm.searchKey}
                      onChange={(chip) =>
                        setAddForm({
                          ...addForm,
                          searchKey: chip,
                        })
                      }
                      allowDuplicates={false}
                      placeholder={props.t("advertisements.enterSearchKey")}
                      label={props.t("advertisements.searchKey")}
                    />
                  </Grid>
                  {props.formDialog.divType === "new" ||
                  props.formDialog.divType === "edit" ? (
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="galleryImageFileUploader"
                        // multiple
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                        onChange={handleGalleryImage}
                        type="file"
                      />
                      <label htmlFor="galleryImageFileUploader">
                        <Button
                          style={{ marginTop: "10px" }}
                          variant="contained"
                          color="primary"
                          component="span"
                          className={"mr-2"}
                        >
                          <MdCloudUpload
                            style={{
                              fontSize: 20,
                              marginRight: "20px",
                            }}
                          />
                          {props.t("advertisements.uploadImages")}
                        </Button>
                      </label>
                      <Tooltip
                        title={props.t(
                          "advertisements.moreInfoAboutFileUpload"
                        )}
                      >
                        <IconButton aria-label="view">
                          <IoIosInformationCircle />
                        </IconButton>
                      </Tooltip>
                      <Button
                        style={{ marginTop: "10px", float: "right" }}
                        variant="contained"
                        color="primary"
                        component="span"
                        className={"mr-2"}
                        onClick={_deleteS3Files}
                      >
                        <MdDeleteSweep
                          style={{
                            fontSize: 20,
                            marginRight: "20px",
                          }}
                        />
                        {props.t("advertisements.deleteFile")}
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FileUploadTableCheckBox
                      imageTableCallBack={imageTableRowDetails}
                      data={addForm.images}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )} */}
              {addForm.typeOfLink === "user" ? (
                <>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <Autocomplete
                      id="products"
                      value={addForm.users}
                      options={userList}
                      getOptionLabel={(option) =>
                        option.fname + " (" + option.mobileNo + ")"
                      }
                      fullWidth
                      multiple
                      onChange={(event, selected) =>
                        setAddForm({
                          ...addForm,
                          users: selected,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={props.t("advertisements.searchUsers")}
                          variant="standard"
                        />
                      )}
                      variant="outlined"
                  style={{margin:'5px'}}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {addForm.typeOfLink === "promotion" ? (
                <>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <Autocomplete
                      id="products"
                      value={addForm.users}
                      options={userList}
                      getOptionLabel={(option) =>
                        option.fname + " (" + option.mobileNo + ")"
                      }
                      fullWidth
                      multiple
                      onChange={(event, selected) =>
                        setAddForm({
                          ...addForm,
                          users: selected,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={props.t("advertisements.searchUsers")}
                          // variant="standard"
                          variant="outlined"
                  style={{margin:'5px'}}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {addForm.typeOfLink === "banner" ? (
                <>
                  <Grid xl={6} lg={6} md={12} sm={12} xs={12}>
                    <TextField
                      label={props.t("advertisements.siteLink")}
                      id="siteLink"
                      fullWidth
                      required
                      value={bannerListVal.siteLink}
                      onChange={(event) =>
                        setBannerListVal({
                          ...bannerListVal,
                          siteLink: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      autoComplete="off"
                      variant="outlined"
                  style={{margin:'5px'}}
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <ChipInput
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      id="searchKey"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      // value={
                      //   bannerListVal.searchKey.length > 0
                      //     ? bannerListVal.searchKey.split(",")
                      //     : null
                      // }
                      value={
                        typeof bannerListVal.searchKey === "string"
                          ? bannerListVal.searchKey.split(",")
                          : bannerListVal.searchKey
                      }
                      onChange={(chip) => {
                        console.log("Chip", chip.toString());
                        setBannerListVal({
                          ...bannerListVal,
                          searchKey: chip.toString(),
                        });
                      }}
                      allowDuplicates={false}
                      placeholder={props.t("advertisements.enterSearchKey")}
                      label={props.t("advertisements.searchKey")}
                      variant="outlined"
                  style={{margin:'5px',marginTop:'10px'}}
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      label={props.t("advertisements.bannerImage")}
                      id="image"
                      required
                      value={bannerListVal.image}
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="bannerImage"
                              // multiple
                              disabled={
                                props.formDialog.divType === "new" ||
                                props.formDialog.divType === "edit"
                                  ? false
                                  : true
                              }
                              onChange={handleBannerImage}
                              type="file"
                            />
                            <label htmlFor="bannerImage">
                              <Button
                                style={{ marginTop: "10px" }}
                                variant="contained"
                                color="primary"
                                component="span"
                                className={"mr-2"}
                              >
                                <MdCloudUpload
                                  style={{
                                    fontSize: 20,
                                    marginRight: "20px",
                                  }}
                                />
                                {props.t("advertisements.uploadBanner")}
                              </Button>
                            </label>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                  style={{margin:'5px'}}
                    />
                  </Grid>

                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                    style={{ textAlign: "center" }}
                  >
                    <Tooltip title={props.t("advertisements.add")}>
                      <IconButton aria-label="add" onClick={handleBannerPush}>
                        <AddBoxIcon fontSize={"large"} variant="contained" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={props.t("advertisements.delete")}>
                      <IconButton aria-label="add" onClick={handleDeleteBanner}>
                        <DeleteIcon fontSize={"large"} variant="contained" />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    {addForm.bannerList.map((banners, index) => (
                      <Grid container key={index}>
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Checkbox
                            checked={bannerCheckBox[index]}
                            onChange={(event) => handleCheckBox(event, index)}
                            name="checkedA"
                          />
                        </Grid>

                        {/* <Grid xl={4} lg={4} md={12} sm={12} xs={12}>
                          <TextField
                            id="category"
                            label={props.t("advertisements.category")}
                            autoComplete="off"
                            value={banners.category}
                            fullWidth
                            // disabled
                          />
                        </Grid> */}

                        <Grid xl={4} lg={4} md={12} sm={12} xs={12}>
                          <TextField
                            id="siteLink"
                            label={props.t("advertisements.siteLink")}
                            autoComplete="off"
                            value={banners.siteLink}
                            fullWidth
                            // disabled
                            variant="outlined"
                  style={{margin:'5px'}}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={4}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ margin: "0px 0px" }}
                        >
                          <ChipInput
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder={props.t(
                              "advertisements.enterSearchKey"
                            )}
                            label={props.t("advertisements.searchKey")}
                            autoComplete="off"
                            value={
                              typeof banners.searchKey === "string"
                                ? banners.searchKey.split(",")
                                : banners.searchKey
                            }
                            fullWidth
                            // disabled
                            variant="outlined"
                  style={{margin:'5px'}}
                          />
                        </Grid>
                        <Grid
                          xl={8}
                          lg={8}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ margin: "40px 0px" }}
                        >
                          <TextField
                            id="image"
                            label={props.t("advertisements.bannerImage")}
                            autoComplete="off"
                            value={banners.image}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    src={banners.image}
                                    height={100}
                                    width={100}
                                  ></img>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                  style={{margin:'5px'}}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <FileUpload
        ref={galleryFileUpload}
        aws={aws}
        file={file}
        base64={galleryFileBase64}
        bucket={aws.bucket}
        callback={_s3GalleryImageUrl}
      />
      <FileUpload
        ref={bannerFileUpload}
        aws={aws}
        file={file}
        base64={attachmentsFileBase64}
        bucket={aws.bucket}
        callback={_s3BannerImageUrl}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
