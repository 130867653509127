import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import { Alert } from "@material-ui/lab";

import SlideInDialog from "../../templates/dialog/SlideInDialog";

import LocalStorage from "../../config/LocalStorage";
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ margin: "0 auto !important" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        {LocalStorage.ProjectName}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    padding: "20px",
    backgroundColor: "#9DBBF9",
    backgroundImage:
      "linear-gradient(135deg, rgba(44, 114, 255, 0) 0%, #2C72FF 100%)",
    cursor: "default",
    display: "flex",
    justifyContent: "center",
  },
  imageGrid: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  titleDiv: {
    height: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  vectorDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "400px",
    width: "500px",
  },
  loginGrid: {
    width: "100%",
    height: "100%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background:"linear-gradient(-45deg, rgb(200 207 212) 0%, rgb(200 208 215) 33%, rgb(195 244 238) 100%)",
  },
  paper: {
    // width: "50%",
    width: "500px",
    height: "500px",
    margin: 4,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "10px",
    boxShadow: " 0px 8px 4px #9DBBF9",
    padding: "40px",
    textAlign: "center",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  avatar: {
    margin: 1,
    backgroundColor: "#9DBBF9",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    margin: "10px",
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  submit: {
    backgroundColor: "#00275A !important",
    // backgroundColor: "#2c72ff !important",
    borderRadius: "6px !important",
    padding: "16px, 90px, 16px, 90px !important",
    marginTop: "18px !important",
    height: "48px !important",
    // width: "247px !important",
    fontWeight: "700 !important",
  },
  textAlign: {
    textAlign: "end",
  },
}));

function Login(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");

  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);

  const [otp, setOTP] = useState("");
  const [twoFactAut, setTowFactAuth] = useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  useEffect(() => {
    isLogin();
  }, []);

  const handlePasswordChange = () => {
    if (mobileNo === "") {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter your mobile number",
      });
      return;
    }
    setSlideInDialog(true);
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.forgotPassword + "?username=" + mobileNo,
        ""
      ).then((response) => {
        setSlideInDialog(false);
        if (response.code === 100 && response.data.responseCode === 101) {
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "New password is generated and sent over the email",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 116
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Exceeded password attempt",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 115
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User login email is not verified",
          });
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User email id not found / wrong email id /deactivated",
          });
        }
      });
    } else {
      setSlideInDialog(false);
    }
  };

  const submitMobileLogin = () => {
    if (mobileNo === "") {
      return Toaster.error("Enter your Mobile Number", "topRight");
    }
    if (password === "") {
      return Toaster.error("Enter your Password", "topRight");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.accountLogin,
      JSON.stringify({
        email: mobileNo,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response.data.responseCode === 108 ||
        response.data.responseCode === 103
      ) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Wrong Credentials",
        });
      } else if (response.data.responseCode === 116) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Exceeded Password attempt",
        });
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 105
      ) {
        isLogin();
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 122
      ) {
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP sent to your mobile number",
        });
        setTowFactAuth(true);
      } else if (response.data.responseCode === 104) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Password is not matching, please check your password",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };

  const verifyOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOtp,
      JSON.stringify({
        otpVal: otp,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          isLogin();
        } else if (response.data.responseCode === 118) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Invalid OTP",
          });
        }
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };
  const isLogin = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        //redirect based on user type
        if (response !== undefined && response !== null) {
          if (response.data !== undefined && response.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
              } else if (response.data.responseCode === 109) {
                let userDetails = {};
                if (
                  response.data.user !== null &&
                  response.data.user.permission !== undefined
                ) {
                  userDetails = {
                    email: response.data.user.email,
                    mobileNo: response.data.user.mobileNo,
                    name: response.data.user.name,
                    permission: response.data.user.permission,
                    profileImage: response.data.user.profileImage,
                    userName: response.data.user.userName,
                    userType: response.data.user.userType,
                    isSuperAdmin: response.data.user.isSuperAdmin,
                  };
                  LocalStorage.userDetails = userDetails;

                  console.log("login", userDetails);
                } else {
                  setSnakbarValues({
                    status: true,
                    severity: "error",
                    message: "User does not have permission to login",
                  });

                  logoutFunction();
                }

                //redirect based on user type
                if (
                  userDetails.userType !== undefined &&
                  userDetails.userType === "admin"
                ) {
                  props.history.replace("/admin");
                  // } else if (userDetails.userType === 'user') {
                  //   props.history.replace('/user');
                } else if (
                  userDetails.userType !== undefined &&
                  userDetails.userType === "partner"
                ) {
                  props.history.replace("/partner");
                } else {
                  props.history.replace("/login");
                }
              } else {
                setSnakbarValues({
                  status: true,
                  severity: "error",
                  message: "Server Error, Please try after sometime",
                });
              }
            } else {
              setSnakbarValues({
                status: true,
                severity: "error",
                message: "Server Error, Please try after sometime",
              });
            }
          } else {
            setSnakbarValues({
              status: true,
              severity: "error",
              message: "Server Error, Please try after sometime",
            });
          }
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Server Error, Please try after sometime",
          });
        }
      }
    );
  };

  const _enableResendOTPButton = () => {
    setTimeout(function () {
      setDisableOtpBtn(false);
    }, 5000);
  };

  const logoutFunction = (props) => {
    APIRequest.request("GET", ConfigAPIURL.adminLogout, "").then((response) => {
      if (response.code === 100) {
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    });
    sessionStorage.clear();
    localStorage.clear();
    // LocalStorage.permission = null;
  };

  const resendOTP = () => {
    setDisableOtpBtn(true);
    APIRequest.request("POST", ConfigAPIURL.resendOtp, "").then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          _enableResendOTPButton();

          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Sucessfully sent OTP",
          });
        } else {
          setDisableOtpBtn(false);
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Please try after some time",
          });
        }
      } else {
        setDisableOtpBtn(false);
        setSnakbarValues({
          status: true,
          severity: "info",
          message: "Please try after some time",
        });
      }
    });
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />

      <Grid item xl={8} lg={8} sm={8} xs={8} className={classes.loginGrid}>
        <div className={classes.paper}>
          <Typography
            style={{ fontSize: "28px", fontWeight: 600, color: "#444" }}
          >
            {/* Sign in to {LocalStorage.ProjectName} */}
            Login
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
          >
            Please provide registered mobile number with password to login
          </Typography>
          {!twoFactAut ? (
            <div className={classes.form}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={mobileNo}
                onKeyPress={(event) => {
                  return event.charCode >= 48 && event.charCode <= 57
                    ? event
                    : event.preventDefault();
                }}
                // onChange={(event) => setMobileNo(event.target.value)}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length <= 10) {
                    setMobileNo(value);
                  }
                }}
                id="mobileNo"
                autoComplete="off"
                label="Mobile Number"
                name="mobileNo"
              />

              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                name="password"
                autoComplete="off"
                label="Password"
                type="password"
                id="password"
              />
              {/* <div className={classes.forgotPassword}>
                <Typography style={{ color: "#555", fontSize: "15px" }}>
                </Typography>
                <Link
                  href="javascript:void(0)"
                  onClick={handlePasswordChange}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    style={{
                      color: "#800000",
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </div> */}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => submitMobileLogin()}
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </div>
          ) : (
            <div className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                value={otp}
                onKeyPress={(event) => {
                  return event.charCode >= 48 && event.charCode <= 57
                    ? event
                    : event.preventDefault();
                }}
                onChange={(event) => setOTP(event.target.value)}
                fullWidth
                autoComplete="off"
                name="otp"
                label="OTP"
                type="password"
                id="otp"
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => verifyOTP()}
              >
                Submit
              </Button>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  {!disableOtpBtn ? (
                    <Link
                      href="javascript:void(0)"
                      onClick={() => resendOTP()}
                      variant="body2"
                    >
                      Resend OTP
                    </Link>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          {/* <Box style={{ margin: "10px auto" }}>
            <Copyright />
          </Box> */}
        </div>
      </Grid>
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
      <SlideInDialog
        title={"Need to reset your password?"}
        contentComponent={
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                I would like to reset my password
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              align="justify"
              style={{ margin: "10px 0px" }}
            >
              <Divider />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                By clicking “Agree” below you will get an email with a link to
                reset your password. After clicking the link to reset your
                password in that email you will get another email with system
                generated password.'
              </Typography>
            </Grid>
          </Grid>
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </Grid>
  );
}

export default Login;
