import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
// import Select from 'react-select';

import {
  Grid,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  Paper,
  FormGroup,
  Tooltip,
  IconButton,
  CircularProgress,
  makeStyles,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
// import { makeStyles } from "@mui/styles";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import imageCompression from "browser-image-compression";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import {
  // Autocomplete,
  Box,
  FormControl,
  Input,
  InputLabel,
  Select,
  // Stack,
  Typography,
} from "@material-ui/core";
import { Stack } from "react-bootstrap";

import "./discount.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CopyToClipboard from "react-copy-to-clipboard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

import TagsInput from "react-tagsinput";
// import { WithContext as ReactTags } from "react-tag-input";
import "./modalSkills.css";
import { event } from "jquery";
var voucher_codes = require("voucher-code-generator");

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },

  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  chipRoot: {
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
}));
const userFormDetails = {
  active: true,
  isParent: true,
  parentTitle: "",
  title: "",
  logo: "",
  i18n: {
    en_US: { title: "" },
  },
  oraganisationName: "",
  elligibileMentors: [],
  appliedCustomers: [],
  amount: "",
  startDate: null,
  endDate: null,
  validity: "",
  couponCode: "",
  selectField: true,
  incubatorId: "",
  type: "eligible",
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState({ ...userFormDetails });
  const [logoUploadStatus, setLogoUploadStatus] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [allMentors, setAllMentors] = useState([]);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const voucherGenerator = () => {
    return voucher_codes.generate({
      length: 6,
      count: 1,
      charset: voucher_codes.charset("alphanumeric"),
    });
  };

  //tags area

  useEffect(() => {
    fetchMentors();
  }, []);

  const fetchMentors = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.GetAllMentors,
        ""
      );
      if (response !== undefined && response !== null) {
        if (response?.data?.responseCode === 109) {
          // setAvailableDates(response?.data?.result);
          let userResult = [];
          const result = response?.data?.result;
          console.log("this is result", result);
          if (response?.data?.result && response?.data?.result.length > 0) {
            result.forEach((value) => {
              userResult.push({
                label: value?.fname,
                id: value?._id,
              });
            });
          }
          console.log("this is all mentors", userResult);
          setAllMentors(userResult);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //getting all mentors email
  const [email, setEmails] = useState([]);
  useEffect(() => {
    fetchMentorsEmail();
  }, []);

  const fetchMentorsEmail = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.GetAllMentors,
        ""
      );
      if (response !== undefined && response !== null) {
        if (response?.data?.responseCode === 109) {
          const result = response?.data?.result;
          const emails = result.map((obj) => obj.email);
          setEmails(emails);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  //tags end

  useEffect(() => {
    // getLanguageList();
  }, []);

  const getLanguageList = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: "languageOptions" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setLanguageList(response.data.options[0].options);
        }
      }
    });
  };

  console.log("Mentors", allMentors);

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({
        ...userFormDetails,
        userType: "user",
      });
    }
  }, [props.page]);

  useEffect(() => {
    setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setTags([]);
      setUserForm({
        ...userFormDetails,
        userType: "user",

        i18n: {
          en_US: { title: "" },
        },
      });
    } else {
      setTags([]);

      setUserForm({
        ...userFormDetails,
        i18n: {
          en_US: { title: "" },
        },
      });
    }
  };
  // console.log(userForm);
  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.DiscountDetails,
        JSON.stringify({ recordId: props.rowDetails._id })
      ).then((response) => {
        console.log("printing the discount details", response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.coupon;
            console.log("logging disconts details", userResult);

            setUserForm({
              ...userForm,
              active: userResult?.active,
              isParent: userResult?.isParent,
              parentTitle: userResult?.parentTitle,
              i18n: userResult?.i18n,
              title: userResult?.title,
              logo: userResult?.logo,
              incubatorId: userResult?.organization,
              oraganisationName: userResult?.title,
              elligibileMentors: userResult?.elligibileMentors,
              appliedCustomers: userResult?.appliedCustomers,
              amount: userResult?.amount,
              startDate: new Date(userResult?.startAt * 1000),
              endDate: new Date(userResult?.endAt * 1000),
              couponCode: userResult?.couponCode,
              type: userResult?.couponType,
            });
            setTags(userResult?.elligibileMentors);
            console.log("printing userRessult", userResult);

            setSelectedRowId(userResult?._id);
          }
        }
      });
    }
  };
  //keyboard shortcut
  // console.log("priting the userForn", userForm);
  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    if (
      !userForm?.incubatorId ||
      !userForm?.oraganisationName ||
      !userForm?.amount ||
      !userForm?.startDate ||
      !userForm?.endDate ||
      !userForm?.couponCode
    )
      return SnackbarUtils.error(
        props.t("snackbar.enterDetails"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    let url = ConfigAPIURL.createDiscount;
    let method = "POST";
    let passingData = {
      organization: userForm?.incubatorId,
      title: userForm?.oraganisationName,
      couponType: userForm?.type,
      elligibileMentors: userForm?.elligibileMentors,
      amount: +userForm?.amount,
      startAt: new Date(userForm?.startDate) / 1000,
      endAt: new Date(userForm?.endDate) / 1000,

      couponCode: userForm?.couponCode,
      appliedCustomer: [],
      active: true,
      publish: true,
    };
    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateDiscount;
      passingData["recordId"] = selectedRowId;
      method = "POST";
    }
    let fieldValidation = [];
    //  ["title", "active"];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(method, url, JSON.stringify(passingData)).then(
            (response) => {
              console.log(response);
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    method === "POST"
                      ? props.t("snackbar.savedSuccess")
                      : props.t("snackbar.updatedSuccess"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleLogoUpload = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setLogoUploadStatus(true);
      [...event.target.files].map(async (data) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(data);

        img.onload = async () => {
          let formData = new FormData();
          if (data.size > 3000000) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }

          if (data.size > 300000) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            let compressedFile = await imageCompression(
              event.target.files[0],
              options
            );

            formData.append(`attachment`, compressedFile);
          } else {
            formData.append(`attachment`, data);
          }

          formData.append("bucketName", "logo");

          APIRequest.multipartForDataRequest(
            "PUT",
            ConfigAPIURL.uploadFile,
            formData
          ).then((response) => {
            if (response !== undefined && response !== null) {
              setTimeout(function () {
                setLogoUploadStatus(false);
                setUserForm({
                  ...userForm,
                  logo: response.data.attachmentUrl[0],
                });
              }, 1000);
            } else {
              setLogoUploadStatus(false);
            }
          });
        };
      });
    } else {
      setLogoUploadStatus(false);
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const addLanguage = () => {
    const u = userForm;
    u.i18n[""] = { title: "" };
    setUserForm({ ...userForm, i18n: u.i18n });
  };

  const removeLanguage = (value) => {
    const u = userForm;
    delete u.i18n[value];
    setUserForm({ ...userForm, i18n: u.i18n });
  };
  const [tags, setTags] = useState([]);
  const [terms, setTerms] = useState("");
  const handleTermsChange = (e) => {
    setTerms(e.target.value);
  };

  const handleAddTags = () => {
    if (terms !== "") {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        elligibileMentors: [...prevUserForm.eligibleMentors, terms],
      }));
      setTerms("");
    }
  };

  // removing the tags from state
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (e) => {
    setTags([...tags, e.target.value]);
    e.target.value = "";
    setTerms([]);
  };

  // to update the current tags
  const UpdateTags = async () => {
    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdateSkills,
      JSON.stringify({
        skills: tags,
      })
    );
    //closes the popup
  };

  console.log("priting the tags", tags);
  console.log("priting the userForm", userForm);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleTypeChange = (event) => {
    setUserForm({ ...userForm, type: event.target.value });
  };

  //here we are saving the isSuperAdmin value for permissions
  useEffect(() => {
    isSuperAdmin();
  }, []);
  const [mentorPermission, setMentorPermission] = useState("");
  const isSuperAdmin = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (res) => {
        // console.log("the superAdmin",res.data.user.isSuperAdmin)
        setMentorPermission(res.data.user);
      }
    );
  };

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Stack
                  sx={{
                    px: 3,
                    py: 3,
                  }}
                  className="coupons__container"
                >
                  <Stack style={{ margin: "10px" }}>
                    <Typography className="coupons__title" variant="h2" mb={2}>
                      {props.t("Discount")}
                    </Typography>
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      margin: "10px",
                    }}
                  >
                    {/* <Stack direction="row"> */}
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        fullWidth
                        id="controllable-states-demo"
                        disablePortal
                        // options={allMentors}
                        options={allMentors.map((mentor) => mentor.label)}
                        value={userForm?.title || null}
                        // onChange={(event, newValue) => {
                        //   {
                        //     console.log(
                        //       "this is autocomp selected Mentor disc",
                        //       event.target.value,
                        //       " and ",
                        //       newValue,
                        //       " and ",
                        //       newValue
                        //     );
                        //   }
                        //   setUserForm((prevUserForm) => ({
                        //     ...prevUserForm,
                        //     title: newValue,
                        //     oraganisationName: newValue?.label || "",
                        //     incubatorId: newValue?.id || "",
                        //   }));
                        // }}
                        onChange={(e, term) => {
                          const selectedMentor = allMentors.find(
                            (mentor) => mentor.label === term
                          );
                          const incubatorId = selectedMentor
                            ? selectedMentor.id
                            : "";
                          // {
                          //   console.log(
                          //     "this is autocomp selectedMentor",
                          //     selectedMentor.label,
                          //     " and ",
                          //     incubatorId,
                          //     " and ",
                          //     term
                          //   );
                          // }

                          setUserForm({
                            ...userForm,
                            title: term,
                            oraganisationName: term ? term : "",
                            incubatorId: incubatorId,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="auto"
                            {...params}
                            label="Incubator / Organization Name"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                    {/* </Stack> */}
                    <div
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormGroup
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="active"
                              sx={{ textTransform: "capitalize" }}
                              checked={userForm.type === "general"}
                              value="general"
                              onChange={handleTypeChange}
                              style={{ display: "flex", flexDirection: "row" }}
                            />
                          }
                          label={props.t("general")}
                        />

                        <FormControlLabel
                          style={{ display: "flex", flexDirection: "row" }}
                          control={
                            <Checkbox
                              id="active"
                              sx={{ textTransform: "capitalize" }}
                              checked={userForm.type === "eligible"}
                              value="eligible"
                              onChange={handleTypeChange}
                              style={{ display: "flex", flexDirection: "row" }}
                            />
                          }
                          label={props.t("eligible")}
                        />
                      </FormGroup>
                    </div>

                    {/* starting email array is stored */}
                    {/*Div starts here for wrapping condition*/}

                    {/* <Stack alignItems="start" width="100%" >
                      <div className="tags-input" style={{ width: "100%",display:userForm.type==="general"?"none":"" }}>
                        <ul id="tags">
                          {tags &&
                            tags.map((tag, index) => (
                              <li key={index} className="tag" aria-disabled={userForm.type==="general"}>
                              
                                <span className="tag-title">{tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTags(index)}
                                  aria-disabled={userForm.type==="general"}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                        </ul>
                        <input
                          style={{ width: "100%" }}
                          disabled={userForm.type==="general"}
                          type="text"
                          onKeyUp={(event) =>
                            event.key === "Enter" ? addTags(event) : null
                          }
                          placeholder="E-mail ID"
                          value={terms}
                          onChange={(e) => setTerms(e.target.value)}
                        />
                      </div>
                      
                    </Stack> */}
                    {/* email */}
                    <Autocomplete
                      style={{
                        display: userForm.type === "general" ? "none" : "",
                      }}
                      limitTags={4}
                      fullWidth
                      multiple
                      id="controllable-states-demo"
                      disablePortal
                      options={email}
                      value={userForm?.elligibileMentors || []}
                      onChange={(event, value) => {
                        setUserForm({
                          ...userForm,
                          elligibileMentors: value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="auto"
                          {...params}
                          label="Emails"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    {/* /////////////////////////// */}
                    <Autocomplete
                      style={{
                        display: userForm.type === "general" ? "none" : "",
                      }}
                      limitTags={4}
                      fullWidth
                      multiple
                      id="controllable-states-demo"
                      disablePortal
                      options={
                        userForm?.appliedCustomers?.map((customer) => "") || []
                      }
                      value={
                        userForm?.appliedCustomers?.map(
                          (customer) => `${customer.fname} (${customer.email})`
                        ) || []
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            disabled={
                              userForm?.appliedCustomers.indexOf(option) !== -1
                            }
                            classes={{ root: classes.chipRoot }} // Apply custom styles to the chip
                          />
                        ))
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Applied Customers"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                          }}
                        />
                      )}
                    />

                    <TextField
                      size="small"
                      type="number"
                      id="input-with-icon-textfield"
                      label={props.t("Amount")}
                      variant="outlined"
                      value={userForm?.amount}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          amount: e.target.value,
                        });
                      }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <label htmlFor="startDate">Start Date</label>
                        <DatePicker
                          id="startDate"
                          className="date__picker"
                          minDate={new Date()}
                          style={{ width: "100%" }}
                          selected={userForm?.startDate}
                          onChange={(date) =>
                            setUserForm({
                              ...userForm,
                              startDate: date,
                            })
                          }
                          withPortal
                          dateFormat="dd/MM/yy"
                          // placeholderText="Discount Start Date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label htmlFor="startDate">End Date</label>
                        <DatePicker
                          minDate={
                            userForm?.startDate
                              ? userForm?.startDate
                              : new Date()
                          }
                          className="date__picker"
                          selected={userForm?.endDate}
                          onChange={(Date) =>
                            setUserForm({
                              ...userForm,
                              endDate: Date,
                            })
                          }
                          // placeholderText="Discount end Date"
                          withPortal
                          dateFormat="dd/MM/yy"
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      size="small"
                      type="text"
                      value={userForm?.couponCode}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) =>
                        setUserForm({
                          ...userForm,
                          couponCode: e.target.value,
                        })
                      }
                      id="input-with-icon-textfield"
                      label="Coupon Code (6 digits)"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            // sx={{ cursor: "pointer" }}
                            // style={{
                            //   display:
                            //     userForm.type === "general" ? "none" : "",
                            // }}
                          >
                            <CopyToClipboard
                              text={userForm?.couponCode}
                              onCopy={handleCopyClick}
                            >
                              <FileCopyIcon
                                // sx={{
                                //   cursor: "pointer",
                                //   opacity: copied ? 0.5 : 1,
                                //   // transition: "opacity 0.2s ease-in-out",
                                //   display:
                                //     userForm.type === "general" ? "none" : "",
                                // }}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </CopyToClipboard>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      helperText={copied ? "Copied!" : ""}
                      error={copied}
                    />

                    <Stack alignItems="end">
                      <button
                        onClick={() =>
                          setUserForm({
                            ...userForm,
                            couponCode: voucherGenerator()[0],
                          })
                        }
                        // variant="text"
                        // sx={{ border: "none", color: "black" }}
                        style={{
                          border: "none",
                          outline: "none",
                          background: "none",
                          justifySelf: "center",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {props.t("coupons.AutoGenerate")}
                      </button>
                    </Stack>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <Stack
                  justifyContent="center"
                  height="100%"
                  sx={{ px: 3, py: 3 }}
                  className="coupons__container"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666357569425_Allura%20-%20Feedback%20Session%201.png"
                    alt="feedback"
                  />
                  <Typography className="coupon__text">
                    Create Discount
                  </Typography>
                  <Typography className="coupon__text coupon__small--text">
                    Offering your users Discount is a good way to increase
                    orders
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center", background: "red" }}
              >
                <h2>{props.t("category.categoryDetails")}</h2>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("category.active")}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("category.title")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.title}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      title: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="isParent"
                  label={props.t("category.isParent")}
                  select
                  fullWidth
                  defaultValue={userForm.isParent}
                  value={userForm.isParent}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      isParent: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>

              {userForm.isParent === false && (
                <Grid
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="parentTitle"
                    label={props.t("category.parentTitle")}
                    autoComplete="something-unsupported"
                    value={userForm.parentTitle}
                    fullWidth
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        parentTitle: event.target.value,
                      })
                    }
                  />
                </Grid>
              )}
              {userForm.i18n &&
                Object.entries(userForm.i18n).map((e, i) => (
                  <Grid
                    key={i}
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-langCode`}
                        label={props.t("category.language")}
                        select
                        fullWidth
                        defaultValue={e[0]}
                        value={e[0]}
                        onChange={(event) => {
                          const uf = userForm;
                          delete uf.i18n[e[0]];

                          uf["i18n"][event.target.value] = {
                            title: "",
                          };

                          setUserForm({ ...uf });
                        }}
                      >
                        {languageList.map((language) => (
                          <MenuItem key={language._id} value={language.value}>
                            {language.display}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-title`}
                        label={props.t("category.languageTitle")}
                        required
                        fullWidth
                        value={e[1].title}
                        onChange={(event) => {
                          const uf = userForm;
                          uf["i18n"][e[0]] = {
                            title: event.target.value,
                          };

                          setUserForm({ ...uf });
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={addLanguage}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          removeLanguage(e[0]);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid
                    xl={8}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="logo"
                      label={props.t("shop.logo")}
                      fullWidth
                      variant="outlined"
                      value={userForm.logo}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Upload Image upto 3 Mb">
                              <IconButton aria-label="add">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <Grid item xs={7}>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="logoImage"
                        // multiple
                        disabled={
                          logoUploadStatus === true
                            ? true
                            : (logoUploadStatus === false &&
                                props.formDialog.divType === "new") ||
                              (logoUploadStatus === false &&
                                props.formDialog.divType === "edit")
                            ? false
                            : true
                        }
                        onChange={(event) => {
                          handleLogoUpload(event);
                        }}
                        type="file"
                      />
                      <label htmlFor="logoImage">
                        <Button
                          align="left"
                          color="primary"
                          component="span"
                          className={"mr-2"}
                          size="large"
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: 20,
                              marginRight: "20px",
                            }}
                          />
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      {logoUploadStatus === true ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        logoUploadStatus === false &&
                        userForm.logo !== "" && (
                          <img
                            src={userForm.logo}
                            style={{
                              width: "80px",
                              height: "55px",
                              border: "2px solid black",
                            }}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Paper>
        </Grid>

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button
              color="primary"
              onClick={() => {
                console.log("submitting");
                sendToServer();
              }}
            >
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
