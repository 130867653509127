import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../../assets/images/mentorlink logo.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  background: {
    backgroundColor: "#00275A",
    // backgroundColor: "#2c72ff",
  },
}));
export default function Header(props) {
  const classes = useStyles();
  return (
    <div style={{ display: "none" }} className={classes.root}>
      <AppBar position="fixed" className={classes.background}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Avatar
              alt="MentorLink"
              src={Logo}
              m={3}
              className={classes.large}
            />
          </Typography>
          <MenuItem onClick={() => props.history.push("/login")}>
            Login
          </MenuItem>
        </Toolbar>
      </AppBar>
    </div>
  );
}
