import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      position: "fixed  !important",
      zIndex: "1196 !important",
      left: "251px !important",
    },
  },
  appBar: {
    position: "fixed  !important",
    zIndex: "1196 !important",
    [theme.breakpoints.up("md")]: {
      left: "251px !important",
      paddingRight: "251px",
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FormDialog(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        className={!props.sideDrawerData ? classes.root : ""}
        fullScreen
        open={props.formDialog.status}
        TransitionComponent={Transition}
      >
        <AppBar className={!props.sideDrawerData ? classes.appBar : ""}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleFormDialog}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.formDialog.title}
            </Typography>
            {props.label !== undefined && props.label !== null ? (
              <Typography variant="h6">{props.label}</Typography>
            ) : (
              <></>
            )}
            {props.formDialog.divType === "new" ? (
              <>
                <Button
                  autoFocus
                  color="inherit"
                  style={{ marginRight: "10px" }}
                  onClick={props.resetForm}
                >
                  Reset
                </Button>
              </>
            ) : (
              <></>
            )}
            {props?.button === "logs" &&
            props?.formDialog?.divType === "edit" ? (
              <>
                <Button
                  autoFocus
                  color="inherit"
                  style={{ marginRight: "10px" }}
                  onClick={props?.showLogs}
                >
                  Logs
                </Button>
              </>
            ) : (
              <></>
            )}

            {props.formDialog.divType === "new" ? (
              <Button autoFocus color="inherit" onClick={() => props.submit()}>
                {props.t("buttons.save")}
              </Button>
            ) : props.formDialog.divType === "edit" ||
              props.formDialog.divType === "assign" ? (
              <Button autoFocus color="inherit" onClick={() => props.submit()}>
                {props.t("buttons.update")}
              </Button>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: "15px" }}>{props.formComponent}</div>
      </Dialog>
    </div>
  );
}
export default withTranslation("translations")(FormDialog);
