import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import ConfigAPIURL from "./config/ConfigAPIURL";
import APIRequest from "./utils/APIRequest";
import LocalStorage from "./config/LocalStorage";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";

class PrivateRouteValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveAcces: false,
      loaded: false,
    };
  }

  UNSAFE_componentWillReceiveProps = () => {
    this.checkAcces();
  };
  UNSAFE_componentWillMount = () => {
    this.checkAcces();
  };

  checkAcces = () => {
    const { history } = this.props;
    LocalStorage.adminButtonPermission = [];
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "")
      .then((response) => {
        if (
          response.code === 100 &&
          response.data !== undefined &&
          response.data.responseCode === 109
        ) {
          if (response.data.user !== undefined && response.data.user !== null) {
            LocalStorage.userDetails.userName = response.data.user.fname;
            LocalStorage.userDetails.userEmail = response.data.user.email;
            LocalStorage.userDetails.userMobile = response.data.user.mobileNo;
            LocalStorage.userDetails.userType = response.data.user.userType;
            LocalStorage.userDetails.userId = response?.data?.user?._id;
            LocalStorage.userDetails.organizationId =
              response?.data?.user?.associatedOrganization;
            LocalStorage.userDetails.profileImage =
              response.data.user.profileImage;
            LocalStorage.userDetails.notificationCount =
              response.data.notificationCount;
          }
          if (history.location.pathname.startsWith("/admin")) {
            if (history.location.pathname.split("/").length > 2) {
              let maintenanceOptions = [];
              response.data.user.permission.permission.map((data) => {
                if (data.enable) {
                  maintenanceOptions.push(data.label);
                }
              });

              maintenanceOptions.push("setting");
              LocalStorage.maintenanceOptions = maintenanceOptions;
              let redirectUrl = history.location.pathname;
              let accessFlag = false;
              Object.keys(LocalStorage.adminSideMenuWithRoleID).map(
                (objectKey) => {
                  if (
                    LocalStorage.adminSideMenuWithRoleID[objectKey] ===
                      redirectUrl &&
                    !accessFlag
                  ) {
                    if (objectKey !== "") {
                      if (LocalStorage.maintenanceOptions.includes(objectKey)) {
                        accessFlag = true;
                      }
                    } else if (
                      (LocalStorage.adminSideMenuWithRoleID[objectKey] =
                        redirectUrl)
                    ) {
                      accessFlag = true;
                    }
                  }
                }
              );
              if (!accessFlag) {
                let newPathSet = false;
                Object.keys(LocalStorage.adminSideMenuWithRoleID).map(
                  (objectKey) => {
                    if (
                      LocalStorage.adminSideMenuWithRoleID[objectKey] ===
                      redirectUrl
                    ) {
                      newPathSet = true;
                    } else if (newPathSet) {
                      redirectUrl =
                        LocalStorage.adminSideMenuWithRoleID[objectKey];
                      newPathSet = false;
                    }
                  }
                );
                this.setState(
                  {
                    haveAcces: false,
                    loaded: true,
                  },
                  () => {
                    setTimeout(function () {
                      history.push(redirectUrl);
                    }, 2000);
                  }
                );
              } else {
                response.data.user.permission.permission.map((data) => {
                  if (data.enable) {
                    Object.keys(LocalStorage.adminSideMenuWithRoleID).map(
                      (objectKey) => {
                        if (
                          LocalStorage.adminSideMenuWithRoleID[objectKey] ===
                            history.location.pathname &&
                          data.label === objectKey
                        ) {
                          let prevStateButtonDetails = [];
                          data.buttons.map((buttonData) => {
                            if (buttonData.enable) {
                              let tempButtonObject = {
                                button: buttonData.label,
                                disable: false,
                              };
                              prevStateButtonDetails.push(tempButtonObject);
                            }
                          });
                          LocalStorage.adminButtonPermission =
                            prevStateButtonDetails;
                        }
                      }
                    );
                  }
                });
                this.setState({
                  haveAcces: true,
                  loaded: true,
                });
              }
            } else {
              this.setState({
                haveAcces: true,
                loaded: true,
              });
            }
          } else if (history.location.pathname.startsWith("/partner")) {
            if (history.location.pathname.split("/").length > 2) {
              let maintenanceOptions = [];
              response.data.user.permission.permission.map((data) => {
                if (data.enable) {
                  maintenanceOptions.push(data.label);
                }
              });

              maintenanceOptions.push("setting");
              LocalStorage.maintenanceOptions = maintenanceOptions;
              let redirectUrl = history.location.pathname;
              let accessFlag = false;
              Object.keys(LocalStorage.partnerSideMenuWithRoleID).map(
                (objectKey) => {
                  if (
                    LocalStorage.partnerSideMenuWithRoleID[objectKey] ===
                      redirectUrl &&
                    !accessFlag
                  ) {
                    if (objectKey !== "") {
                      if (LocalStorage.maintenanceOptions.includes(objectKey)) {
                        accessFlag = true;
                      }
                    } else if (
                      (LocalStorage.partnerSideMenuWithRoleID[objectKey] =
                        redirectUrl)
                    ) {
                      accessFlag = true;
                    }
                  }
                }
              );
              if (!accessFlag) {
                let newPathSet = false;
                Object.keys(LocalStorage.partnerSideMenuWithRoleID).map(
                  (objectKey) => {
                    if (
                      LocalStorage.partnerSideMenuWithRoleID[objectKey] ===
                      redirectUrl
                    ) {
                      newPathSet = true;
                    } else if (newPathSet) {
                      redirectUrl =
                        LocalStorage.partnerSideMenuWithRoleID[objectKey];
                      newPathSet = false;
                    }
                  }
                );
                this.setState(
                  {
                    haveAcces: false,
                    loaded: true,
                  },
                  () => {
                    setTimeout(function () {
                      history.push(redirectUrl);
                    }, 2000);
                  }
                );
              } else {
                response.data.user.permission.permission.map((data) => {
                  if (data.enable) {
                    Object.keys(LocalStorage.partnerSideMenuWithRoleID).map(
                      (objectKey) => {
                        if (
                          LocalStorage.partnerSideMenuWithRoleID[objectKey] ===
                            history.location.pathname &&
                          data.label === objectKey
                        ) {
                          let prevStateButtonDetails = [];
                          data.buttons.map((buttonData) => {
                            if (buttonData.enable) {
                              let tempButtonObject = {
                                button: buttonData.label,
                                disable: false,
                              };
                              prevStateButtonDetails.push(tempButtonObject);
                            }
                          });
                          LocalStorage.adminButtonPermission =
                            prevStateButtonDetails;
                        }
                      }
                    );
                  }
                });
                this.setState({
                  haveAcces: true,
                  loaded: true,
                });
              }
            } else {
              this.setState({
                haveAcces: true,
                loaded: true,
              });
            }
          } else {
            history.push("/login");
          }
        } else if (
          response.code === 100 &&
          response.data !== undefined &&
          response.data.responseCode === 108
        ) {
          LocalStorage.userName = "";
          LocalStorage.userEmail = "";
          LocalStorage.userMobile = "";
          LocalStorage.userType = "";
          LocalStorage.userProfileImage = "";
          LocalStorage.notificationCount = 0;
          history.push("/login");
        } else {
          this.setState({
            haveAcces: false,
            loaded: true,
          });
        }
      })
      .catch(() => {
        history.push("/");
      });
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, haveAcces } = this.state;
    if (!loaded)
      return (
        <Backdrop
          open={true}
          style={{
            background:
              // "linear-gradient(-45deg, #4a7ca3 0%, #5d84a3 33%, #9dc8c2 100%)",
              "#fff",
          }}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      );
    return (
      <Route
        {...rest}
        render={(props) => {
          return haveAcces === true ? (
            <Component {...props} />
          ) : (
            <Backdrop
              open={true}
              style={{
                background:
                  // "linear-gradient(-45deg, #4a7ca3 0%, #5d84a3 33%, #9dc8c2 100%)",
                  "#fff",
              }}
            >
              <CircularProgress color="secondary" />
              <Box style={{ position: "absolute", marginTop: "27%" }}>
                {
                  "Sorry, You don't have permission. We are trying to redirect you another page."
                }
              </Box>
            </Backdrop>
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRouteValidation);
