/* eslint-disable default-case */
const SnackbarUtils = {
  sucess: async function (message, position, duration) {
    let severity = "success";
    let anchorOrigin = await this.snackbarPosition(position);
    return { message, anchorOrigin, severity, duration, open: true };
  },
  error: async function (message, position, duration) {
    let severity = "error";
    let anchorOrigin = await this.snackbarPosition(position);
    return { message, anchorOrigin, severity, duration, open: true };
  },
  warn: async function (message, position, duration) {
    let severity = "warn";
    let anchorOrigin = await this.snackbarPosition(position);
    return { message, anchorOrigin, severity, duration, open: true };
  },
  info: async function (message, position, duration) {
    let severity = "info";
    let anchorOrigin = await this.snackbarPosition(position);
    return { message, anchorOrigin, severity, duration, open: true };
  },
  custom: async function (message, position, duration) {},

  snackbarPosition: async function (position) {
    let pos = {
      vertical: "bottom",
      horizontal: "center",
    };
    switch (position) {
      case "topCenter":
        pos = {
          vertical: "top",
          horizontal: "center",
        };
        break;
      case "topLeft":
        pos = {
          vertical: "top",
          horizontal: "left",
        };
        break;
      case "topRight":
        pos = {
          vertical: "top",
          horizontal: "right",
        };
        break;
      case "bottomCenter":
        pos = {
          vertical: "bottom",
          horizontal: "center",
        };
        break;
      case "bottomLeft":
        pos = {
          vertical: "bottom",
          horizontal: "left",
        };
        break;
      case "bottomRight":
        pos = {
          vertical: "bottom",
          horizontal: "right",
        };
        break;
      case "default":
        pos = {
          vertical: "bottom",
          horizontal: "center",
        };
        break;
    }
    return pos;
  },
};
export default SnackbarUtils;
