import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import SecurityIcon from "@material-ui/icons/Security";
import FilterListIcon from "@material-ui/icons/FilterList";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CategoryIcon from "@material-ui/icons/Category";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import DiscountIcon from "@mui/icons-material/Discount";
import PercentIcon from "@mui/icons-material/Percent";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import discount from "./discount.png";
import "./discount.css";
const Discount = () => {
  return <img src={discount} alt="Discount Icon" width={24} height={24} />;
};
class SideBarMenu {
  static menu = [
    {
      display: "sideNavMenu.dashboard",
      link: "/admin/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      icon: <DashboardIcon />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      icon: <PeopleIcon />,
      eventKey: "users",
      subMenu: [],
    },
    {
      display: "sideNavMenu.booking",
      link: "/admin/booking",
      linkPage: false,
      onclick: null,
      urlEndWith: "booking",
      className: "",
      permissionLevel: "Booking",
      icon: <EventAvailableIcon />,
      eventKey: "booking",
      subMenu: [],
    },
    // {
    //   display: "sideNavMenu.advertisement",
    //   link: "/admin/advertisements",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "advertisements",
    //   className: "",
    //   permissionLevel: "Advertisement",
    //   icon: <AdUnitsIcon />,
    //   eventKey: "users",
    //   subMenu: [],
    // },
    {
      display: "sideNavMenu.organization",
      link: "/admin/organization",
      linkPage: false,
      onclick: null,
      urlEndWith: "organization",
      className: "",
      permissionLevel: "Organization",
      icon: <AdUnitsIcon />,
      eventKey: "users",
      subMenu: [],
    },
    {
      display: "sideNavMenu.category",
      link: "/admin/category",
      linkPage: false,
      onclick: null,
      urlEndWith: "category",
      className: "",
      permissionLevel: "Category",
      icon: <CategoryIcon />,
      eventKey: "category",
      subMenu: [],
    },
    {
      display: "sideNavMenu.coupons",
      link: "/admin/coupons",
      linkPage: false,
      onclick: null,
      urlEndWith: "coupons",
      className: "",
      permissionLevel: "Coupons",
      icon: <DiscountIcon />,
      eventKey: "Coupons",
      subMenu: [],
    },
    {
      display: "Discount",
      link: "/admin/discount",
      linkPage: false,
      onclick: null,
      urlEndWith: "discount",
      className: "",
      permissionLevel: "Discount",
      icon: <LocalOfferIcon />,
      eventKey: "Discount",
      subMenu: [],
    },

    {
      display: "sideNavMenu.security",
      link: "/admin/security",
      linkPage: false,
      onclick: null,
      urlEndWith: "security",
      className: "",
      permissionLevel: "",
      icon: <SecurityIcon />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "Role",
          icon: <FilterListIcon />,
          eventKey: "role",
        },
      ],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      icon: <SettingsIcon />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
  static partnerMenu = [
    {
      display: "sideNavMenu.dashboard",
      link: "/partner/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      icon: <DashboardIcon />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/partner/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      icon: <PeopleIcon />,
      eventKey: "users",
      subMenu: [],
    },
    {
      display: "sideNavMenu.booking",
      link: "/partner/booking",
      linkPage: false,
      onclick: null,
      urlEndWith: "booking",
      className: "",
      permissionLevel: "Booking",
      icon: <EventAvailableIcon />,
      eventKey: "booking",
      subMenu: [],
    },
    // {
    //   display: "sideNavMenu.advertisement",
    //   link: "/partner/advertisements",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "advertisements",
    //   className: "",
    //   permissionLevel: "Advertisement",
    //   icon: <AdUnitsIcon />,
    //   eventKey: "users",
    //   subMenu: [],
    // },
    {
      display: "sideNavMenu.organization",
      link: "/partner/organization",
      linkPage: false,
      onclick: null,
      urlEndWith: "organization",
      className: "",
      permissionLevel: "Organization",
      icon: <AdUnitsIcon />,
      eventKey: "users",
      subMenu: [],
    },
    {
      display: "sideNavMenu.category",
      link: "/partner/category",
      linkPage: false,
      onclick: null,
      urlEndWith: "category",
      className: "",
      permissionLevel: "Category",
      icon: <CategoryIcon />,
      eventKey: "category",
      subMenu: [],
    },
    {
      display: "sideNavMenu.coupons",
      link: "/partner/coupons",
      linkPage: false,
      onclick: null,
      urlEndWith: "coupons",
      className: "",
      permissionLevel: "Coupons",
      icon: <DiscountIcon />,
      eventKey: "Coupons",
      subMenu: [],
    },

    // {
    //   display: "sideNavMenu.security",
    //   link: "/partner/security",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "security",
    //   className: "",
    //   permissionLevel: "",
    //   icon: <SecurityIcon />,
    //   eventKey: "security",
    //   subMenu: [
    //     {
    //       display: "sideNavMenu.role_function",
    //       link: "/partner/security/role",
    //       linkPage: false,
    //       onclick: null,
    //       urlEndWith: "role",
    //       className: "",
    //       permissionLevel: "Role",
    //       icon: <FilterListIcon />,
    //       eventKey: "role",
    //     },
    //   ],
    // },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.setting",
      link: "/partner/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      icon: <SettingsIcon />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
}
export default SideBarMenu;
