class ConfigAPIURL {
  //Base URL For Live & Staging
  static baseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;
  // static baseUrl = "https://mentorlink.in";
  // static baseUrl = "https://dev.mentorlink.in";

  //List of Pay Defination APIs and request method type
  static sessionValidation = this.baseUrl + "/admin/islogin"; //get
  static accountLogin = this.baseUrl + "/admin/accountLogin";
  static verifyOtp = this.baseUrl + "/admin/verify/otp";
  static resendOtp = this.baseUrl + "/admin/resend/otp";
  static adminLogout = this.baseUrl + "/admin/logout";

  static uploadFile = this.baseUrl + "/upload/file";

  static getRoleTitles = this.baseUrl + "/api" + "/admin/roles/titles";

  static adminGeneratePassword = this.baseUrl + "/admin/password/generate";

  static forgotPassword = this.baseUrl + "/admin/forgot/password";
  static resetPassword = this.baseUrl + "/admin/reset/password";

  //Admin APIS
  static roleTableURL = this.baseUrl + "/admin/roles/all"; //post
  static roleTitles = this.baseUrl + "/admin/roles/titles"; //post
  static roleDetails = this.baseUrl + "/admin/role/details"; //post
  static roleCreate = this.baseUrl + "/admin/role/create"; //post
  static roleUpdate = this.baseUrl + "/admin/role/permission/update"; //post
  static roleUnassigned = this.baseUrl + "/admin/role/permission/unassigned"; //post
  static roleGrant = this.baseUrl + "/admin/role/permission/grant"; //post

  //  User Table API
  static userTableURL = this.baseUrl + "/admin/user/all"; //post
  static userCreate = this.baseUrl + "/admin/user/add"; //post
  static userUpdate = this.baseUrl + "/admin/user/update"; //post
  static userDelete = this.baseUrl + "/admin/user/delete"; //post
  static userDetails = this.baseUrl + "/admin/user/details"; //post
  static getUserDropdown = this.baseUrl + "/admin/user/list"; // get

  static resetPasswordAttempt =
    this.baseUrl + "/admin/user/resetpasswordattempt";

  // Advertisement API

  static getAllAdvertisement = this.baseUrl + "/admin/advertisement/all"; //post
  static inventoryProductList = this.baseUrl + "/admin/Advertisement/search"; //get
  static updateAdvertisement = this.baseUrl + "/admin/advertisement/update";
  static getAdvertisementDetails =
    this.baseUrl + "/admin/advertisement/details";
  static addAdvertisement = this.baseUrl + "/admin/advertisement/create";
  static getCategoryForAd = this.baseUrl + "/admin/advertisement/category";
  static advertisementDelete = this.baseUrl + "/admin/advertisement/delete";
  static advertisementCreate = this.baseUrl + "/admin/advertisement/create";

  //Category APIs
  static getAllCategory = this.baseUrl + "/admin/category/all";
  static getCategoryDetails = this.baseUrl + "/admin/category/details";
  static addCategory = this.baseUrl + "/admin/category/add";
  static updateCategory = this.baseUrl + "/admin/category/update";
  static deleteCategory = this.baseUrl + "/admin/category/delete";
  static category = this.baseUrl + "/admin/category/search";

  //Dropdown APIs
  static getOptions = this.baseUrl + "/admin/options";

  //Booking APIs
  static getAllBooking = this.baseUrl + "/admin/booking/all";
  static bookingDetails = this.baseUrl + "/admin/booking/details";
  static bookingDelete = this.baseUrl + "/admin/booking/delete"; //post
  static updateBookingStatus = this.baseUrl + "/admin/booking/update-status";

  // Dashboard APIs
  static getCardValues = this.baseUrl + "/admin/dashboard/cardvalues";
  static getOverView = this.baseUrl + "/admin/dashboard/overview";
  static getNewMenteesCount = this.baseUrl + "/admin/dashboard/newmentees";
  static getNewMentorsCount = this.baseUrl + "/admin/dashboard/newmentors";
  static getOrganizationsCount = this.baseUrl + "/admin/dashboard/organization";
  static getNewBookingsCount = this.baseUrl + "/admin/dashboard/newbooking";
  static getNewPaymentCount = this.baseUrl + "/admin/dashboard/newpayment";
  //coupons
  static getIncubators = this.baseUrl + "/admin/coupon/admins"; //GET
  static createCoupons = this.baseUrl + "/admin/coupon/create"; //post
  static getallCoupons = this.baseUrl + "/admin/coupon/all"; //post
  static couponDetails = this.baseUrl + "/admin/coupon/details"; //post
  static updateCoupons = this.baseUrl + "/admin/coupon/update"; //post
  static DeleteCoupons = this.baseUrl + "/admin/coupon/delete"; //post
  static getMentorInvoice = this.baseUrl + "/user/order/mentor/details"; //get

  // discount APIs
  static createDiscount = this.baseUrl + "/admin/discount/create"; //post
  static GetAllDiscounts = this.baseUrl + "/admin/discount/all"; //POST
  static updateDiscount = this.baseUrl + "/admin/discount/update"; //POST
  static DeleteDiscount = this.baseUrl + "/admin/discount/delete"; //POST
  static DiscountDetails = this.baseUrl + "/admin/discount/details"; //POST
  static GetAllMentors = this.baseUrl + "/admin/discount/mentors"; //get
  static GetAdmins = this.baseUrl + "/admin/discount/admins"; //GET

  //Organization
  static createOrganization = this.baseUrl + "/admin/organization/create"; //POST
  static getAllOrganization = this.baseUrl + "/admin/organization/all"; //POST
  static getOrganizationDetails = this.baseUrl + "/admin/organization/details"; //POST
  static deleteOrganization = this.baseUrl + "/admin/organization/delete"; //POST
  static updateOrganization = this.baseUrl + "/admin/organization/update"; //POST
  static addCreditsToOrganization =
    this.baseUrl + "/admin/organization/addCredits"; //POST

  //Coupons
  static getAllMentorsEmails = this.baseUrl + "/admin/coupon/mentors"; //POST
  static getAllMenteesEmails = this.baseUrl + "/admin/coupon/mentees"; //POST

  //Logs
  static getAllLogs = this.baseUrl + "/admin/organizations/logs"; //POST
}

export default ConfigAPIURL;
