import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Autocomplete,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));

const bookingFormDetails = {
  mentor: {
    fname: "",
    lname: "",
    mobileNo: "",
    email: "",
    profileImage: "",
    designation: "",
  },
  mentee: {
    fname: "",
    lname: "",
    mobileNo: "",
    email: "",
    profileImage: "",
    designation: "",
  },
  bookingId: "",
  status: "",
  appointmentFee: 0,
  convenienceFee: 0,
  tax: 0,
  discount: 0,
  totalPayment: 0,
  sessionDate: null,
  sessionTime: "",
};

const AEVForm = (props) => {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [bookingForm, setBookingForm] = useState({ ...bookingFormDetails });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("Event: ", event.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {}, []);
  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setBookingForm({ ...bookingFormDetails, userType: "user" });
    }
  }, [props.page]);

  useEffect(() => {
    // setBookingForm(bookingFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setBookingForm({ ...bookingFormDetails, userType: "user" });
    } else {
      setBookingForm(bookingFormDetails);
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.bookingDetails,
        JSON.stringify({ bookingId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let bookingResult = response.data.details;

            let dateValue =
              new Date(bookingResult["sessionDate"] * 1000)
                .toLocaleDateString("default", {
                  month: "numeric",
                })
                .split(" ")[0]
                .toString()
                .padStart(2, "0") +
              " " +
              new Date(bookingResult["sessionDate"] * 1000)
                .toLocaleDateString("default", {
                  day: "numeric",
                  month: "short",
                })
                .split(" ")[1]
                .toString()
                .padStart(2, "0") +
              " " +
              new Date(bookingResult["sessionDate"] * 1000)
                .toLocaleDateString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
                .split(" ")[2];

            bookingResult["sessionDate"] = dateValue;

            setBookingForm({
              ...bookingForm,
              mentor: {
                ...bookingForm.mentor,
                fname: bookingResult.mentorId.fname,
                lname: bookingResult.mentorId.lname,
                mobileNo: bookingResult.mentorId.mobileNo,
                email: bookingResult.mentorId.email,
                profileImage: bookingResult.mentorId.profileImage,
                designation: bookingResult.mentorId.designation,
              },
              mentee: {
                ...bookingForm.mentee,
                fname: bookingResult.userId.fname,
                lname: bookingResult.userId.lname,
                mobileNo: bookingResult.userId.mobileNo,
                email: bookingResult.userId.email,
                profileImage: bookingResult.userId.profileImage,
                designation: bookingResult.userId.designation,
              },
              bookingId: bookingResult.orderId,
              status: bookingResult.status,
              appointmentFee: bookingResult.appointmentFee,
              convenienceFee: bookingResult.convenienceFee,
              tax: bookingResult.tax,
              discount: bookingResult.discount,
              totalPayment: bookingResult.totalPayment,
              sessionDate: bookingResult.sessionDate,
              sessionTime: bookingResult.sessionTime,
            });

            setSelectedRowId(bookingResult._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url;
    let method;

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateBookingStatus;
      bookingForm["recordId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation = [];

    FormValidation.validation(fieldValidation, bookingForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(method, url, JSON.stringify(bookingForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.transComplete"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();

                  setBookingForm({ ...bookingFormDetails });
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  console.log("Booking Form: ", bookingForm);

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                item
                className={classes.formGrid}
              >
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: 10,
                    margin: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      fontSize: 20,
                      backgroundColor: "#D3E1FE",
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                      padding: 15,
                      margin: 0,
                    }}
                  >
                    Mentee Details
                  </Typography>
                  <Grid
                    container
                    style={{
                      padding: 15,
                    }}
                  >
                    <Grid>
                      <img
                        style={{
                          width: 126,
                          height: 126,
                          borderRadius: "50%",
                          objectFit: "cover",
                          border: "1px solid #D3E1FE",
                        }}
                        src={
                          bookingForm.mentee.profileImage &&
                          bookingForm.mentee.profileImage !== ""
                            ? bookingForm.mentee.profileImage
                            : "https://mrmqt.com/wp-content/themes/wp-theme-mrmqt.com/assets/images/test-img.png"
                        }
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginLeft: 10,
                        marginTop: 10,
                      }}
                    >
                      <Typography
                        variant="root"
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <Box
                          style={{
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          {bookingForm.mentee.fname} {bookingForm.mentee.lname}
                        </Box>
                        <Box
                          style={{
                            textTransform: "capitalize",
                            fontWeight: 500,
                          }}
                        >
                          {bookingForm.mentee.designation}
                        </Box>
                        <Box style={{ fontSize: 14 }}>
                          {bookingForm.mentee.email}
                        </Box>
                        <Box style={{ fontSize: 14, marginTop: 2 }}>
                          {bookingForm.mentee.mobileNo}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                item
                className={classes.formGrid}
              >
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: 10,
                    margin: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      fontSize: 20,
                      backgroundColor: "#D3E1FE",
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                      padding: 15,
                      margin: 0,
                    }}
                  >
                    Mentor Details
                  </Typography>
                  <Grid
                    container
                    style={{
                      padding: 15,
                    }}
                  >
                    <Grid>
                      <img
                        style={{
                          width: 126,
                          height: 126,
                          borderRadius: "50%",
                          objectFit: "cover",
                          border: "1px solid #D3E1FE",
                          // backgroundColor: "#D3E1FE",
                        }}
                        src={
                          bookingForm.mentor.profileImage &&
                          bookingForm.mentor.profileImage !== ""
                            ? bookingForm.mentor.profileImage
                            : "https://mrmqt.com/wp-content/themes/wp-theme-mrmqt.com/assets/images/test-img.png"
                        }
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginLeft: 10,
                        marginTop: 10,
                      }}
                    >
                      <Typography
                        variant="root"
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <Box
                          style={{
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          {bookingForm.mentor.fname} {bookingForm.mentor.lname}
                        </Box>
                        <Box
                          style={{
                            textTransform: "capitalize",
                            fontWeight: 500,
                          }}
                        >
                          {bookingForm.mentor.designation}
                        </Box>
                        <Box style={{ fontSize: 14 }}>
                          {bookingForm.mentor.email}
                        </Box>
                        <Box style={{ fontSize: 14, marginTop: 2 }}>
                          {bookingForm.mentor.mobileNo}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} container>
                <Grid
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Box
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                      margin: 5,
                      height: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: 20,
                        backgroundColor: "#D3E1FE",
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        borderRadius: 10,
                        padding: 15,
                        margin: 0,
                      }}
                    >
                      Booking Details
                    </Typography>
                    <Grid
                      style={{
                        padding: 15,
                      }}
                    >
                      <Typography variant="root">
                        <Box style={{ marginTop: 15 }}>
                          <span style={{ fontWeight: 700 }}>Booking ID</span> :{" "}
                          {bookingForm.bookingId}
                        </Box>
                        <Box style={{ marginTop: 15 }}>
                          <span style={{ fontWeight: 700 }}>Booking Date</span>{" "}
                          : {bookingForm.sessionDate}
                        </Box>
                        <Box style={{ marginTop: 15 }}>
                          <span style={{ fontWeight: 700 }}>Booking Time</span>{" "}
                          :{" "}
                          {bookingForm?.sessionTime &&
                            bookingForm?.sessionTime.length > 0 &&
                            bookingForm?.sessionTime.map((session, index) => (
                              <span key={index}>
                                {session.from} - {session.till}{" "}
                                {index + 1 !==
                                  bookingForm?.sessionTime.length && " , "}
                              </span>
                            ))}
                        </Box>
                        <Box
                          style={{ marginTop: 15, textTransform: "capitalize" }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            Booking Status
                          </span>{" "}
                          : {bookingForm.status}
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              open ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            // variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          ></Button>
                          <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setBookingForm({
                                  ...bookingForm,
                                  status: "enquiry",
                                });
                              }}
                              disableRipple
                            >
                              Enquiry
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setBookingForm({
                                  ...bookingForm,
                                  status: "cancelled",
                                });
                              }}
                              disableRipple
                            >
                              Cancelled
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setBookingForm({
                                  ...bookingForm,
                                  status: "confirmed",
                                });
                              }}
                              disableRipple
                            >
                              Confirmed
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setBookingForm({
                                  ...bookingForm,
                                  status: "completed",
                                });
                              }}
                              disableRipple
                            >
                              Completed
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setBookingForm({
                                  ...bookingForm,
                                  status: "upcoming",
                                });
                              }}
                              disableRipple
                            >
                              Upcoming
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Box
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                      margin: 5,
                      height: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: 20,
                        backgroundColor: "#D3E1FE",
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        borderRadius: 10,
                        padding: 15,
                        margin: 0,
                      }}
                    >
                      Booking Details
                    </Typography>
                    <Grid
                      style={{
                        padding: 15,
                      }}
                    >
                      <Typography
                        variant="root"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Box style={{ marginTop: 15, fontWeight: 600 }}>
                            Appointment Fee
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 600 }}>
                            Convenience
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 600 }}>
                            Discount
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 600 }}>
                            Tax
                          </Box>
                        </Box>
                        <Box>
                          <Box style={{ marginTop: 15, fontWeight: 700 }}>
                            + {"₹"}
                            {bookingForm.appointmentFee}
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 700 }}>
                            + {"₹"}
                            {bookingForm.convenienceFee}
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 700 }}>
                            - {"₹"}
                            {parseInt(bookingForm.discount) > 0
                              ? bookingForm.discount
                              : 0}
                          </Box>
                          <Box style={{ marginTop: 15, fontWeight: 700 }}>
                            + {"₹"}
                            {parseInt(bookingForm.tax) > 0
                              ? bookingForm.tax
                              : 0}
                          </Box>
                        </Box>
                      </Typography>

                      <Typography
                        variant="root"
                        style={{
                          marginTop: 30,
                          display: "flex",
                          justifyContent: "space-between",
                          borderTop: "1px solid rgba(0, 0, 0, 0.15)",
                        }}
                      >
                        <Box>
                          <Box
                            style={{
                              marginTop: 10,
                              fontWeight: 500,
                              color: "#1C3464",
                              fontSize: 20,
                              fontWeight: 600,
                            }}
                          >
                            Total amount
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            style={{
                              marginTop: 10,
                              fontWeight: 500,
                              fontSize: 20,
                              fontWeight: 700,
                            }}
                          >
                            {"₹"}
                            {bookingForm.totalPayment}
                          </Box>
                        </Box>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
