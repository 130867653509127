import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CardList from "./CardsList";
import { MdEdit, MdDeleteForever, MdDeleteSweep, MdSend } from "react-icons/md";
import DateConversion from "../../../../utils/DateConversion";
import BarRechartSingleValue from "../../../../templates/charts/BarRechartSingleValue";
// import TableWithoutSelection from "../../../../templates/tables/TableWithoutSelection";
// import TableHeader from "./TopCustomerHeader";
// import TableOrderHeader from "./TopOrderHeader";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// import ReactTable from "../../../../templates/tables/ReactTableCustom";

import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { withTranslation } from "react-i18next";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Box } from "@mui/system";
import LocalStorage from "../../../../config/LocalStorage";
import { allImages } from "../../../../images";
import { MenuItem } from "@material-ui/core";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";

const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
  { name: "Group E", value: 278 },
  { name: "Group F", value: 189 },
];

const defaultQuery = {
  startDate: null,
  endDate: null,
  duration: "",
  itemLimit10: true,
  customerLimit10: true,
};

const defaultFilteredQuery = {
  monthClicked: "",
  dayClicked: "",
  yearClicked: "",
  queryStart: null,
  queryEnd: null,
  queryDuration: "",
  graphDropdown: "mentees",
};
const defaultCardValues = {
  mentees: null,
  mentors: null,
  organization: null,
  bookings: null,
  payments: null,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  // border: "1px solid #ccc",
  borderRadius: "4px",
  backgroundColor: "#F6F7F9",
  padding: "5px",
  height: "50px",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  flex: 1,
  "&.Mui-selected": {
    backgroundColor: "#002855", // Dark blue background for selected button
    color: "#fff", // White text for selected button
    width: "100px",
    "&:hover": {
      backgroundColor: "#001f3f", // Darker blue on hover for selected button
    },
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "#F6F7F9", // Light background for unselected button
    color: "#000", // Black text for unselected button
    width: "100px",
    "&:hover": {
      backgroundColor: "#e0e0e0", // Slightly darker on hover for unselected button
    },
  },
}));

const Dashboard = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     monthClicked: "",
  //     dayClicked: "",
  //     yearClicked: "",
  //     query: {
  //       startDate: null,
  //       endDate: null,
  //       duration: "",
  //       itemLimit10: true,
  //       customerLimit10: true,
  //     },
  //     statusData: [],
  //     revenueData: [],
  //     statusDataPattern: [
  //       "verified",
  //       "shipped",
  //       "delivered",
  //       "cancelled",
  //       "refunded",
  //       "placed",
  //       "enquiry",
  //     ],
  //     paymentData: [],
  //     typeData: [],
  //     queryStart: null,
  //     queryEnd: null,
  //     mentees: null,
  //     payment: null,
  //     bookings: null,
  //     mentors: null,
  //     overView: {
  //       mentees: null,
  //       payment: null,
  //       bookings: null,
  //       mentors: null,
  //     },
  //     queryDuration: "",
  //     menteesData: [],
  //     orderItemWise: [],
  //     topCustomer: [],
  //     topItem: [],
  //     statusDataIsEmpty: false,
  //     menteesDataIsEmpty: false,
  //     revenueDataIsEmpty: false,
  //   };
  // }

  const [query, setQuery] = useState({ ...defaultQuery });
  const [cardValues, setCardValues] = useState({ ...defaultCardValues });
  const [overView, setOverView] = useState({ ...defaultCardValues });
  const [filteredQuery, setFilteredQuery] = useState({
    ...defaultFilteredQuery,
  });
  const [menteesData, setMenteesData] = useState({
    data: [{}],
    isEmpty: false,
  });
  const [mentorsData, setMentorsData] = useState({
    data: [{}],
    isEmpty: false,
  });
  const [bookingsData, setBookingsData] = useState({
    data: [{}],
    isEmpty: false,
  });
  const [paymentData, setPaymentData] = useState({
    data: [{}],
    isEmpty: false,
  });
  const [organizationData, setOrganizationData] = useState({
    data: [{}],
    isEmpty: false,
  });

  // UNSAFE_componentWillMount = () => {
  //   // // this.getOrderType();
  //   // this.getOrderStatus();
  //   // this.getOrderPaymentMode();
  //   // this.getCardValues();
  //   // this.getNewMenteesCount();
  //   // // this.urlFetch("status");
  //   this.getTopItem();
  // };

  useEffect(() => {
    getCardValues();
    getOverView();
    getNewMenteesCount();
    getNewMentorsCount();
    getNewBookingsCount();
    getNewPaymentCount();
    getOrganizationsCount();
  }, []);

  const _query = () => {
    let queryValue = {};
    if (filteredQuery.queryEnd != null && filteredQuery.queryStart != null) {
      queryValue = {
        startDate: DateConversion.LocalDateToUnix(
          filteredQuery.queryStart,
          "start"
        ),

        endDate: DateConversion.LocalDateToUnix(filteredQuery.queryEnd, "end"),

        duration: filteredQuery.queryDuration,
      };
    } else {
      queryValue = {
        duration: filteredQuery.queryDuration,
      };
    }
    console.log("Hi", queryValue);
    // setFilteredQuery(queryValue);

    getNewMenteesCount(queryValue);
    getNewMentorsCount(queryValue);
    getOrganizationsCount(queryValue);
    getNewBookingsCount(queryValue);
    getNewPaymentCount(queryValue);
  };

  const _queryFormReset = () => {
    setFilteredQuery(defaultFilteredQuery);
  };

  const getCardValues = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getCardValues,
      JSON.stringify({
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response?.data?.responseCode !== undefined &&
          response?.data?.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setCardValues({
              mentees: response?.data?.mentees,
              mentors: response?.data?.mentors,
              organization: response?.data?.organizations,
              credit: response?.data?.credit,
              bookings: response?.data?.bookings,
              payment: response?.data?.payment,
            });
          }
        }
      }
    });
  };

  const getOverView = () => {
    let serveDaysBefore = Math.floor(
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000
    );
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOverView,
      JSON.stringify({
        startAt: serveDaysBefore,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setOverView({
              mentees: response.data.mentees,
              mentors: response.data.mentors,
              organization: response?.data?.organizations,
              // organization: response?.data?.organizations,
              bookings: response.data.bookings,
              payment: response.data.payment,
            });
          }
        }
      }
    });
  };

  const getNewMenteesCount = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getNewMenteesCount,
      // JSON.stringify(query)
      JSON.stringify({
        ...query,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedActivities;
            if (response.data.result.length === 0) {
              setMenteesData({
                isEmpty: true,
                data: [{}],
              });
            } else {
              sortedActivities = response.data.result
                .slice()
                .sort((a, b) => new Date(a._id) - new Date(b._id));
              setMenteesData({
                isEmpty: false,
                data: sortedActivities,
              });
            }
          }
        }
      }
    });
  };

  const getNewMentorsCount = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getNewMentorsCount,
      // JSON.stringify(query)
      JSON.stringify({
        ...query,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedActivities;
            if (response.data.result.length === 0) {
              setMentorsData({
                isEmpty: true,
                data: [{}],
              });
            } else {
              sortedActivities = response.data.result
                .slice()
                .sort((a, b) => new Date(a._id) - new Date(b._id));
              setMentorsData({
                isEmpty: false,
                data: sortedActivities,
              });
            }
          }
        }
      }
    });
  };
  const getOrganizationsCount = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOrganizationsCount,
      JSON.stringify({
        ...query,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedActivities;
            if (response.data.result.length === 0) {
              setOrganizationData({
                isEmpty: true,
                data: [{}],
              });
            } else {
              sortedActivities = response.data.result
                .slice()
                .sort((a, b) => new Date(a._id) - new Date(b._id));
              setOrganizationData({
                isEmpty: false,
                data: sortedActivities,
              });
            }
          }
        }
      }
    });
  };

  const getNewBookingsCount = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getNewBookingsCount,
      // JSON.stringify(query)
      JSON.stringify({
        ...query,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedActivities;
            if (response.data.result.length === 0) {
              setBookingsData({
                isEmpty: true,
                data: [{}],
              });
            } else {
              sortedActivities = response.data.result
                .slice()
                .sort((a, b) => new Date(a._id) - new Date(b._id));
              setBookingsData({
                isEmpty: false,
                data: sortedActivities,
              });
            }
          }
        }
      }
    });
  };
  const getNewPaymentCount = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getNewPaymentCount,
      // JSON.stringify(query)
      JSON.stringify({
        ...query,
        associatedOrganization: LocalStorage?.userDetails?.organizationId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedActivities;
            if (response.data.result.length === 0) {
              setPaymentData({
                isEmpty: true,
                data: [{}],
              });
            } else {
              sortedActivities = response.data.result
                .slice()
                .sort((a, b) => new Date(a._id) - new Date(b._id));
              setPaymentData({
                isEmpty: false,
                data: sortedActivities,
              });
            }
          }
        }
      }
    });
  };

  const handleClick = (data) => {
    let url = `/admin/order?status=${data._id}`;
    props.history.push(url);
  };

  // urlFetch = (variable) => {
  //   var query = window.location;
  //   console.log(query);
  //   var vars = query.split("&");
  //   console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
  //     if (pair[0] == variable) {
  //       console.log("pair", pair[1], typeof pair[1]);
  //       return pair[1];
  //     }
  //   }
  //   return false;
  // };

  // handleChange = (input) => (event) => {
  //   this.setState({ [input]: event.target.value });
  // };

  const _handleEndChange = (date) => {
    setFilteredQuery({
      ...filteredQuery,
      queryEnd: date,
    });
  };

  const _handleStartChange = (date) => {
    setFilteredQuery({
      ...filteredQuery,
      queryStart: date,
    });
  };

  const byMonth = () => {
    setFilteredQuery({
      ...filteredQuery,
      queryDuration: "month",
      monthClicked: "primary",
      yearClicked: "",
      dayClicked: "",
    });
  };

  const byYear = () => {
    setFilteredQuery({
      ...filteredQuery,
      queryDuration: "year",
      monthClicked: "",
      dayClicked: "",
      yearClicked: "primary",
    });
  };

  const byDay = () => {
    setFilteredQuery({
      ...filteredQuery,
      queryDuration: "day",
      dayClicked: "primary",
      monthClicked: "",
      yearClicked: "",
    });
  };

  const Cards = [
    {
      title: "Mentees",
      value: cardValues.mentees,
      color: "#7AAEA9",
      icon: allImages.iconMentee,
      background: "linear-gradient(45deg, #CBDDDA, #5E9D96)",
      iconBackground: "#E5E4FF",
    },

    {
      title: "Mentors",
      value: cardValues.mentors,
      color: "#DDA2B3",
      icon: allImages.iconMentor,
      background: "linear-gradient(45deg, #FED4C5, #B27687",
      iconBackground: "#FFF3D6",
    },
    // {
    //   title: "Organization",
    //   value: cardValues.organization,
    //   color: "#DDA2B3",
    //   icon: allImages.iconOrganisation,
    //   background: "linear-gradient(45deg, #FED4C5, #B27687",
    //   iconBackground: "#FEDCE4",
    // },
    ...(LocalStorage?.userDetails?.userType !== "partner"
      ? [
          {
            title: "Organization",
            value: cardValues.organization,
            color: "#DDA2B3",
            icon: allImages.iconOrganisation,
            background: "linear-gradient(45deg, #FED4C5, #B27687)",
            iconBackground: "#FEDCE4",
          },
        ]
      : []),
    ...(LocalStorage?.userDetails?.userType === "partner"
      ? [
          {
            title: "Credits",
            value: cardValues.credit,
            color: "#DDA2B3",
            icon: allImages.iconRevenue,
            background: "linear-gradient(45deg, #FED4C5, #B27687)",
            iconBackground: "#FEDCE4",
          },
        ]
      : []),

    {
      title: "Bookings",
      value: cardValues.bookings,
      color: "#8FB9BF",
      icon: allImages.bookingsIcon,
      background: "linear-gradient(45deg, #EDE4DE, #84BBC1)",
      iconBackground: "#D9F7E8",
    },
    ...(LocalStorage?.userDetails?.userType !== "partner"
      ? [
          {
            title: "Revenue",
            value: cardValues.payment,
            color: "#DDA2B3",
            icon: allImages.iconRevenue,
            background: "linear-gradient(45deg, #FED4C5, #B27687)",
            iconBackground: "#FFDED1",
          },
        ]
      : []),
    // {
    //   title: "Revenue",
    //   value: cardValues.payment,
    //   color: "#746F92",
    //   icon: allImages.iconRevenue,
    //   background: "linear-gradient(45deg, #F3E5EA, #938EB0)",
    //   iconBackground: "#FFDED1",
    // },
  ];

  const OverViewCard = (props) => (
    // iconMentee
    <Grid
      style={{
        width: 222,
        // border: "1px solid rgba(245, 199, 165, 0.49)",
        borderRadius: 11,
        // height: 56,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: 20,
        marginTop: 20,
        boxShadow: "6px 6px 54px 0px #0000000D",
        backgroundColor: props.background,
        padding: "12px",
      }}
      container
    >
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        <Grid
          style={{
            backgroundColor: props.iconBackground,
            width: 49,
            height: 46,
            borderRadius: 16,
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={props.icon}
            style={{ width: "22px", height: "20px", contain: "content" }}
          />
          {/* {props.icon} */}
        </Grid>

        <Box style={{ marginLeft: 7, fontWeight: 700, fontSize: 14 }}>
          {props.title}
        </Box>
      </Grid>
      <Grid style={{ marginRight: 7, fontWeight: 700, fontSize: 14 }}>
        {props.value}
      </Grid>
    </Grid>
  );

  useEffect(() => {
    _query();
  }, [filteredQuery]);
  // const {
  //   menteesData,
  //   statusData,
  //   revenueData,
  //   orderItemWise,
  //   topItem,
  //   topCustomer,
  // } = this.state;
  // console.log("Status: ", statusData);
  const [view, setView] = useState("12months");

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <div className="dashboard p-1">
      <Paper
        elevation={5}
        style={{
          padding: 5,
          backgroundColor: "#fafafd",
          paddingBottom: "50px",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            // justifyContent: "center",
            padding: 5,
            // backgroundColor: "violet",
          }}
        >
          {Cards?.map((e, index) => (
            <div
              key={index}
              // item
              // lg={2}
              // md={4}
              // sm={6}
              // xs={12}
              style={{
                height: "100%",
                margin: "8px",
                marginTop: "8px",
              }}
            >
              <CardList values={[e]} />
            </div>
          ))}
        </Grid>

        <Grid
          container
          style={{
            padding: 10,
          }}
        >
          <Typography variant="root">
            <Box style={{ fontSize: 24, fontWeight: 700, color: "#151515" }}>
              Over View
            </Box>
            <Box style={{ fontSize: 20, fontWeight: 600, color: "#4A4A4A" }}>
              Last 7 days Performance
            </Box>
            <Grid container>
              <OverViewCard
                title="Mentees"
                value={overView.mentees}
                iconBackground="#E5E4FF"
                background="#FFFFFF"
                icon={allImages.iconMentee}
              />
              <OverViewCard
                title="Mentors"
                value={overView.mentors}
                iconBackground="#FFF3D6"
                background="#FFFFFF"
                icon={allImages.iconMentor}
              />
              <OverViewCard
                title="Bookings"
                value={overView.bookings}
                iconBackground="#D9F7E8"
                background="#FFFFFF"
                icon={allImages.bookingsIcon}
              />
              {LocalStorage?.userDetails?.userType === "partner" ? (
                // <OverViewCard
                // title="Credits"
                // value={overView.organization}
                // iconBackground="#FEDCE4"
                // background="#FFFFFF"
                // icon={allImages.iconRevenue}
                // />
                <></>
              ) : (
                <OverViewCard
                  title="Organization"
                  value={overView.organization}
                  iconBackground="#FEDCE4"
                  background="#FFFFFF"
                  icon={allImages.iconOrganisation}
                />
              )}
              {LocalStorage?.userDetails?.userType !== "partner" && (
                <OverViewCard
                  title="Revenue"
                  value={overView.payment}
                  iconBackground="#FFDED1"
                  background="#FFFFFF"
                  icon={allImages.iconRevenue}
                />
              )}
            </Grid>
          </Typography>
        </Grid>

        <Typography
          style={{
            marginLeft: "12px",
            marginTop: "24px",
            marginBottom: "-26px",
          }}
        >
          Graphical Representation
        </Typography>
        <Grid
          container
          style={{
            padding: 10,
            display: "flex",
            // justifyContent: "space-evenly",
            marginLeft: "5px",
          }}
        >
          <Grid
            item
            lg={3}
            md={12}
            sm={12}
            xs={12}
            style={{
              padding: "10px",
            }}
          >
            <TextField
              id="priority"
              // label={props.t("dashboard.priority")}
              // className={classes.userTypeMenuItem}
              select
              // disabled={
              //   props.formDialog.divType === 'new' ||
              //   props.formDialog.divType === 'edit'
              //     ? false
              //     : true
              // }
              // required
              fullWidth
              defaultValue={filteredQuery.graphDropdown}
              // value={addForm.priority}
              onChange={(event) =>
                setFilteredQuery({
                  ...filteredQuery,
                  graphDropdown: event.target.value,
                })
              }
              style={{ marginTop: "12px" }}
            >
              {LocalStorage.userDetails.userType === "partner"
                ? ["mentees", "mentor", "booking"]?.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {props.t(`dashboard.${data}`)}
                    </MenuItem>
                  ))
                : [
                    "mentees",
                    "mentor",
                    "booking",
                    "payment",
                    "organization",
                  ]?.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {props.t(`dashboard.${data}`)}
                    </MenuItem>
                  ))}
              {/* <MenuItem key={0} value={"mentees"}>
                {props.t("dashboard.mentees")}
              </MenuItem>
              <MenuItem key={1} value={"mentor"}>
                {props.t("dashboard.mentor")}
              </MenuItem>
              <MenuItem key={2} value={"booking"}>
                {props.t("dashboard.booking")}
              </MenuItem>
              <MenuItem key={2} value={"payment"}>
                {props.t("dashboard.payment")}
              </MenuItem>
              <MenuItem key={2} value={"organization"}>
                {props.t("dashboard.organization")}
              </MenuItem> */}
            </TextField>
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid #EEEFF1",
              backgroundColor: "#FFFFFF",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              borderRadius: "4px",
              marginLeft: "12px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ width: "150px" }}>
                <DatePicker
                  margin="normal"
                  id="startAt"
                  label="Starting date"
                  inputFormat="dd/MM/yyyy"
                  value={filteredQuery.queryStart}
                  onChange={_handleStartChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={
                    filteredQuery.queryEnd !== null
                      ? filteredQuery.queryEnd
                      : new Date()
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
            {/* </Grid> */}
            {/* <Grid item lg={2} md={2} sm={12} xs={12}> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ width: "150px" }}>
                <DatePicker
                  margin="normal"
                  id="endAt"
                  label="Ending Date"
                  inputFormat="dd/MM/yyyy"
                  value={filteredQuery.queryEnd}
                  onChange={_handleEndChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={filteredQuery.queryStart}
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
            {/* </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          > */}
            {/* <div style={{ backgroundColor: "#F6F7F9" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={"tableIcons ml-1 mr-1"}
                title={props.t("buttons.reset")}
                onClick={() => _queryFormReset()}
              >
                <RestartAltIcon style={{ fontSize: 20 }} />
              </Button>
              <Button
              variant="contained"
              color="primary"
              size="small"
              className={"tableIcons ml-1 mr-1"}
              title={props.t("buttons.query")}
              onClick={() => _query()}
            >
              <MdSend style={{ fontSize: 20 }} />
            </Button>

              <Button
                variant="contained"
                color={
                  filteredQuery.dayClicked !== ""
                    ? filteredQuery.dayClicked
                    : "secondary"
                }
                onClick={byDay}
                className={"tableIcons ml-1 mr-1"}
              >
                Day{" "}
              </Button>
              <Button
                variant="contained"
                color={
                  filteredQuery.monthClicked !== ""
                    ? filteredQuery.monthClicked
                    : "secondary"
                }
                className={"tableIcons ml-1 mr-1"}
                onClick={byMonth}
              >
                Month
              </Button>
              <Button
                variant="contained"
                color={
                  filteredQuery.yearClicked !== ""
                    ? filteredQuery.yearClicked
                    : "secondary"
                }
                className={"tableIcons ml-1 mr-1"}
                onClick={byYear}
              >
                Year{" "}
              </Button>
            </div> */}
            <div
              style={{
                // backgroundColor: "#F6F7F9",
                padding: "0px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <RestartAltIcon
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() => _queryFormReset()}
              />
              <StyledToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                aria-label="view selection"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <StyledToggleButton
                  value="24hours"
                  onClick={byDay}
                  style={{ textTransform: "none" }}
                >
                  Day
                </StyledToggleButton>

                <StyledToggleButton
                  value="30days"
                  onClick={byMonth}
                  style={{ textTransform: "none" }}
                >
                  Month
                </StyledToggleButton>
                <StyledToggleButton
                  value="12months"
                  onClick={byYear}
                  style={{ textTransform: "none" }}
                >
                  Year
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            </div>
          </Grid>
        </Grid>

        <Grid container className="firstRow">
          {/* Start Of Inbound Row */}

          <Grid item lg={12} md={12} sm={12} xs={12} className="colStyle h-50">
            <Card style={{ height: "45vh" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Card.Title
                  style={{ color: "var(--textColor)" }}
                  // onClick={() => _languageChange()}
                >
                  {props.t("dashboard.newMentees")}
                </Card.Title> */}
                <BarRechartSingleValue
                  width="90%"
                  response={
                    filteredQuery?.graphDropdown === "mentees"
                      ? menteesData.data
                      : filteredQuery?.graphDropdown === "mentor"
                      ? mentorsData.data
                      : filteredQuery?.graphDropdown === "booking"
                      ? bookingsData.data
                      : filteredQuery?.graphDropdown === "payment"
                      ? paymentData.data
                      : filteredQuery?.graphDropdown === "organization"
                      ? organizationData.data
                      : null
                  }
                  XAxis={"_id"}
                  colors={[
                    "#00275A",
                    "#00275A",
                    "#00275A",
                    "#00275A",
                    "#00275A",
                    "#00275A",
                    "#00275A",
                    "#00275A",
                  ]}
                  // height={"30vh"}
                  tick={5}
                  maxDomain={"dataMax + 25"}
                  YAxis={"count"}
                  LabelPosition={"top"}
                  onClick={(data) => {
                    // handleClick(data);
                  }}
                />
              </Card.Body>
              {menteesData.data[0] ? (
                !menteesData.data[0].count ? (
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      zIndex: 1,
                      alignItems: "center",
                      // position: "absolute",
                      margin: "15px 20%",
                      padding: "3px",
                    }}
                  >
                    <p style={{ margin: "0 auto" }}>
                      No Data for given condition
                    </p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Card>
          </Grid>

          {/* <Grid item lg={6} md={6} sm={12} xs={12} className="colStyle h-50">
            <Card style={{ height: "35vh" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card.Title
                  style={{ color: "var(--textColor)" }}
                  // onClick={() => _languageChange()}
                >
                  {props.t("dashboard.newMentors")}
                </Card.Title>
                <BarRechartSingleValue
                  width="90%"
                  response={mentorsData.data}
                  XAxis={"_id"}
                  colors={[
                    "#0088FE",
                    "#00C49F",
                    "#FFBB28",
                    "#FF8042",
                    "#FF9872",
                    "#FFBB28",
                    "#FFBB28",
                    "#FFBB28",
                  ]}
                  // height={"30vh"}
                  tick={5}
                  maxDomain={"dataMax + 25"}
                  YAxis={"count"}
                  LabelPosition={"top"}
                  onClick={(data) => {
                    // handleClick(data);
                  }}
                />
              </Card.Body>
              {mentorsData.data[0] ? (
                !mentorsData.data[0].count ? (
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      zIndex: 1,
                      alignItems: "center",
                      // position: "absolute",
                      margin: "15px 20%",
                      padding: "3px",
                    }}
                  >
                    <p style={{ margin: "0 auto" }}>
                      No Data for given condition
                    </p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="colStyle h-50">
            <Card style={{ height: "35vh" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card.Title
                  style={{ color: "var(--textColor)" }}
                  // onClick={() => _languageChange()}
                >
                  {props.t("dashboard.newPayments")}
                </Card.Title>
                <BarRechartSingleValue
                  width="90%"
                  response={bookingsData.data}
                  XAxis={"_id"}
                  colors={[
                    "#0088FE",
                    "#00C49F",
                    "#FFBB28",
                    "#FF8042",
                    "#FF9872",
                    "#FFBB28",
                    "#FFBB28",
                    "#FFBB28",
                  ]}
                  // height={"30vh"}
                  tick={5}
                  maxDomain={"dataMax + 25"}
                  YAxis={"count"}
                  LabelPosition={"top"}
                  onClick={(data) => {
                    // handleClick(data);
                  }}
                />
              </Card.Body>
              {bookingsData.data[0] ? (
                !bookingsData.data[0].count ? (
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      zIndex: 1,
                      alignItems: "center",
                      // position: "absolute",
                      margin: "15px 20%",
                      padding: "3px",
                    }}
                  >
                    <p style={{ margin: "0 auto" }}>
                      No Data for given condition
                    </p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Card>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} className="colStyle h-50">
            <Card style={{ height: "35vh" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card.Title
                  style={{ color: "var(--textColor)" }}
                  // onClick={() => _languageChange()}
                >
                  {props.t("dashboard.newBookings")}
                </Card.Title>
                <BarRechartSingleValue
                  width="90%"
                  response={paymentData.data}
                  XAxis={"_id"}
                  colors={[
                    "#0088FE",
                    "#00C49F",
                    "#FFBB28",
                    "#FF8042",
                    "#FF9872",
                    "#FFBB28",
                    "#FFBB28",
                    "#FFBB28",
                  ]}
                  // height={"30vh"}
                  tick={5}
                  maxDomain={"dataMax + 25"}
                  YAxis={"count"}
                  LabelPosition={"top"}
                  onClick={(data) => {
                    // handleClick(data);
                  }}
                />
              </Card.Body>

              {paymentData.data[0] ? (
                !paymentData.data[0].count ? (
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      zIndex: 1,
                      alignItems: "center",
                      // position: "absolute",
                      margin: "15px 20%",
                      padding: "3px",
                    }}
                  >
                    <p style={{ margin: "0 auto" }}>
                      No Data for given condition
                    </p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Card>
          </Grid> */}
        </Grid>
      </Paper>
    </div>
  );
};
export default withTranslation("translations")(Dashboard);

//   <Grid item lg={6} md={6} sm={12} xs={12} className="colStyle h-50">
//   <Card style={{ height: "35vh" }}>
//     <Card.Body
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Card.Title
//         style={{ color: "var(--textColor)" }}
//         onClick={() => this._languageChange()}
//       >
//         {this.props.t("order.orderType")}
//       </Card.Title>

//       <PieRechart
//         response={this.state.typeData}
//         name={"_id"}
//         dataKey={"count"}
//         showValue={true}
//         active={true}
//         payload={this.state.typeData}
//         cx={200}
//         cy={200}
//         innerRadius={60}
//         outerRadius={100}
//       />
//     </Card.Body>
//   </Card>
// </Grid>
