class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader = [
    // {
    //   id: "fname",
    //   accessor: "fname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "user.fname",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "lname",
    //   accessor: "lname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "user.lname",
    //   className: "",
    //   functionName: "",
    // },
    {
      id: "orderId",
      accessor: "orderId",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.orderId",
      className: "",
      functionName: "",
    },
    {
      id: "status",
      accessor: "status",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.status",
      className: "",
      functionName: "",
    },
    {
      id: "menteeName",
      accessor: "userId.fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "booking.mentee",
      className: "",
      functionName: "",
    },
    {
      id: "mentorName",
      accessor: "mentorId.fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "booking.mentor",
      className: "",
      functionName: "",
    },
    {
      id: "sessionDate",
      accessor: "sessionDate",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.sessionDate",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "totalPayment",
      accessor: "totalPayment",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.totalPayment",
      className: "",
      functionName: "",
    },
    {
      id: "Invoice",
      accessor: "totalPayment",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.totalPayment",
      className: "",
      functionName: "",
      icon: true,
    },
    // {
    //   id: "mobileNo",
    //   accessor: "mobileNo",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: "user.mobileNo",
    //   className: "",
    //   functionName: "",
    // },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
  ];
  static adminTableHeader = [
    // {
    //   id: "fname",
    //   accessor: "fname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "user.fname",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "lname",
    //   accessor: "lname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "user.lname",
    //   className: "",
    //   functionName: "",
    // },
    {
      id: "orderId",
      accessor: "orderId",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.orderId",
      className: "",
      functionName: "",
    },
    {
      id: "status",
      accessor: "status",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.status",
      className: "",
      functionName: "",
    },
    {
      id: "menteeName",
      accessor: "userId.fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "booking.mentee",
      className: "",
      functionName: "",
    },
    {
      id: "mentorName",
      accessor: "mentorId.fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "booking.mentor",
      className: "",
      functionName: "",
    },
    {
      id: "sessionDate",
      accessor: "sessionDate",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.sessionDate",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "totalPayment",
      accessor: "totalPayment",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.totalPayment",
      className: "",
      functionName: "",
    },
    {
      id: "Invoice",
      accessor: "orderId",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "booking.invoice",
      className: "",
      functionName: "",
      icon: true,
    },
    // {
    //   id: "mobileNo",
    //   accessor: "mobileNo",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: "user.mobileNo",
    //   className: "",
    //   functionName: "",
    // },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 170,
      label: "user.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
  ];
}
export default TableHeader;
