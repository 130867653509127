import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  useEffect(() => {
    getOrganizations();
  }, []);

  const [organizations, setOrganizations] = useState([]);

  const getOrganizations = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllOrganization,
      JSON.stringify({
        keyword: "",
        page: 0,
        pageSize: 10,
        sortField: "",
        sortOrder: "false",
        startAt: null,
        endAt: null,
        active: true,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setOrganizations(response.data.rows);
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("query.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("query.inactive")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.userType")}
              select
              defaultValue={props.query.userType}
              value={props.query.userType}
              onChange={(event) =>
                props.setQuery({ ...props.query, userType: event.target.value })
              }
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              {LocalStorage.userDetails.userType === "partner"
                ? ["mentee", "mentor"]?.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {props.t(`query.${data}`)}
                    </MenuItem>
                  ))
                : ["all", "admin", "mentee", "mentor", "partner"]?.map(
                    (data, index) => (
                      <MenuItem key={index} value={data}>
                        {props.t(`query.${data}`)}
                      </MenuItem>
                    )
                  )}
            </TextField>
          </Grid>
          {LocalStorage.userDetails.userType !== "partner" && (
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                label={props.t("query.organization")}
                select
                defaultValue={props.query.associatedOrganization}
                value={props.query.associatedOrganization}
                // onChange={(event) =>
                //   props.setQuery({
                //     ...props.query,
                //     associatedOrganization: event.target.value,
                //   })
                // }
                style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
              >
                {organizations?.map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data.name}
                    onClick={(event) =>
                      props.setQuery({
                        ...props.query,
                        associatedOrganization: data?._id,
                      })
                    }
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
