import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 200,
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("role.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("role.inactive")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="startAt"
                fullWidth
                maxDate={
                  props.query.endAt !== null
                    ? new Date(props.query.endAt * 1000)
                    : new Date()
                }
                label={props.t("query.startDate")}
                format="dd/MM/yyyy"
                defaultValue={
                  props.query.startAt !== null
                    ? new Date(props.query.startAt * 1000)
                    : null
                }
                value={
                  props.query.startAt !== null
                    ? new Date(props.query.startAt * 1000)
                    : null
                }
                style={{ marginTop: "-0px", maxWidth: 200 }}
                onChange={(event) =>
                  props.setQuery({
                    ...props.query,
                    startAt: Math.floor(new Date(event).getTime() / 1000.0),
                  })
                }
                // InputProps={{
                //   disabled: true,
                // }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="endAt"
                fullWidth
                minDate={
                  props.query.startAt !== null
                    ? new Date(props.query.startAt * 1000)
                    : new Date(0)
                }
                label={props.t("query.endDate")}
                format="dd/MM/yyyy"
                defaultValue={
                  props.query.endAt !== null
                    ? new Date(props.query.endAt * 1000)
                    : null
                }
                value={
                  props.query.endAt !== null
                    ? new Date(props.query.endAt * 1000)
                    : null
                }
                style={{ marginTop: "-0px", maxWidth: 200 }}
                onChange={(event) =>
                  props.setQuery({
                    ...props.query,
                    endAt: Math.floor(new Date(event).getTime() / 1000.0),
                  })
                }
                // InputProps={{
                //   disabled: true,
                // }}

                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
