import React, { Component } from "react";
import General from "./components/general";
import AdminIndex from "./components/privateRouting/admin";
import PrivateRouteValidation from "./PrivateRouteValidation"; // for restricted access route
import { HashRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
class App extends Component {
  render() {
    return (
      <div style={rootStyle}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path={["/", "/login", "/user/password/generate"]}
              component={General}
            />

            <PrivateRouteValidation path={["/admin"]} component={AdminIndex} />
            <PrivateRouteValidation
              path={["/partner"]}
              component={AdminIndex}
            />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </div>
    );
  }
}
export default App;
const rootStyle = {
  overflowX: "hidden",
};
