import React from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import Role from "./role";
import User from "./user";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile";
import Setting from "./setting";
import Advertisement from "./advertisement";
import Category from "./category";
import Booking from "./booking";
import Discount from "./discounts";
import Coupons from "./coupons/index";
import organization from "./organization";

function PartnerContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path="/partner"
        render={() => <Redirect to="/partner/users" />}
      />
      <Route exact path={["/partner/security/role"]} component={Role} />
      <Route exact path={["/partner/dashboard"]} component={Dashboard} />
      <Route exact path={["/partner/users"]} component={User} />
      <Route exact path={["/partner/booking"]} component={Booking} />
      <Route exact path={["/partner/profile"]} component={Profile} />
      <Route exact path={["/partner/setting"]} component={Setting} />
      <Route
        exact
        path={["/partner/advertisements"]}
        component={Advertisement}
      />
      <Route exact path={["/partner/organization"]} component={organization} />
      <Route exact path={["/partner/category"]} component={Category} />
      <Route exact path={["/partner/coupons"]} component={Coupons} />
      <Route exact path={["/partner/discount"]} component={Discount} />

      <Route render={() => <h3>Coming soon.</h3>} />
    </Switch>
  );
}
export default withTranslation("translations")(PartnerContentRouting);
