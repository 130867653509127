import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  IconButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
// import { makeStyles } from "@mui/styles";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import InfoIcon from "@material-ui/icons/Info";
import imageCompression from "browser-image-compression";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LanguageConfig from "../../../../config/LanguageConfig";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
const userFormDetails = {
  active: true,
  isParent: true,
  parentTitle: "",
  title: "",
  logo: "",
  i18n: {
    en_US: { title: "" },
  },
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState({ ...userFormDetails });
  const [logoUploadStatus, setLogoUploadStatus] = useState(false);
  const [languageList, setLanguageList] = useState([]);

  // useEffect(() => {
  //   getLanguageList();
  // }, []);

  // const getLanguageList = () => {
  //   APIRequest.request(
  //     "POST",
  //     ConfigAPIURL.getOptions,
  //     JSON.stringify({ name: "languageOptions" })
  //   ).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         setLanguageList(response.data.options[0].options);
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
  }, []);

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({
        ...userFormDetails,
        userType: "user",
      });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({
        ...userFormDetails,
        userType: "user",
        i18n: {
          en_US: { title: "" },
        },
      });
    } else {
      setUserForm({
        ...userFormDetails,
        i18n: {
          en_US: { title: "" },
        },
      });
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getCategoryDetails,
        JSON.stringify({ categoryId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.categories;

            setUserForm({
              ...userForm,
              active: userResult.active,
              isParent: userResult.isParent,
              parentTitle: userResult.parentTitle,
              i18n: userResult?.i18n,
              title: userResult.title,
              logo: userResult.logo,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.addCategory;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateCategory;
      userForm["categoryId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation = ["title", "active"];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    method === "POST"
                      ? props.t("snackbar.savedSuccess")
                      : props.t("snackbar.updatedSuccess"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleLogoUpload = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setLogoUploadStatus(true);
      [...event.target.files].map(async (data) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(data);

        img.onload = async () => {
          let formData = new FormData();
          if (data.size > 3000000) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }

          if (data.size > 300000) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            let compressedFile = await imageCompression(
              event.target.files[0],
              options
            );

            formData.append(`attachment`, compressedFile);
          } else {
            formData.append(`attachment`, data);
          }

          formData.append("bucketName", "logo");

          APIRequest.multipartForDataRequest(
            "PUT",
            ConfigAPIURL.uploadFile,
            formData
          ).then((response) => {
            if (response !== undefined && response !== null) {
              setTimeout(function () {
                setLogoUploadStatus(false);
                setUserForm({
                  ...userForm,
                  logo: response.data.attachmentUrl[0],
                });
              }, 1000);
            } else {
              setLogoUploadStatus(false);
            }
          });
        };
      });
    } else {
      setLogoUploadStatus(false);
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const addLanguage = () => {
    const u = userForm;
    u.i18n[""] = { title: "" };
    setUserForm({ ...userForm, i18n: u.i18n });
  };

  const removeLanguage = (value) => {
    const u = userForm;
    delete u.i18n[value];
    setUserForm({ ...userForm, i18n: u.i18n });
  };
  console.log("User Form", userForm);

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <h2>{props.t("category.categoryDetails")}</h2>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("category.active")}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                  variant="outlined"
                  
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("category.title")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.title}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      title: event.target.value,
                    })
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="isParent"
                  label={props.t("category.isParent")}
                  select
                  fullWidth
                  defaultValue={userForm.isParent}
                  value={userForm.isParent}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      isParent: event.target.value,
                    })
                  }
                  variant="outlined"
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>

              {userForm.isParent === false && (
                <Grid
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="parentTitle"
                    label={props.t("category.parentTitle")}
                    autoComplete="something-unsupported"
                    value={userForm.parentTitle}
                    fullWidth
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        parentTitle: event.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </Grid>
              )}
              {userForm.i18n &&
                Object.entries(userForm.i18n).map((e, i) => (
                  <Grid
                    key={i}
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-langCode`}
                        label={props.t("category.language")}
                        select
                        fullWidth
                        defaultValue={e[0]}
                        value={e[0]}
                        onChange={(event) => {
                          const uf = userForm;
                          delete uf.i18n[e[0]];

                          uf["i18n"][event.target.value] = {
                            title: "",
                          };

                          setUserForm({ ...uf });
                        }}
                        variant="outlined"
                      >
                        {languageList.map((language) => (
                          <MenuItem key={language.code} value={language.code}>
                            {language.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-title`}
                        label={props.t("category.languageTitle")}
                        required
                        fullWidth
                        value={e[1].title}
                        onChange={(event) => {
                          const uf = userForm;
                          uf["i18n"][e[0]] = {
                            title: event.target.value,
                          };

                          setUserForm({ ...uf });
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={addLanguage}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          removeLanguage(e[0]);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid
                    xl={8}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="logo"
                      label={props.t("category.logo")}
                      fullWidth
                      variant="outlined"
                      value={userForm.logo}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Upload Image upto 3 Mb">
                              <IconButton aria-label="add">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <Grid item xs={7}>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="logoImage"
                        // multiple
                        disabled={
                          logoUploadStatus === true
                            ? true
                            : (logoUploadStatus === false &&
                                props.formDialog.divType === "new") ||
                              (logoUploadStatus === false &&
                                props.formDialog.divType === "edit")
                            ? false
                            : true
                        }
                        onChange={(event) => {
                          handleLogoUpload(event);
                        }}
                        type="file"
                      />
                      <label htmlFor="logoImage">
                        <Button
                          align="left"
                          color="primary"
                          component="span"
                          className={"mr-2"}
                          size="large"
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: 20,
                              marginRight: "20px",
                            }}
                          />
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      {logoUploadStatus === true ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        logoUploadStatus === false &&
                        userForm.logo !== "" && (
                          <img
                            src={userForm.logo}
                            style={{
                              width: "80px",
                              height: "55px",
                              border: "2px solid black",
                            }}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button color="primary" onClick={() => sendToServer()}>
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
