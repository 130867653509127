import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Paper,
  Tooltip,
  IconButton,
  CircularProgress,
  makeStyles,
  MenuItem,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
// import { makeStyles } from "@mui/styles";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import InfoIcon from "@material-ui/icons/Info";
import imageCompression from "browser-image-compression";
import {
  // Autocomplete,
  Box,
  FormControl,
  Input,
  InputLabel,
  Select,
  // Stack,
  Typography,
} from "@material-ui/core";
import "./coupons.css";
import DatePicker from "react-datepicker";
import { Stack } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import "react-datepicker/dist/react-datepicker.css";
import CopyToClipboard from "react-copy-to-clipboard";
import TagsInput from "react-tagsinput";
// import { WithContext as ReactTags } from "react-tag-input";
import "./modalSkills.css";
import LocalStorage from "../../../../config/LocalStorage";
var voucher_codes = require("voucher-code-generator");

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
const userFormDetails = {
  active: true,
  isParent: true,
  parentTitle: "",
  title: "",
  logo: "",
  i18n: {
    en_US: { title: "" },
  },
  oraganisationName: "",
  email: [],
  amount: "",
  startDate: null,
  endDate: null,
  validity: null,
  couponCode: "",
  selectField: true,
  tags: [],
  incubatorId: "",
  type: "general",
  noOfAttempts: "",
  // noOfUsers: "",
  associatedOrganization: "",
  elligibileMentors: [],
  eligibleMentee: [],
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState({ ...userFormDetails });
  const [logoUploadStatus, setLogoUploadStatus] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [allMentors, setAllMentors] = useState([]);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const [snackbarValues, setSnackbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const voucherGenerator = () => {
    return voucher_codes.generate({
      length: 6,
      count: 1,
      charset: voucher_codes.charset("alphanumeric"),
    });
  };

  //tags area

  useEffect(() => {
    fetchMentors();
  }, []);

  const fetchMentors = async () => {
    try {
      const response = await APIRequest.request(
        // get the users id from localstorage
        "GET",
        ConfigAPIURL.getIncubators,
        ""
      );
      if (response !== undefined && response !== null) {
        if (response?.data?.responseCode === 109) {
          // setAvailableDates(response?.data?.result);
          let userResult = [];
          const result = response?.data?.result;
          if (response?.data?.result && response?.data?.result.length > 0) {
            result.forEach((value) => {
              userResult.push({
                label: value?.fname,
                id: value?._id,
              });
            });
          }
          // console.log("this is dropdown res", userResult);
          setAllMentors(userResult);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  //tags end

  useEffect(() => {
    // getLanguageList();
  }, []);

  const getLanguageList = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: "languageOptions" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setLanguageList(response.data.options[0].options);
        }
      }
    });
  };

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({
        ...userFormDetails,
        userType: "user",
      });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setTags([]);
      setUserForm({
        ...userFormDetails,
        userType: "user",

        i18n: {
          en_US: { title: "" },
        },
      });
    } else {
      setTags([]);

      setUserForm({
        ...userFormDetails,
        i18n: {
          en_US: { title: "" },
        },
      });
    }
  };
  // console.log("this is ids ", allMentors);

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.couponDetails,
        JSON.stringify({ recordId: props.rowDetails._id })
      ).then((response) => {
        console.log(response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.coupon;
            console.log("couponsDetails", userResult);

            setUserForm({
              ...userForm,
              active: userResult?.active,
              isParent: userResult?.isParent,
              parentTitle: userResult?.parentTitle,
              i18n: userResult?.i18n,
              title: userResult?.title,
              logo: userResult?.logo,
              incubatorId: userResult?.organization,
              oraganisationName: userResult?.title,
              amount: userResult?.amount,
              startDate: new Date(userResult?.startAt * 1000),
              endDate: new Date(userResult?.endAt * 1000),
              validity: userResult?.validity,
              couponCode: userResult?.couponCode,
              type: userResult?.couponType,
              noOfAttempts: userResult?.noOfAttempts,
              elligibileMentors: userResult?.eligibleMentor,
              eligibleMentee: userResult?.eligibleMentee,
              organization: userResult?.organization,
            });
            setTags(userResult?.eligibleMentor);

            setSelectedRowId(userResult?._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    if (
      // !userForm?.associatedOrganization ||
      !userForm?.amount ||
      !userForm?.startDate ||
      !userForm?.validity ||
      !userForm?.couponCode
    )
      return SnackbarUtils.error(
        props.t("snackbar.enterDetails"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    let url = ConfigAPIURL.createCoupons;
    let method = "POST";
    let passingData = {
      // organization: userForm?.incubatorId,
      associatedOrganization: userForm?.associatedOrganization,
      noOfAttempts: userForm?.noOfAttempts,
      // noOfUsers: userForm?.noOfUsers,
      title: userForm?.organization,
      couponType: userForm?.type,
      eligibleMentor: userForm?.elligibileMentors,
      eligibleMentee: userForm?.eligibleMentee,
      amount: +userForm?.amount,
      startAt: new Date(userForm?.startDate) / 1000,
      endAt: new Date(userForm?.endDate) / 1000,
      validity: +userForm?.validity,
      couponCode: userForm?.couponCode,
      active: true,
      publish: true,
    };
    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateCoupons;
      passingData["recordId"] = selectedRowId;
      method = "POST";
    }
    let fieldValidation = [];
    //  ["title", "active"];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(method, url, JSON.stringify(passingData)).then(
            (response) => {
              console.log(response);
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    method === "POST"
                      ? props.t("snackbar.savedSuccess")
                      : props.t("snackbar.updatedSuccess"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 130
                ) {
                  SnackbarUtils.sucess(
                    props.t(response?.data?.message),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleLogoUpload = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setLogoUploadStatus(true);
      [...event.target.files].map(async (data) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(data);

        img.onload = async () => {
          let formData = new FormData();
          if (data.size > 3000000) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }

          if (data.size > 300000) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            let compressedFile = await imageCompression(
              event.target.files[0],
              options
            );

            formData.append(`attachment`, compressedFile);
          } else {
            formData.append(`attachment`, data);
          }

          formData.append("bucketName", "logo");

          APIRequest.multipartForDataRequest(
            "PUT",
            ConfigAPIURL.uploadFile,
            formData
          ).then((response) => {
            if (response !== undefined && response !== null) {
              setTimeout(function () {
                setLogoUploadStatus(false);
                setUserForm({
                  ...userForm,
                  logo: response.data.attachmentUrl[0],
                });
              }, 1000);
            } else {
              setLogoUploadStatus(false);
            }
          });
        };
      });
    } else {
      setLogoUploadStatus(false);
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const addLanguage = () => {
    const u = userForm;
    u.i18n[""] = { title: "" };
    setUserForm({ ...userForm, i18n: u.i18n });
  };

  const removeLanguage = (value) => {
    const u = userForm;
    delete u.i18n[value];
    setUserForm({ ...userForm, i18n: u.i18n });
  };
  const [tags, setTags] = useState([]);
  const [terms, setTerms] = useState("");

  // removing the tags from state
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  //adding tags to state
  const addTags = (e) => {
    if (e.target.value !== "") {
      setTags([...tags, e.target.value]);
      e.target.value = "";
      setTerms("");
    }
  };
  // to update the current tags
  const UpdateTags = async () => {
    const response = await APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdateSkills,
      JSON.stringify({
        skills: tags,
      })
    );
    //closes the popup
  };
  // console.log(userForm.type);
  const handleCopy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = userForm?.couponCode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setSnackbarValues({
      status: true,
      severity: "success",
      message: "Text copied!",
    });
  };
  const handleCloseSnackbar = () => {
    setSnackbarValues({
      ...snackbarValues,
      status: false,
    });
  };

  useEffect(() => {
    getOrganizations();
  }, []);
  const [organizations, setOrganizations] = useState([]);

  const getOrganizations = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllOrganization,
      JSON.stringify({
        keyword: "",
        page: 0,
        pageSize: 10,
        sortField: "",
        sortOrder: "false",
        startAt: null,
        endAt: null,
        active: true,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          const organizationId = LocalStorage.userDetails.organizationId;
          const filteredOrganizations = response?.data?.rows?.filter((org) =>
            organizationId ? org._id === organizationId : true
          );
          setOrganizations(filteredOrganizations);
          // setOrganizations(response.data.rows);
        }
      }
    });
  };

  //getting all mentors email
  const [email, setEmails] = useState([]);
  const [menteesEmail, setMenteesEmails] = useState([]);
  // useEffect(() => {
  //   fetchMentorsEmail();
  // }, []);

  const fetchMenteesEmail = async (ID) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getAllMenteesEmails,
        JSON.stringify({
          associatedOrganization: ID,
          userType: "",
        })
      );
      if (response !== undefined && response !== null) {
        if (response?.data?.responseCode === 109) {
          const result = response?.data?.result;
          const emails = result?.map((obj) => obj.email);
          setMenteesEmails(emails);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchMentorsEmail = async (ID) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.getAllMentorsEmails,
        JSON.stringify({
          associatedOrganization: ID,
          userType: "",
        })
      );
      if (response !== undefined && response !== null) {
        if (response?.data?.responseCode === 109) {
          const result = response?.data?.result;
          const emails = result?.map((obj) => obj.email);
          setEmails(emails);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (LocalStorage.userDetails.organizationId !== "") {
      setUserForm({
        ...userForm,
        associatedOrganization: LocalStorage.userDetails.organizationId,
      });
    }
  }, [LocalStorage.userDetails.organizationId]);

  console.log("this is organisation", userForm);

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Stack
                  sx={{
                    px: 3,
                    py: 3,
                  }}
                  className="coupons__container"
                >
                  <Stack style={{ margin: "10px" }}>
                    <Typography className="coupons__title" variant="h2" mb={2}>
                      {props.t("coupons.title")}
                    </Typography>
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      margin: "10px",
                    }}
                  >
                    <TextField
                      id="active"
                      label={props.t("coupons.eligibleType")}
                      select
                      // required
                      fullWidth
                      variant="outlined"
                      sx={{ textTransform: "capitalize" }}
                      // defaultValue={userForm.active}
                      value={userForm.type}
                      onChange={(event) =>
                        setUserForm({
                          ...userForm,
                          // active: event.target.value,
                          type: event.target.value,
                        })
                      }
                    >
                      <MenuItem key={0} value={"general"}>
                        {props.t("general")}
                      </MenuItem>
                      <MenuItem key={1} value={"eligible"}>
                        {props.t("eligible")}
                      </MenuItem>
                    </TextField>
                    {userForm?.type === "eligible" && (
                      <FormControl sx={{ width: "100%" }}>
                        <Autocomplete
                          id="organization"
                          options={organizations}
                          value={userForm.organization}
                          getOptionLabel={(option) =>
                            option.name === undefined ? "" : option.name
                          }
                          // onInputChange={getInventorList}
                          onChange={(event, value) => {
                            setUserForm({
                              ...userForm,
                              organization: value,
                              associatedOrganization: value?._id,
                            });
                            fetchMentorsEmail(value?._id);
                            fetchMenteesEmail(value?._id);
                          }}
                          disabled={
                            props.formDialog.divType === "edit" ? true : false
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              label={props.t("user.organization")}
                              variant="outlined"
                            />
                          )}
                        />
                      </FormControl>
                    )}
                    {/* <Stack direction="row"> */}
                    {/* <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        fullWidth
                        id="controllable-states-demo"
                        disablePortal
                        // options={allMentors}
                        options={allMentors.map((mentor) => mentor.label)} // Assuming 'label' is the property containing the string value
                        value={userForm?.title}
                        // onChange={(e, term) => {
                        //   {
                        //     console.log(
                        //       "this is autocomp droponchange",
                        //       e.target.value,
                        //       " and ",
                        //       term
                        //     );
                        //   }
                        //   setUserForm({
                        //     ...userForm,
                        //     title: term,
                        //     oraganisationName: term ? term : "",
                        //     incubatorId: term ? term : "",
                        //   });
                        // }}
                        onChange={(e, term) => {
                          const selectedMentor = allMentors.find(
                            (mentor) => mentor.label === term
                          );
                          const incubatorId = selectedMentor
                            ? selectedMentor.id
                            : "";
                          // {
                          //   console.log(
                          //     "this is autocomp selectedMentor",
                          //     selectedMentor.label,
                          //     " and ",
                          //     incubatorId,
                          //     " and ",
                          //     term
                          //   );
                          // }

                          setUserForm({
                            ...userForm,
                            title: term,
                            oraganisationName: term ? term : "",
                            incubatorId: incubatorId,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Incubator"
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        )}
                      />
                    </FormControl> */}
                    {/* </Stack> */}

                    {/* {userForm?.type === "general" && (
                      <>
                        <TextField
                          size="small"
                          type="number"
                          id="input-with-icon-textfield"
                          label={props.t("coupons.noOfUsers")}
                          variant="outlined"
                          value={userForm?.noOfUsers}
                          onChange={(e) => {
                            setUserForm({
                              ...userForm,
                              noOfUsers: e.target.value,
                            });
                          }}
                        />
                      </>
                    )} */}
                    <TextField
                      size="small"
                      type="number"
                      id="input-with-icon-textfield"
                      label={props.t("coupons.noOfAttempts")}
                      variant="outlined"
                      value={userForm?.noOfAttempts}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          noOfAttempts: e.target.value,
                        });
                      }}
                    />

                    {/* starting mentors email array is stored */}
                    {userForm?.type === "eligible" && (
                      <>
                        <FormControl sx={{ width: "100%" }}>
                          <Autocomplete
                            limitTags={4}
                            fullWidth
                            multiple={
                              props.formDialog.divType === "edit" ? true : false
                            }
                            // id="controllable-states-demo"
                            disablePortal
                            options={email}
                            value={userForm?.elligibileMentors || []}
                            onChange={(event, value) => {
                              setUserForm({
                                ...userForm,
                                elligibileMentors: value,
                              });
                            }}
                            disabled={
                              userForm.associatedOrganization === ""
                                ? true
                                : false
                            }
                            renderInput={(params) => (
                              <TextField
                                className="auto"
                                {...params}
                                label="Select Mentor Email"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    {/* starting Mentees email array is stored */}
                    {userForm?.type === "eligible" && (
                      <>
                        <FormControl sx={{ width: "100%" }}>
                          <Autocomplete
                            limitTags={4}
                            fullWidth
                            // multiple
                            multiple={
                              props.formDialog.divType === "edit" ? true : false
                            }
                            // id="controllable-states-demo"
                            disablePortal
                            options={menteesEmail}
                            value={userForm?.eligibleMentee || []}
                            onChange={(event, value) => {
                              setUserForm({
                                ...userForm,
                                eligibleMentee: value,
                              });
                            }}
                            disabled={
                              userForm.associatedOrganization === ""
                                ? true
                                : false
                            }
                            renderInput={(params) => (
                              <TextField
                                className="auto"
                                {...params}
                                label="Select Mentee Email"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}

                    {/* email */}
                    <TextField
                      size="small"
                      type="number"
                      id="input-with-icon-textfield"
                      label={props.t("coupons.amount")}
                      variant="outlined"
                      value={userForm?.amount}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          amount: e.target.value,
                        });
                      }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DatePicker
                          className="date__picker"
                          minDate={new Date()}
                          style={{ width: "100%" }}
                          selected={userForm?.startDate}
                          onChange={(Date) =>
                            setUserForm({
                              ...userForm,
                              startDate: Date,
                            })
                          }
                          placeholderText="Coupon Start Date"
                          withPortal
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          minDate={
                            userForm?.startDate
                              ? userForm?.startDate
                              : new Date()
                          }
                          className="date__picker"
                          selected={userForm?.endDate}
                          onChange={(Date) =>
                            setUserForm({
                              ...userForm,
                              endDate: Date,
                            })
                          }
                          placeholderText="Coupon end Date"
                          withPortal
                        />
                      </Grid>
                    </Grid>
                    {/* <DatePicker
                      // minDate={new Date()}
                      className="date__picker"
                      selected={userForm?.validity}
                      onChange={(Date) =>
                        setUserForm({
                          ...userForm,
                          validity: Date,
                        })
                      }
                      placeholderText="Coupons Validity No of Days"
                      withPortal
                    /> */}
                    <TextField
                      size="small"
                      type="number"
                      id="input-with-icon-textfield"
                      label={"Validity (No of Days)"}
                      variant="outlined"
                      value={userForm?.validity}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          validity: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      size="small"
                      type="text"
                      value={userForm?.couponCode}
                      inputProps={{ maxLength: 6 }}
                      erorText="Please enter only 6 Charecters"
                      onChange={(e) => {
                        // if (userForm?.couponCode.length < 6)
                        setUserForm({
                          ...userForm,
                          couponCode: e.target.value,
                        });
                      }}
                      id="input-with-icon-textfield"
                      label="Coupon Code ( 6 digits)"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyToClipboard
                              text={userForm?.couponCode}
                              onCopy={handleCopy}
                            >
                              <FileCopyIcon
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </CopyToClipboard>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                    <Stack alignItems="end">
                      <button
                        onClick={() =>
                          setUserForm({
                            ...userForm,
                            couponCode: voucherGenerator()[0],
                          })
                        }
                        // variant="text"
                        // sx={{ border: "none", color: "black" }}
                        style={{
                          border: "none",
                          outline: "none",
                          background: "none",
                          justifySelf: "center",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {props.t("coupons.AutoGenerate")}
                      </button>
                    </Stack>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <Stack
                  justifyContent="center"
                  height="100%"
                  sx={{ px: 3, py: 3 }}
                  className="coupons__container"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      "& img": {
                        width: "100%",
                        height: "auto",
                        maxWidth: "100%",
                        objectFit: "contain",
                      },
                    }}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666357569425_Allura%20-%20Feedback%20Session%201.png"
                      alt="feedback"
                      style={{ contain: "content" }}
                    />
                  </Box>
                  <Typography className="coupon__text">
                    Create Coupons
                  </Typography>
                  <Typography className="coupon__text coupon__small--text">
                    Offering your users coupons is a good way to increase orders
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center", background: "red" }}
              >
                <h2>{props.t("category.categoryDetails")}</h2>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("category.active")}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("category.title")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.title}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      title: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="isParent"
                  label={props.t("category.isParent")}
                  select
                  fullWidth
                  defaultValue={userForm.isParent}
                  value={userForm.isParent}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      isParent: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("category.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("category.no")}
                  </MenuItem>
                </TextField>
              </Grid>

              {userForm.isParent === false && (
                <Grid
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="parentTitle"
                    label={props.t("category.parentTitle")}
                    autoComplete="something-unsupported"
                    value={userForm.parentTitle}
                    fullWidth
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        parentTitle: event.target.value,
                      })
                    }
                  />
                </Grid>
              )}
              {userForm.i18n &&
                Object.entries(userForm.i18n).map((e, i) => (
                  <Grid
                    key={i}
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-langCode`}
                        label={props.t("category.language")}
                        select
                        fullWidth
                        defaultValue={e[0]}
                        value={e[0]}
                        onChange={(event) => {
                          const uf = userForm;
                          delete uf.i18n[e[0]];

                          uf["i18n"][event.target.value] = {
                            title: "",
                          };

                          setUserForm({ ...uf });
                        }}
                      >
                        {languageList.map((language) => (
                          <MenuItem key={language._id} value={language.value}>
                            {language.display}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id={`${i}-title`}
                        label={props.t("category.languageTitle")}
                        required
                        fullWidth
                        value={e[1].title}
                        onChange={(event) => {
                          const uf = userForm;
                          uf["i18n"][e[0]] = {
                            title: event.target.value,
                          };

                          setUserForm({ ...uf });
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={addLanguage}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          removeLanguage(e[0]);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid
                    xl={8}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="logo"
                      label={props.t("shop.logo")}
                      fullWidth
                      variant="outlined"
                      value={userForm.logo}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Upload Image upto 3 Mb">
                              <IconButton aria-label="add">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <Grid item xs={7}>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="logoImage"
                        // multiple
                        disabled={
                          logoUploadStatus === true
                            ? true
                            : (logoUploadStatus === false &&
                                props.formDialog.divType === "new") ||
                              (logoUploadStatus === false &&
                                props.formDialog.divType === "edit")
                            ? false
                            : true
                        }
                        onChange={(event) => {
                          handleLogoUpload(event);
                        }}
                        type="file"
                      />
                      <label htmlFor="logoImage">
                        <Button
                          align="left"
                          color="primary"
                          component="span"
                          className={"mr-2"}
                          size="large"
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: 20,
                              marginRight: "20px",
                            }}
                          />
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      {logoUploadStatus === true ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        logoUploadStatus === false &&
                        userForm.logo !== "" && (
                          <img
                            src={userForm.logo}
                            style={{
                              width: "80px",
                              height: "55px",
                              border: "2px solid black",
                            }}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Paper>
          <Snackbar
            open={snackbarValues.status}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarValues.message}
            severity={snackbarValues.severity}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            ContentProps={{
              style: {
                backgroundColor: "#379237",
                color: "white",
              },
            }}
          />
        </Grid>

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button
              color="primary"
              onClick={() => {
                console.log("submitting");
                sendToServer();
              }}
            >
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
