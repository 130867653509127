export const CheckValidation = (fields) => {
  const mandatoryFields = Object.keys(fields);
  const missingFields = mandatoryFields.filter((field) => {
    const value = fields[field];
    return value === undefined || value === null || value === "";
  });

  return missingFields;
};

export const checkEmail = (fields) => {
  const email = fields;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const checkMobileNo = (fields) => {
  const mobileNo = fields;
  const mobileNoPattern = /^[0-9]{10}$/;
  return mobileNoPattern.test(mobileNo);
};
