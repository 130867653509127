import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  CheckValidation,
  checkEmail,
  checkMobileNo,
} from "../../../../utils/FieldValidation";
import imageCompression from "browser-image-compression";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Paper,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { CameraAlt, Delete } from "@material-ui/icons";
import { Button } from "@mui/material";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import Logs from "./Logs";
import CircularProgress from "@mui/material/CircularProgress";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));
const formStateDetails = {
  name: "",
  address: "",
  email: "",
  phone: "",
  credit: "",
  profileImage: "",
  newCredit: "",
};
function AEVForm(props) {
  const classes = useStyles();
  const [formState, setFormState] = useState(formStateDetails);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    // getCategoryOptions();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setFormState(formStateDetails);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getOrganizationDetails,
        JSON.stringify({ recordId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.organization;
            // userResult.startAt = userResult.startAt * 1000;
            // userResult.endAt = userResult.endAt * 1000;

            setFormState({
              ...formState,
              name: userResult?.name,
              address: userResult?.address,
              email: userResult?.email,
              phone: userResult?.phone,
              credit: userResult?.credit,
              profileImage: userResult?.profileImage,
            });
          }
        }
      });
    }
  };

  const addCredits = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.addCreditsToOrganization,
        JSON.stringify({
          associatedOrganization: props.rowDetails._id,
          amount: formState.newCredit,
        })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            // let userResult = response?.data?.organization;
          }
        }
      });
    }
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.createOrganization;
    let method = "POST";

    //if dont't want to verify the particular field then just delete that field
    const userFormCopy = { ...formState };
    delete userFormCopy.profileImage;
    delete userFormCopy.newCredit;
    const missingFields = CheckValidation(userFormCopy);
    // const mobileNo = checkMobileNo(userFormCopy?.phone);
    // const email = checkEmail(userFormCopy?.email);
    const email = userFormCopy?.email;
    const isValidEmail = checkEmail(email);
    const mobileNo = userFormCopy?.phone;
    const isValidMobileNo = checkMobileNo(mobileNo);

    //check proper mobileNo and email
    if (!isValidMobileNo) {
      return SnackbarUtils.error(
        props.t("Please enter valid phone number."),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
    if (!isValidEmail) {
      return SnackbarUtils.error(
        props.t("Please enter valid email Id."),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    if (missingFields?.length > 0) {
      return SnackbarUtils.error(
        props.t("Please fill all the mandatory fields!"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    if (props.formDialog.divType === "edit") {
      url = ConfigAPIURL.updateOrganization;
      formState["recordId"] = props.rowDetails._id;
      method = "POST";
    }

    APIRequest.request(method, url, JSON.stringify(formState)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            if (
              props.formDialog.divType === "edit" &&
              formState.newCredit !== ""
            ) {
              addCredits();
            }
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              sortOrder: "false",
              startAt: null,
              endAt: null,
              active: true,
            });
            setFormState(formStateDetails);
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  const handleImageUpload = (event) => {
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      setUploading(true);
      [...event?.target?.files].map(async (data) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(data);

        img.onload = async () => {
          let formData = new FormData();
          if (data.size > 3000000) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }

          if (data.size > 300000) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            let compressedFile = await imageCompression(
              event.target.files[0],
              options
            );

            formData.append(`attachment`, compressedFile);
          } else {
            formData.append(`attachment`, data);
          }
          console.log("this is upload", formData);

          formData.append("bucketName", "logo");

          APIRequest.multipartForDataRequest(
            "PUT",
            ConfigAPIURL.uploadFile,
            formData
          ).then((response) => {
            if (response !== undefined && response !== null) {
              setTimeout(function () {
                setUploading(false);
                setFormState({
                  ...formState,
                  profileImage: response?.data?.attachmentUrl?.[0],
                });
              }, 1000);
            } else {
              setUploading(false);
            }
          });
        };
      });
    } else {
      setUploading(false);
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const handleImageDelete = () => {
    setFormState({ ...formState, profileImage: "" });
  };

  const handleChange = (prop) => (event) => {
    setFormState({ ...formState, [prop]: event.target.value });
  };
  const [newCreditField, setNewCreditField] = useState(false);
  const [logsData, setLogsData] = useState([]);

  const getAllLogs = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getAllLogs,
        JSON.stringify({
          organizationId: props.rowDetails._id,
        })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.result;
            setLogsData(userResult);
          }
        }
      });
    }
  };

  useEffect(() => {
    getAllLogs();
  }, []);
  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {uploading && <CircularProgress />}
                  {!uploading && (
                    <img
                      src={
                        formState?.profileImage ||
                        "https://via.placeholder.com/100"
                      }
                      alt="logo"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        backgroundColor: "pink",
                      }}
                    />
                  )}
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image-upload"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  {formState?.profileImage !== "" ? (
                    <IconButton
                      onClick={handleImageDelete}
                      style={{ position: "relative", bottom: -30, right: 30 }}
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <label htmlFor="image-upload">
                      <IconButton
                        component="span"
                        style={{
                          position: "relative",
                          bottom: -30,
                          right: 30,
                        }}
                      >
                        <CameraAlt />
                      </IconButton>
                    </label>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label="Organization Name"
                  value={formState.name}
                  onChange={handleChange("name")}
                  fullWidth
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {/* <AccountCircle /> */}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // variant="outlined"
                  style={{ margin: "5px" }}
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label="Organization Location"
                  value={formState.address}
                  onChange={handleChange("address")}
                  fullWidth
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {/* <LocationOn /> */}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // variant="outlined"
                  style={{ margin: "5px" }}
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label="Email ID"
                  value={formState.email}
                  onChange={(event) => {
                    handleChange("email")(event);
                  }}
                  fullWidth
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {/* <Email /> */}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // variant="outlined"
                  style={{ margin: "5px" }}
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label="Phone Number"
                  // InputLabelProps={{
                  //   shrink: formState.phone !== "" ? true : false,
                  // }}
                  value={formState.phone}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;
                    const maxLength = 10;

                    if (
                      event.target.value === "" ||
                      (re.test(event.target.value) &&
                        event.target.value.length <= maxLength)
                    ) {
                      handleChange("phone")(event);
                    }
                  }}
                  fullWidth
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {/* <Phone /> */}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // variant="outlined"
                  style={{ margin: "5px" }}
                />
              </Grid>
              {/* {props.formDialog.divType === "new" && ( */}
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label="Credit"
                  value={formState.credit}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;
                    const maxLength = 10;

                    if (
                      event.target.value === "" ||
                      (re.test(event.target.value) &&
                        event.target.value.length <= maxLength)
                    ) {
                      handleChange("credit")(event);
                    }
                  }}
                  // onChange={handleChange("credit")}
                  fullWidth
                  // variant="outlined"
                  style={{ margin: "5px" }}
                  disabled={props.formDialog.divType === "edit" ? true : false}
                />
              </Grid>
              {/* )} */}
              {props.formDialog.divType === "edit" && (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Button onClick={() => setNewCreditField((prev) => !prev)}>
                    Add credits
                  </Button>
                </Grid>
              )}

              {props.formDialog.divType === "edit" &&
                newCreditField === true && (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      label="Add more credit"
                      value={formState.newCredit}
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        const maxLength = 10;

                        if (
                          event.target.value === "" ||
                          (re.test(event.target.value) &&
                            event.target.value.length <= maxLength)
                        ) {
                          handleChange("newCredit")(event);
                        }
                      }}
                      // onChange={handleChange("newCredit")}
                      fullWidth
                      // variant="outlined"
                      style={{ margin: "5px" }}
                    />
                  </Grid>
                )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <SlideInDialog
        title={props.t("organization.logs")}
        contentComponent={<Logs logsData={logsData} />}
        fullWidth={false}
        maxWidth={"sm"}
        dialogActionsStatus={"logs"}
        slideInDialog={props?.logDialog}
        setSlideInDialog={props?.handleLogDialog}
        // handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
