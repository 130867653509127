import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import OpenSansBold from "./OpenSans-Bold.ttf";
import OpenSansRegular from "./OpenSans-Regular.ttf";
import styled from "@react-pdf/styled-components";

// Create styles
Font.register({
  family: "Opensans",
  fonts: [
    { src: OpenSansRegular }, // font-style: normal, font-weight: normal
    { src: OpenSansBold, fontWeight: "bold" },
  ],
});
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    backgroundColor: "#fff",
  },
  section: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  imageCover: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100vh",
    width: "100%",
  },
  view: {
    width: "100%",
    height: "100vh",
    padding: 0,
    backgroundColor: "white",
  },
  section1: {
    marginTop: 60,
    textAlign: "center",
    fontSize: 34,
    fontWeight: "bold",
  },
  image: {
    objectFit: "cover",
  },
});

// Create Document Component
const Invoice = ({ data, isMentor }) => {
  console.log({
    data,
    isMentor,
  });
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: "#000",
            borderBottomWidth: 1,
          }}
        >
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Image
              src={{
                uri: "https://s3.ap-south-1.amazonaws.com/mentorlink/logo/1671626206465_Mentorlink%20logo%20(3).png",
              }}
              style={{
                width: "30px",
                height: "30px",
                // resizeMode: "contain",
              }}
            />
            <Text
              style={{
                // width: 120,
                height: 30,
                resizeMode: "contain",
              }}
            >
              {"Mentorlink"}
            </Text>
          </View>
          <View style={{ justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 10,
                // padding: 4,
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            >
              {"Retail Invoice / Bill"}
            </Text>
            <Text
              style={{
                fontSize: 10,
                // padding: 4,
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            >
              {"Status : New"}{" "}
              {/* {data.status[0].toUpperCase() + data.status.substring(1)} */}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            borderBottomWidth: 1,
            padding: "8px",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              width: "50%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  // fontWeight: 'bold',
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"Name : "}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? data?.mentorId?.fname : data?.userId?.fname}{" "}
                {isMentor ? data?.mentorId?.lname : data?.userId?.lname}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  margin: 0,
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"MobileNo"} :{" "}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  magin: 0,
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? data?.mentorId?.mobileNo : data?.userId?.mobileNo}
              </Text>
            </View>
          </View>

          <View
            style={{
              justifyContent: "center",
              // width: "50%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  // fontWeight: 'bold',
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"Invoice No"} :{" "}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? data?.orderId + 100 : data.orderId}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  margin: 0,
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"Booking Date"} :{" "}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  magin: 0,
                  fontFamily: "Opensans",
                }}
              >
                {new Date(data.createdAt * 1000).getDate() +
                  "/" +
                  (new Date(data.createdAt * 1000).getMonth() + 1) +
                  "/" +
                  new Date(data.createdAt * 1000).getFullYear()}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <View
            style={{
              width: "100%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? "From" : "To"} :{" "}
              </Text>
            </View>
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? data?.mentorId?.fname : data?.fname}
              </Text>

              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {isMentor
                  ? data?.mentorId?.billingAddress
                  : data?.userId?.billingAddress}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              ></Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  paddingLeft: 10,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {"State Name"} :{" "}
                {isMentor
                  ? data?.mentorId?.address?.state
                  : data?.userId?.address?.state}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"City"} :{" "}
                {isMentor
                  ? data?.mentorId?.address?.city
                  : data?.userId?.address?.city}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  fontFamily: "Opensans",
                }}
              >
                {"Contact "} :{" "}
                {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo}
              </Text>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              padding: 5,
            }}
          >
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 90,
                  fontFamily: "Opensans",
                }}
              >
                {isMentor ? "To" : "From"} :{" "}
              </Text>
            </View>
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {"Innocatlyst Advisors PVT LTD"}
              </Text>

              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              >
                {
                  "#122, Pipeline Road, Malleshwaram10th Cross,,Karnataka,India."
                }
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              ></Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  paddingLeft: 100,
                  fontWeight: "bold",
                  fontFamily: "Opensans",
                }}
              ></Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 100,
                  fontFamily: "Opensans",
                }}
              ></Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 100,
                  fontFamily: "Opensans",
                }}
              >
                {"+91 9916128363"}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            padding: 10,
            marginTop: "16px",
            backgroundColor: "#2C72FF",
          }}
        >
          <View style={{ justifyContent: "center", width: "5%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            ></Text>
          </View>
          <View style={{ justifyContent: "center", width: "5%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "left",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            ></Text>
          </View>
          <View style={{ justifyContent: "center", width: "30%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Description
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "15%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            ></Text>
          </View>
          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"Appointment Fee"}
            </Text>
          </View>
          {!isMentor && (
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {"convenience Fee"}
              </Text>
            </View>
          )}

          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "right",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"GST (18%)"}
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "25%" }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "right",
                fontFamily: "Opensans",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"IGST (18%)"}
            </Text>
          </View>
        </View>
        {/*Uptill here everything is fine */}
        <View
          style={{
            padding: 5,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: "row", marginBottom: 3 }}>
            <View style={{ justifyContent: "center", width: "5%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "5%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "left",
                  fontFamily: "Opensans",
                }}
              >
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "30%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                Mentoring Session with Mr.{data?.mentorId?.fname}
                {""}
                {data?.mentorId?.lname} on{" "}
                {new Date(data.createdAt * 1000).getDate() +
                  "/" +
                  (new Date(data.createdAt * 1000).getMonth() + 1) +
                  "/" +
                  new Date(data.createdAt * 1000).getFullYear()}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "15%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Opensans",
                }}
              >
                Rs {data?.appointmentFee}
              </Text>
            </View>
            {!isMentor && (
              <View style={{ justifyContent: "center", width: "25%" }}>
                <Text
                  style={{
                    fontSize: 8,
                    textAlign: "center",
                    fontFamily: "Opensans",
                  }}
                >
                  {"Rs " + data?.convenienceFee}
                </Text>
              </View>
            )}
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "right",
                  fontFamily: "Opensans",
                }}
              >
                Rs{" "}
                {isMentor || data?.userId?.address?.country !== "India"
                  ? "0"
                  : data?.userId?.address?.state === "Karnataka"
                  ? data?.tax
                  : "0"}
              </Text>
            </View>
            <View style={{ justifyContent: "center", width: "25%" }}>
              <Text
                style={{
                  fontSize: 8,
                  textAlign: "right",
                  fontFamily: "Opensans",
                }}
              >
                Rs{" "}
                {isMentor || data?.userId?.address?.country !== "India"
                  ? "0%"
                  : data?.userId?.address?.state !== "Karnataka"
                  ? data?.tax
                  : "0%"}{" "}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "30%" }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Opensans",
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Opensans",
                    fontWeight: "bold",
                  }}
                >
                </Text>
                <Text style={{ fontSize: 9, fontFamily: "Opensans" }}>
                  
                </Text>
              </View>
            </View>
            <View style={{ justifyContent: "center", width: "40%" }}>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 5,
                  padding: "0 6px",
                }}
              >
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    {"Order Total"}
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    :
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontFamily: "Opensans",
                    }}
                  >
                    Rs{" "}
                    {isMentor || data?.userId?.address?.country !== "India"
                      ? isMentor
                        ? data?.totalPayment - data?.tax - data?.convenienceFee
                        : data?.totalPayment - data?.tax
                      : data?.totalPayment}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: "row", marginBottom: 5 }}>
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  ></Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontFamily: "Opensans",
                    }}
                  ></Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderBottomColor: "#000",
            borderBottomWidth: 0.5,
            borderLeftWidth: 0.5,
            borderRightWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "30%" }}></View>
            <View style={{ width: "30%" }}></View>
            <View style={{ justifyContent: "center", width: "40%" }}>
              <View style={{ flexDirection: "row", padding: "0 6px" }}>
                <View style={{ justifyContent: "center", width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  >
                    {"Grand Total"}{" "}
                  </Text>
                </View>
                <View style={{ justifyContent: "center", width: "10%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "center",
                      fontFamily: "Opensans",
                      fontWeight: "bold",
                    }}
                  ></Text>
                </View>
                <View style={{ justifyContent: "center", width: "40%" }}>
                  <Text
                    style={{
                      fontSize: 9,
                      textAlign: "right",
                      fontWeight: "bold",
                      fontFamily: "Opensans",
                    }}
                  >
                    Rs{" "}
                    {isMentor || data?.userId?.address?.country !== "India"
                      ? isMentor
                        ? data?.totalPayment - data?.tax - data?.convenienceFee
                        : data?.totalPayment - data?.tax
                      : data?.totalPayment}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderBottomColor: "#000",
          }}
        >
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Opensans",
              fontWeight: "bold",
            }}
          >
            Declaration:
          </Text>
          <Text style={{ fontSize: 7, fontFamily: "Opensans" }}>
            We Declare that this invoice shows the actual price of the Services
            described and that all particulars are true and correct
          </Text>
        </View>

        <View style={{ position: "absolute", bottom: 0 }}>
          <Text
            style={{
              paddingTop: 5,
              paddingBottom: 10,
              color: "grey",
              fontSize: 10,
              textAlign: "center",
              width: "100%",
              fontStyle: "italic",
            }}
          >
            This is a system generated invoice and does not require a digital
            signature or a signature
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
