/* eslint-disable no-useless-escape */
const Validation = {
  emailValidation: function (email) {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  },
  urlValidation: function (url) {
    // eslint-disable-next-line no-useless-escape
    if (
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
        url
      )
    ) {
      return true;
    }
    return false;
  },
  mobileValidation: function (mobileNo) {
    if (mobileNo.trim().length > 9) {
      return true;
    }
    return false;
  },
};
export default Validation;
