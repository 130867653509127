import iconMentee from "../src/assets/images/iconMentee.png";
import iconMentor from "../src/assets/images/iconMentor.png";
import iconOrganisation from "../src/assets/images/iconOrganisation.png";
import bookingsIcon from "../src/assets/images/bookingsIcon.png";
import iconRevenue from "../src/assets/images/iconRevenue.png";

const allImages = {
  iconMentee,
  iconMentor,
  iconOrganisation,
  bookingsIcon,
  iconRevenue,
};
export { allImages };
