import { createMuiTheme } from "@material-ui/core";

export const DARK = createMuiTheme({
  name: "DARK",
  overrides: {
    //.MuiPopover-paper
    MuiPopover: {
      paper: {
        minWidth: "200px",
      },
    },
    MuiDialog: {
      root: {
        // background: '#1C3464',
      },
      container: {
        // background: '#1C3464',
      },
      paperFullScreen: {
        background: "#00275A",
        // background: "#2c72ff",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        // background: "#2c72ff",
        background: "#00275A",
      },
    },
    MuiGrid: {
      root: {
        paddingRight: "3px",
      },
    },
    MuiInputLabel: {
      formControl: {
        fontSize: "12px",
        width: "max-content",
        lineHeight: "1.5",
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 13,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#42A5F5",
        "&$focused": {
          color: "#42A5F5",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):after": {
          borderBottom: "1px solid #42A5F5",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "1px solid #42A5F5",
        },
        "&:focus:not($disabled):after": {
          borderBottom: "1px solid #42A5F5",
        },
        "&:focus:not($disabled):before": {
          borderBottom: "1px solid #42A5F5",
        },
        "&:after": {
          borderBottom: "1px solid #42A5F5",
        },
        "&:before": {
          borderBottom: "1px solid #42A5F5",
        },
        borderBottom: "1px solid #42A5F5",
      },
    },
    MuiButton: {
      root: {
        background: "#00BFA5",
        "&:hover": {
          backgroundColor: "#00bfa5bd",
        },
      },
      textPrimary: {
        color: "#fff",
        "&:hover": {
          backgroundColor: "#00bfa5bd",
        },
      },
    },
  },
  palette: {
    type: "dark",
    primary: {
      // main: "#2c72ff",
      main: "#00275A",
    },
    secondary: {
      main: "#00275A",
      // main: "#2c72ff",
    },

    info: {
      main: "#669fba",
    },
    background: {
      default: "#333333",
    },
  },
  custom: {
    background: "#424242",
    containerColor: "#00275A",
    // containerColor: "#2c72ff",
    topHeader: {
      toolbarColor: "#00275A",
      // toolbarColor: "#2c72ff",
      toolBarLogout: "#fff",
      toolBarLanguage: "#fff",
    },
    sideDrawer: {
      boxShadow: "0 10px 15px -5px rgba(62, 57, 107, .07)",
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(66, 66, 66, 0.75)",
      companyName: "rgba(225, 225, 225, 0.87)",
      userName: "#fff",
      menuColor: "#fff",
    },
  },
});
