import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Login";
import PasswordGenaration from "./PasswordGenaration";
// import FormSample from './FormSample';
export default class Content extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={["/"]} render={() => <Redirect to="/login" />} />
        {/* <Route exact path={['/formSample']} component={FormSample} /> */}
        				<Route exact path={["/"]} render={() => <Redirect to="/login" />} />
        <Route exact path={['/login']} component={Login} />

        <Route
          exact
          path={["/user/password/generate"]}
          component={PasswordGenaration}
        />

        <Route render={() => <h3>Coming soon.</h3>} />
      </Switch>
    );
  }
}
