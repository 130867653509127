import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-US"; // Import the desired locale

// import { DatePicker } from "@material-ui/pickers/DatePicker";
// import { LocalizationProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocalStorage from "../../../../config/LocalStorage";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
const userFormDetails = {
  userType: "",
  active: true,
  isSuperAdmin: false,
  permission: "",
  fname: "",
  lname: "",
  mobileNo: "",
  email: "",
  password: "",
  address1: "",
  address2: "",
  address: {
    city: "",
    state: "",
    country: "",
  },
  billingAddress: "",
  bloodPressure: { high: "", low: "" },
  city: "",
  state: "",
  country: "",
  age: "",
  dob: null,
  gender: null,
  weight: 0,
  bloodGroup: "",
  height: 0,
  isSmoker: null,
  alcoholIntake: "",
  allergies: [],
  chronicConditions: [],
  familyHistory: [],
  surgeries: [],
  mentor: false,
  associatedOrganization: "",
  organization: "",
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);

  useEffect(() => {}, []);
  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    } else {
      setUserForm(userFormDetails);
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.userDetails,
        JSON.stringify({ userId: props.rowDetails._id })
      ).then((response) => {
        console.log("this is editable response", response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.user;

            userResult.allergies =
              userResult.allergies && userResult.allergies !== ""
                ? userResult.allergies.split(",")
                : [];
            userResult.chronicConditions =
              userResult.chronicConditions &&
              userResult.chronicConditions !== ""
                ? userResult.chronicConditions.split(",")
                : [];
            userResult.familyHistory =
              userResult.familyHistory && userResult.familyHistory !== ""
                ? userResult.familyHistory.split(",")
                : [];
            userResult.surgeries =
              userResult.surgeries && userResult.surgeries !== ""
                ? userResult.surgeries.split(",")
                : [];

            let permissions = {};
            if (props.roles !== undefined && props.roles !== null) {
              props.roles.map((role) => {
                if (role._id === userResult.permission) {
                  permissions = role;
                }
              });
            }

            var birthdate = new Date(userResult.dob * 1000);
            var cur = new Date();
            var diff = cur - birthdate;
            var age = Math.floor(diff / 31536000000);

            setUserForm({
              ...userForm,
              userType: userResult.userType,
              active: userResult.active,
              isSuperAdmin: userResult.isSuperAdmin,
              permission: permissions,
              fname: userResult.fname,
              lname: userResult.lname,
              mobileNo: userResult.mobileNo,
              email: userResult.email,
              password: userResult.password,
              address1: userResult.billingAddress,
              address2: userResult.address2,
              billingAddress: userResult.billingAddress,
              address: {
                city: userResult.address.city,
                state: userResult.address.state,
                country: userResult.address.country,
              },
              age: age,
              dob: userResult.dob,
              gender: userResult.gender,
              weight: userResult.weight,
              bloodGroup: userResult.bloodGroup,
              height: userResult.height,
              bloodPressure:
                userResult.bloodPressure !== undefined &&
                userResult.bloodPressure !== null
                  ? userResult.bloodPressure
                  : "",
              isSmoker: userResult.isSmoker,
              alcoholIntake: userResult.alcoholIntake,
              allergies: userResult.allergies,
              chronicConditions: userResult.chronicConditions,
              familyHistory: userResult.familyHistory,
              surgeries: userResult.surgeries,
              mentor: userResult.mentor,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.userCreate;
    let method = "POST";

    if (LocalStorage.userDetails.userType === "partner") {
      console.log(" im inside logg", LocalStorage.userDetails.userId);
      userForm["associatedPartner"] = LocalStorage.userDetails.userId;
      userForm["associatedOrganization"] =
        LocalStorage.userDetails.organizationId;
    }

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.userUpdate;
      userForm["userId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation =
      userForm.userType !== "admin"
        ? ["fname", "lname", "userType", "mobileNo", "email", "password"]
        : [
            "fname",
            "lname",
            "userType",
            "mobileNo",
            "email",
            "password",
            "permission",
          ];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          userForm.permission = userForm.permission._id;
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.transComplete"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  // props.tableQuery({
                  //   keyword: '',
                  //   page: 0,
                  //   pageSize: 10,
                  //   sortField: '',
                  //   userType: 'all',
                  //   sortOrder: 'false',
                  //   active: true,
                  // });
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const [organizations, setOrganizations] = useState([]);

  const getOrganizations = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllOrganization,
      JSON.stringify({
        keyword: "",
        page: 0,
        pageSize: 10,
        sortField: "",
        sortOrder: "false",
        startAt: null,
        endAt: null,
        active: true,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setOrganizations(response.data.rows);
        }
      }
    });
  };

  console.log("this is userForm", userForm);
  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <h2>{props.t("user.personalInformation")}</h2>
              </Grid>
              <Grid
                xl={userForm.userType === "admin" ? 4 : 4}
                lg={userForm.userType === "admin" ? 4 : 4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="userType"
                  label={props.t("user.userType")}
                  className={classes.userTypeMenuItem}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.userType}
                  disabled={
                    props.page !== undefined && props.page !== null
                      ? true
                      : false
                  }
                  value={
                    userForm.userType === "user"
                      ? userForm.mentor
                        ? "mentor"
                        : !userForm.mentor && "mentee"
                      : userForm.userType
                  }
                  onChange={(event) => {
                    if (event.target.value === "admin") {
                      setUserForm({
                        ...userForm,
                        userType: event.target.value,
                      });
                    } else if (event.target.value === "mentor") {
                      setUserForm({
                        ...userForm,
                        userType: "user",
                        mentor: true,
                      });
                    } else if (event.target.value === "mentee") {
                      setUserForm({
                        ...userForm,
                        userType: "user",
                        mentor: false,
                      });
                    } else if (event.target.value === "partner") {
                      setUserForm({
                        ...userForm,
                        userType: "partner",
                        mentor: false,
                      });
                    }
                  }}
                >
                  {LocalStorage.userDetails.userType === "partner"
                    ? ["mentee", "mentor"]?.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {props.t(`user.${data}`)}
                        </MenuItem>
                      ))
                    : ["partner", "admin", "mentee", "mentor"]?.map(
                        (data, index) => (
                          <MenuItem key={index} value={data}>
                            {props.t(`user.${data}`)}
                          </MenuItem>
                        )
                      )}
                </TextField>
              </Grid>
              <Grid
                xl={userForm.userType === "admin" ? 4 : 4}
                lg={userForm.userType === "admin" ? 4 : 4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("user.active")}
                  className={classes.userTypeMenuItem}
                  select
                  required
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>
              {userForm.userType !== "admin" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Autocomplete
                    id="organization"
                    options={organizations}
                    value={userForm.organization}
                    getOptionLabel={(option) =>
                      option.name === undefined ? "" : option.name
                    }
                    // onInputChange={getInventorList}
                    onChange={(event, value) =>
                      setUserForm({
                        ...userForm,
                        organization: value,
                        associatedOrganization: value?._id,
                      })
                    }
                    style={{
                      marginTop: "8px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        label={props.t("user.organization")}
                        // variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {userForm.userType === "admin" ? (
                <Grid
                  xl={userForm.userType === "admin" ? 4 : 4}
                  lg={userForm.userType === "admin" ? 4 : 4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="isSuperAdmin"
                    className={classes.userTypeMenuItem}
                    label={props.t("user.isSuperAdmin")}
                    select
                    required
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.isSuperAdmin}
                    value={userForm.isSuperAdmin}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        isSuperAdmin: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={true}>
                      {props.t("user.yes")}
                    </MenuItem>
                    <MenuItem key={1} value={false}>
                      {props.t("user.no")}
                    </MenuItem>
                  </TextField>
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="fname"
                  label={props.t("user.firstName")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.fname}
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      fname: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="lname"
                  label={props.t("user.lastName")}
                  autoComplete="something-unsupported"
                  required
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  value={userForm.lname}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      lname: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                />
              </Grid>
              <Grid
                xl={userForm.userType === "admin" ? 4 : 4}
                lg={userForm.userType === "admin" ? 4 : 4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="mobileNo"
                  label={props.t("user.mobileNumber")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  value={userForm.mobileNo}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      mobileNo: event.target.value,
                    })
                  }
                  disabled={props.formDialog.divType === "new" ? false : true}
                />
              </Grid>
              <Grid
                xl={userForm.userType === "admin" ? 4 : 4}
                lg={userForm.userType === "admin" ? 4 : 4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="email"
                  label={props.t("user.emailAddress")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  value={userForm.email}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      email: event.target.value,
                    })
                  }
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      event.charCode === 64 ||
                      event.charCode === 46
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>

              <Grid
                xl={userForm.userType === "admin" ? 4 : 4}
                lg={userForm.userType === "admin" ? 4 : 4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="password"
                  label={props.t("user.password")}
                  type="password"
                  autoComplete="something-unsupported"
                  fullWidth
                  required
                  value={userForm.password}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      password: event.target.value,
                    })
                  }
                  disabled={props.formDialog.divType === "new" ? false : true}
                />
              </Grid>

              {userForm.userType === "admin" ||
              userForm.userType === "partner" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Autocomplete
                    id="permission"
                    options={props.roles}
                    value={userForm.permission}
                    getOptionLabel={(option) =>
                      option.name === undefined ? "" : option.name
                    }
                    // onInputChange={getInventorList}
                    onChange={(event, value) =>
                      setUserForm({
                        ...userForm,
                        permission: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        label={props.t("user.permission")}
                        // variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                mt={5}
                className={classes.formGrid}
                style={{ marginTop: 0 }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    // fullWidth
                    style={{ width: "300px" }}
                    inputFormat="dd/MM/yyyy"
                    value={
                      userForm.dob !== null
                        ? new Date(userForm.dob * 1000)
                        : null
                    }
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    label={props.t("user.dob")}
                    onChange={(date) => {
                      // console.log(date);
                      if (date !== null) {
                        var birthdate = new Date(date);
                        var cur = new Date();
                        var diff = cur - birthdate;
                        var age = Math.floor(diff / 31536000000);
                        setUserForm({
                          ...userForm,
                          dob: new Date(date) / 1000,
                          age: age,
                        });
                      } else {
                        setUserForm({
                          ...userForm,
                          age: "",
                        });
                      }
                    }}
                    disableFuture
                    renderInput={(params) => <TextField {...params} />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 
                  <KeyboardDatePicker
                    style={{ border: "0px solid blue", marginTop: 5 }}
                    margin="normal"
                    id="dob"
                    // autoComplete="something-unsupported"
                    autoComplete="off"
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    disableFuture={true}
                    label={props.t("user.dob")}
                    format="dd/MM/yyyy"
                    value={
                      userForm.dob !== null
                        ? new Date(userForm.dob * 1000)
                        : null
                    }
                    // style={{ marginTop: "-0px" }}
                    onChange={(date) => {
                      // console.log(date);
                      if (date !== null) {
                        var birthdate = new Date(date);
                        var cur = new Date();
                        var diff = cur - birthdate;
                        var age = Math.floor(diff / 31536000000);
                        setUserForm({
                          ...userForm,
                          dob: new Date(date) / 1000,
                          age: age,
                        });
                      } else {
                        setUserForm({
                          ...userForm,
                          age: "",
                        });
                      }
                    }}
                    // InputProps={{
                    //   disabled: true,
                    // }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="age"
                  label={props.t("user.age")}
                  autoComplete="something-unsupported"
                  fullWidth
                  disabled={true}
                  value={Number.isNaN(userForm.age) ? "" : userForm.age}
                  // onChange={(event) =>
                  //   setUserForm({
                  //     ...userForm,
                  //     age: event.target.value,
                  //   })
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="gender"
                  label={props.t("user.gender")}
                  select
                  autoComplete="something-unsupported"
                  // InputLabelProps={{
                  //   shrink: userForm.gender !== "" ? true : false,
                  // }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                  fullWidth
                  defaultValue={userForm.gender}
                  value={userForm.gender}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"male"}>
                    {props.t("user.male")}
                  </MenuItem>
                  <MenuItem key={1} value={"female"}>
                    {props.t("user.female")}
                  </MenuItem>
                  <MenuItem key={1} value={"other"}>
                    {props.t("user.other")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 6
                    : 12
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 6
                    : 12
                }
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="address1"
                  label={props.t("user.address1")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm.billingAddress}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      billingAddress: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              {/* <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 6
                    : 12
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 6
                    : 12
                }
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="address2"
                  label={props.t("user.address2")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm.address2}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address2: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid> */}
              <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="city"
                  label={props.t("user.city")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  value={userForm.address.city}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address: {
                        ...userForm.address,
                        city: event.target.value,
                      },
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>

              <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="state"
                  label={props.t("user.state")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  value={userForm.address.state}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address: {
                        ...userForm.address,
                        state: event.target.value,
                      },
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>

              <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 4
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="country"
                  label={props.t("user.country")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  value={userForm.address.country}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address: {
                        ...userForm.address,
                        country: event.target.value,
                      },

                      // country: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              {/* <Grid
                xl={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 3
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm.userType === "admin"
                    ? 3
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="pin"
                  label={props.t("user.pin")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  inputProps={{ maxLength: 6 }}
                  value={userForm.address.pin}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address: {
                        ...userForm.address,
                        pin: event.target.value,
                      },
                      // pin: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button color="primary" onClick={() => sendToServer()}>
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
