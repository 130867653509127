/* eslint-disable array-callback-return */
import React from "react";
import Toaster from "./Toaster";
import Validation from "./Validation";
import { Translation } from "react-i18next";
const FormValidation = {
  validation: async function (fieldList, state) {
    let emptyField = true;
    fieldList.map((data) => {
      try {
        state[data] = state[data].trim();
      } catch (err) {}

      if (
        state[data] === undefined ||
        state[data] === null ||
        state[data] === "" ||
        state[data].length === 0 ||
        (/email/gi.test(data) && !Validation.emailValidation(state[data])) ||
        (/emailId/gi.test(data) && !Validation.emailValidation(state[data])) ||
        (/url/gi.test(data) && !Validation.urlValidation(state[data])) ||
        (/mobileNo/gi.test(data) &&
          !Validation.mobileValidation(state[data])) ||
        (/website/gi.test(data) && !Validation.urlValidation(state[data]))
      ) {
        let classList = document
          .getElementById(data)
          .parentElement.classList.value.split(" ");
        let flag = false;
        classList.map((data) => {
          if (data === "MuiInput-underline") flag = true;
          return null;
        });
        if (flag) {
          document.getElementById(data).parentElement.classList.add("medentry");
        } else {
          document.getElementById(data).classList.add("medentry");
        }

        if (
          state[data] === undefined ||
          state[data] === null ||
          state[data] === "" ||
          state[data].length === 0
        ) {
          emptyField = false;
        } else if (data.includes("email")) {
          emptyField = "email";
        } else if (data.includes("mobileNo")) {
          emptyField = "mobileNo";
        } else {
          emptyField = false;
        }
      }
      return "";
    });
    if (!emptyField) {
      Toaster.error(
        <Translation ns="translations">
          {(t, { i18n }) => (
            <div
              style={{
                textAlign: "center",
              }}
              className={"pl-2"}
            >
              {t("snackbar.fillMandatoryFields")}
            </div>
          )}
        </Translation>,
        "topRight"
      );
    } else if (emptyField === "email") {
      Toaster.error(
        <Translation ns="translations">
          {(t, { i18n }) => (
            <div
              style={{
                textAlign: "center",
              }}
              className={"pl-2"}
            >
              {t("snackbar.enterValidEmail")}
            </div>
          )}
        </Translation>,
        "topRight"
      );
    } else if (emptyField === "mobileNo") {
      Toaster.error(
        <Translation ns="translations">
          {(t, { i18n }) => (
            <div
              style={{
                textAlign: "center",
              }}
              className={"pl-2"}
            >
              {t("snackbar.enterValidMobileNo")}
            </div>
          )}
        </Translation>,
        "topRight"
      );
    } else {
      return emptyField;
    }
  },
};
export default FormValidation;
