// import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import React from "react";
// import Typography from "../../../../../../components/Text/Typography";
// import { Stack } from "@fluentui/react";
// import ChatArea from "./ChatArea";
import { Stack, Typography } from "@mui/material";
import { useStyles } from "./style";

// var randomColor = require("randomcolor");

// const logsData = [
//   {
//     message: "Opportunity #0005 Proposal has been sent to O&M team",
//     operator: "Sanjay Varma",
//     time: "12 May 2023, 04:30 PM ",
//   },
//   {
//     message: "Opportunity #0005 Proposal has been sent to O&M team",
//     operator: "Sanjay Varma",
//     time: "12 May 2023, 04:30 PM ",
//   },
//   {
//     message: "Opportunity #0005 Proposal has been sent to O&M team",
//     operator: "Sanjay Varma",
//     time: "12 May 2023, 04:30 PM ",
//   },
//   {
//     message: "Opportunity #0005 Proposal has been sent to O&M team",
//     operator: "Sanjay Varma",
//     time: "12 May 2023, 04:30 PM ",
//   },
// ];

const Logs = ({ openForm, logsData }) => {
  const classes = useStyles();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };
  

  return (
    <>
      <Stack className={classes?.header}>
        <Typography variant="title">{openForm?.title}</Typography>
      </Stack>
      {/* <FluentProvider theme={teamsLightTheme}> */}
      <Stack style={{ marginTop: "8px" }}>
        <Stack className={`${classes?.rootLog} scroll__null`}>
          <Stack
            style={{
              position: "absolute",
              height: "100%",
              width: "1px",
              background: "rgba(134, 134, 134, 0.53)",
              left: 8,
            }}
          />
          <Stack className={classes?.logsRoot}>
            {logsData?.length > 0 &&
              logsData?.map((value, key) => {
                // const color = randomColor();
                return (
                  <Stack
                    key={key}
                    style={{
                      flexDirection: "row",
                      gap: "12px",
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <Stack
                      className={classes?.round}
                      // style={{ background: color }}
                    />
                    <Stack
                      className={classes?.chatRoot}
                      style={{ backgroundColor: "#F5E9FF" }}
                    >
                      <Typography variant="subHeading">
                        {value?.action?.toLowerCase() === "credit added"
                          ? `Credit is added to ${value?.organizationId?.name} of Amount Rs. ${value?.creditAdded}`
                          : value?.action?.toLowerCase() === "credit available" ?`Current Balance Rs. ${value?.organizationId?.credit} `
                          :value?.action?.toLowerCase() === "coupon created" ?  `Coupon is created `
                          :value?.action?.toLowerCase() === "coupon applied" ? `Coupon is applied Updated Amount Rs. ${value?.amount}`
                        :value?.action?.toLowerCase() === "organization created" ? `You currently have Rs. ${value?.organizationId?.credit} credits available`:"" }
                      </Typography>
                      {value?.action?.toLowerCase() !== "credit available" && (
                        <Typography
                        variant="content"
                        style={{ backgroundColor: "#F5E9FF" }}
                        >
                        {`By ${value?.userId?.fname} ${value?.userId?.lname}`}
                      </Typography>
                        )}
                      <Typography
                        variant="content"
                        style={{
                          color: "#868686",
                          fontSize: "10px",
                          backgroundColor: "#F5E9FF",
                        }}
                      >
                        {/* {value?.updatedAt} */}
                        {formatDate(value?.updatedAt)}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
        {/* task write section */}
        {/* <ChatArea classes={classes} /> */}
      </Stack>
      {/* </FluentProvider> */}
    </>
  );
};

export default Logs;
